import { runCS, sbxCoreService, sbxSessionService } from '../Network';
import environment from '../Environment';
import Environment from '../Environment';
import Axios from 'axios';
import MixpanelService from './MixpanelService';
import { hrefToObject, signIn, toast } from '../Utils/Utils';
import * as queryString from 'querystring';
import { ChangeEmailParams, SignInGuestParams } from '../Models/Auth';
import { CustomerRegisterModel } from '../Models/CustomerRegisterModel';

const http = Axios.create({
  baseURL: 'https://sbxcloud.com/api'
});

export function logout(replaceUrl = '') {
  localStorage.removeItem('token');
  localStorage.removeItem('user_key');
  localStorage.clear();
  deleteAllCookies();

  sbxSessionService.logout();
  if (replaceUrl) {
    window.location.replace(replaceUrl);
  } else {
    window.location.replace('');
  }
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    if (name === 'email') {
      continue;
    }
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}

export function getTimeEST() {
  return runCS(environment.cloudscripts.getTimeEST, {}).then((res) => {
    if (res) {
      return res;
    }
  });
}

export function loginAsCustomer(params: any) {
  return runCS(environment.cloudscripts.loginAsCustomer, params).then(
    (res) => res
  );
}

export function leadCheck(customer: string) {
  return sbxCoreService
    .with('purchase')
    .andWhereIsEqualTo('customer_user.customer', customer)
    .find()
    .then((res) => {
      return res && res.success && !res.results.length;
    });
}

export const addUtmCode = (code: string, customer?: string) => {
  return sbxCoreService
    .insert('utm_code', { code, customer })
    .then((i: any) => i);
};

export function homeLoading(key: string, managerEmail: string) {
  return runCS(Environment.cloudscripts.homeLoadingV2, {
    customer: { key, managerEmail }
  }).then((res) => res);
}

export function validateToken(token: string, user_key?: string) {
  const params = { token: '', user_key: '' };
  if (token) {
    localStorage.setItem('token', token);
    params.token = token;
  }

  if (user_key) {
    params.user_key = user_key;
  }
  return runCS(Environment.cloudscripts.validate, params)
    .then((res) => {
      if (res.success) {
        const data = res.response;
        sbxSessionService.updateUser({ token: data.token, user: data.user });
        return { success: true, data: data };
      }
      return { success: false };
    })
    .catch((error) => {
      console.error(error);
    });
}

export function LoginService(login: string, password: string) {
  return runCS(Environment.cloudscripts.login, { login, password })
    .then((res) => res)
    .catch((error) => console.error(error));
}

export function guestSignIn(params: SignInGuestParams) {
  return runCS(environment.cloudscripts.guestSignIn, params)
    .then((res) => res)
    .catch((error) => console.error(error));
}

export async function isMailAvailable(
  email: string,
  onlyCheck = false,
  getSignIn = false
) {
  return http
    .get(
      `/user/v1/email/available?domain=${
        Environment.domain
      }&${queryString.stringify({ email })}`
    )
    .then(async (res) => {
      if (!res?.data?.success) {
        if (!onlyCheck) {
          return LoginService(email, 'wpwd').then((resLogin) => {
            if (resLogin.success) {
              MixpanelService.track('login_customer');
              const token = resLogin.data.token;
              localStorage.setItem('token', token);
              toast('login successful', 'success');
              window.location.reload();
              return null;
            } else {
              return res.data && res.data.success;
            }
          });
        } else {
          return res.data && res.data.success;
        }
      } else {
        if (getSignIn) {
          const hrefData: { [key: string]: any } = hrefToObject();
          delete hrefData.utm_source;
          Object.keys(hrefData).forEach((key) => {
            hrefData[key] = decodeURI(hrefData[key]);
          });

          await signIn({
            params: hrefData as SignInGuestParams,
            firstTime: true
          });

          // await signIn({
          //   params: {...params},
          //   firstTime: true,
          //   reload: false
          // })
        }

        return res.data && res.data.success;
      }
    });
}

export function checkEmailAvailable(email: string, isLogged = false) {
  return http
    .get(
      `/user/v1/email/available?domain=${
        Environment.domain
      }&${queryString.stringify({ email })}`
    )
    .then((res) => {
      if (!res.data.success && isLogged) {
        return sbxCoreService
          .with('customer')
          .andWhereIsEqualTo('office_email', email)
          .andWhereIsEqualTo('guest', false)
          .andWhereIsEqualTo('active', true)
          .find()
          .then((res) => {
            return res.success && res.results.length === 0;
          });
      } else {
        return res.data.success;
      }
    })
    .catch((error) => {
      return error;
    });
}

export function forgotPassword(email: string) {
  return sbxCoreService
    .sendPasswordRequest(
      email,
      'iBuyflowers Password Recovery',
      Environment.templates.forgotPassword
    )
    .then((res: any) => res);
}

export function guestSignUp(params: CustomerRegisterModel) {
  return runCS(environment.cloudscripts.userGuestUpgrade, params)
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
}

export function getVerificationCode(params: any) {
  return runCS(environment.cloudscripts.createVerificationCode, params).then(
    (res) => res
  );
}

export function updateSMSConfig(obj: any) {
  return sbxCoreService
    .update('customer_sms_config', obj)
    .then((res: any) => res);
}

export function updatePasswordService(
  currentPassword: string,
  newPassword: string
) {
  return sbxCoreService
    .changeMyPassword(currentPassword, newPassword)
    .then((res) => res);
}

export function changeEmailRequestService(params: ChangeEmailParams) {
  return runCS(
    environment.cloudscripts.ibf_updateUserAndCustomerEmail,
    params
  ).then((res) => res);
}

export function checkEmailRequest(user_key: string) {
  return sbxCoreService
    .with('email_change_request')
    .andWhereIsEqualTo('user', user_key)
    .andWhereIsEqualTo('status', 'PENDING')
    .find()
    .then((res) => res.results);
}

export function verifyEmailAccountService(params: SignInGuestParams) {
  return runCS(environment.cloudscripts.ibf_verify_email_account, params)
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
}

export function insertAccountSent(email: string) {
  return runCS(environment.cloudscripts.ibf_verify_email_guest, { email })
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
}

export function getListEmailGuest() {
  return runCS(Environment.cloudscripts.ibf_email_guest, {})
    .then((res) => {
      if (res.success) {
        return { success: true, data: res };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export function checkCookie() {
  // Quick test if browser has cookieEnabled Host property

  if (!navigator.cookieEnabled) return 'Not Enabled';

  return 'Enabled';
}

export const getConfig = () => {
  return runCS(Environment.cloudscripts.ibf_config, {})
    .then((res) => {
      if (res.success) {
        return { success: true, data: res };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export function setCookie(name: string, value: string) {
  const daysToExpire = new Date(2147483647 * 1000).toUTCString();

  document.cookie = `${name}=${value}; expires=${daysToExpire}; path=/`;
}
export function getCookie(name: string) {
  let nameEQ = name + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
