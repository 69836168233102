import React from 'react';
import { Table } from 'reactstrap';
import StandingTableHead from './StandingTableHead';
import StandingTableBody from './StandingTableBody';
import { StandingOrder } from '../../../Models/StandingOrder';
import LoadingSpinner from '../../Shared/LoadingSpinner';
import { State } from '../../../Models/State';
import ScrollSliceComponent from '../../Shared/ScrollSliceComponent/ScrollSliceComponent';

type Props = {
  standingOrder: Array<StandingOrder>;
  state: string;
};

export default function StandingOrdersTable({ standingOrder, state }: Props) {
  return (
    <div className={`d-flex justify-content-center`}>
      {state === State.PENDING ? (
        <LoadingSpinner />
      ) : (
        <>
          <ScrollSliceComponent className="scroll-table mt-1" />
          <Table
            className={`table table-responsive-sm w-100 rounded border mt-2`}>
            <StandingTableHead />

            {standingOrder.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan={13}>
                    <h4>No results found</h4>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {standingOrder.map((standingOrderItem) => {
                  return (
                    <StandingTableBody
                      standingOrder={standingOrderItem}
                      key={standingOrderItem?._KEY}
                    />
                  );
                })}
              </tbody>
            )}
          </Table>
        </>
      )}
    </div>
  );
}
