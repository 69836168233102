import React, { useEffect, useState } from 'react';
import ProductFilterItem from './ProductFilterItem';
import Filter from '../../../Models/Filter';
import { useSelector } from 'react-redux';
import {
  addObjectParamsToPath,
  countSelectedItems,
  hrefContainsProperty
} from '../../../Utils/Utils';
import { useHistory } from 'react-router-dom';
import MixpanelService from '../../../Services/MixpanelService';
import GrowerFilterComponent from './GrowerFilterComponent';
import FavoriteFilterComponent from './FavoriteFilterComponent';
import { RootState } from '../../../Store/Reducers';
import SeeWhatProductsComponent from '../../Shared/SeeWhatProductsComponent/SeeWhatProductsComponent';

type Props = {
  showMobile: boolean;
  toggle: () => void;
  filtersCount: number;
  setFilterCount: (count: number) => void;
};

const ProductFilterList: React.FC<Props> = ({
  showMobile,
  toggle,
  filtersCount,
  setFilterCount
}) => {
  const { filters, date, totalItems, products, numberWeek } = useSelector(
    (state: RootState) => state.ProductReducer
  );
  const { filterLoading } = useSelector(
    (state: RootState) => state.LoadingReducer
  );

  const { search, filters_conditions } = useSelector(
    (state: RootState) => state.FilterReducer
  );
  const history = useHistory();
  useEffect(() => {
    filters.length > 0 && countSelectedItems(filters_conditions);
    filters.length > 0 &&
      setFilterCount(countSelectedItems(filters_conditions));
  }, [filters, filters_conditions, setFilterCount]);

  const handleClean = () => {
    MixpanelService.track('reset_all_filters');
    const params: any = { date, search_val: search };
    if (hrefContainsProperty('week')) {
      params.week = numberWeek;
    }
    history.push(addObjectParamsToPath('/search', params));
  };

  //height of the nav bar to determine the top in px for the sticky element.
  const { navHeight } = useSelector((state: RootState) => state.DisplayReducer);

  //media query js
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    const body = document.getElementById('body');
    if (body) {
      if (showMobile) {
        body.style.overflow = 'hidden';
        body.style.touchAction = 'none';
      } else {
        body.style.overflow = '';
        body.style.touchAction = '';
      }
    }
  }, [showMobile]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className={` ${
        showMobile ? 'ibf-filter-list-mobile-view ' : 'ibf-filter-desktop-view'
      } `}
      style={
        windowDimensions.width > 980
          ? {
              top: `${navHeight}px`
            }
          : {}
      }>
      <div
        className={`row ibf-filter-list-sticky ${
          showMobile ? 'mx-2 py-3' : 'd-none'
        }`}>
        <div
          onClick={toggle}
          className="ibf-filter-polygon col-5  text-center my-auto p-0">
          <span className="ml-4 ibf-filter-polygon-text">
            Show{' '}
            {`${totalItems} results ${search && 'for  " ' + search + ' " '}`}
          </span>
        </div>
        <div className="col-auto ml-auto p-0">
          <span
            className="rounded-circle ibf-filter-close-icon"
            onClick={toggle}>
            X
          </span>
        </div>
      </div>

      {products.length > 0 && (
        <div
          className={
            showMobile
              ? 'col-10 m-0 pl-3 d-flex justify-content-between'
              : 'd-none d-lg-flex mb-2 justify-content-between'
          }>
          <div className="d-flex">
            <span className="color-gray">
              {filters.length > 0 && 'Filters'}{' '}
            </span>
            <span
              className={
                filtersCount >= 1
                  ? ' text-center ibf-notification-filter-mobile'
                  : ''
              }>
              {' '}
              {filtersCount >= 1 && filtersCount}{' '}
            </span>
          </div>

          <div>
            <span className="color-gray pointer" onClick={handleClean}>
              <u>
                {Object.keys(filters_conditions).length && products.length > 0
                  ? 'Reset all filters'
                  : ''}
              </u>
            </span>
            {/*}*/}
          </div>
        </div>
      )}
      {filters.length > 0 ? (
        <>
          <FavoriteFilterComponent />

          <GrowerFilterComponent
            growers={filters.find(
              (filter: Filter) => filter.label === 'Growers'
            )}
            filterList={filters}
          />
          <SeeWhatProductsComponent />
          <div
            className={`d-flex flex-column ${
              showMobile ? 'px-3 overflow-auto border-top mt-2' : ''
            } `}
            style={{ height: '58vh' }}>
            {filters
              .filter((item: Filter) => item.label !== 'Growers')
              .map((item: Filter, index: number) => {
                return (
                  <div
                    key={'product_filter_' + index}
                    className={
                      showMobile ? 'mt-2 w-75' : 'd-none d-lg-flex mt-2'
                    }>
                    <ProductFilterItem
                      listItemsFilter={item}
                      isMobile={showMobile}
                    />
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        !filterLoading && <div className="filter-list-empty" />
      )}
    </div>
  );
};

export default ProductFilterList;
