import React from 'react';
import { StandingOrder } from '../../../Models/StandingOrder';
import { validateNumberNan } from '../../../Utils/Utils';
import StandingOrderContent from './StandingOrderContent/StandingOrderColumnItems';
import getPrices from './StandingOrderContent/StandingOrderGetPrices';
type Props = {
  standingOrder: StandingOrder;
};

export default function StandingTableBody({ standingOrder }: Props) {
  const items = standingOrder?.items[0];
  const masterList = standingOrder?.items[0]?.masterlist;
  const stemsPerBunch = parseInt(masterList?.stems_per_bunch?.stems_per_bunch!);

  return (
    <>
      {standingOrder?.items[0]?.box &&
      standingOrder?.items[0]?.custom_box_index ? (
        <>
          <tr>
            <td>{standingOrder?.consecutive}</td>
            <td>{standingOrder?.status}</td>
            <td colSpan={3}>{standingOrder?.frequency}</td>
            <td colSpan={5}>
              <p>Custom box</p>
            </td>
            {/*<td colSpan={2}>*/}
            {/*  <p>Total</p>*/}
            {/*</td>*/}
          </tr>
          {standingOrder?.items.map((res, index) => (
            <tr key={index}>
              <td className="border-0" colSpan={5} />
              <td>
                {res?.masterlist?.product_group?.common_name}-{' '}
                {res?.masterlist?.variety?.variety_name}
              </td>
              <td>
                {validateNumberNan(
                  standingOrder?.items[0]?.masterlist?.length!,
                  <StandingOrderContent
                    standingOrder={standingOrder}
                    res={res}
                    custom={true}
                    columName={'length'}
                  />
                )}
              </td>
              <td>{standingOrder?.grower?.name}</td>
              <td>
                {validateNumberNan(
                  parseInt(
                    standingOrder?.items[0]?.masterlist?.stems_per_bunch
                      ?.stems_per_bunch!
                  ) * standingOrder?.items[0]?.masterlist?.bunches_in_box!,
                  <StandingOrderContent
                    standingOrder={standingOrder}
                    res={res}
                    custom={true}
                    columName={'stems'}
                  />
                )}
              </td>
              <td>{getPrices(items, masterList!, stemsPerBunch)}</td>
            </tr>
          ))}
        </>
      ) : (
        <tr>
          <td>{standingOrder?.consecutive}</td>
          <td>{standingOrder?.status}</td>
          <td colSpan={3}>{standingOrder?.frequency}</td>
          <td>
            {standingOrder?.items[0]?.masterlist?.product_group?.common_name}-{' '}
            {standingOrder?.items[0]?.masterlist?.variety?.variety_name}
          </td>
          <td>
            {validateNumberNan(
              standingOrder?.items[0]?.masterlist?.length!,
              <StandingOrderContent
                standingOrder={standingOrder}
                custom={false}
                columName={'length'}
              />
            )}
          </td>
          <td>{standingOrder?.grower?.name}</td>
          <td>
            {validateNumberNan(
              parseInt(
                standingOrder?.items[0]?.masterlist?.stems_per_bunch
                  ?.stems_per_bunch!
              ) * standingOrder?.items[0]?.masterlist?.bunches_in_box!,
              <StandingOrderContent
                standingOrder={standingOrder}
                custom={false}
                columName={'stems'}
              />
            )}
          </td>
          <td>{getPrices(items, masterList!, stemsPerBunch)}</td>
        </tr>
      )}
    </>
  );
}
