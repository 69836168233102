import React, { useState } from 'react';
import { FormGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import {
  DeepMap,
  FieldError,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError
} from 'react-hook-form';
import { CustomerRegisterModel } from '../../Models/CustomerRegisterModel';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store/Reducers';
import { checkEmailAvailable, logout } from '../../Services/AuthService';

type Props = {
  register: UseFormRegister<CustomerRegisterModel>;
  errors: DeepMap<CustomerRegisterModel, FieldError>;
  setValue: any;
  setError: UseFormSetError<CustomerRegisterModel>;
  clearErrors: UseFormClearErrors<CustomerRegisterModel>;
  isCompanyInformation?: boolean;
};

const validationInputMessage = (
  <span className="ibf-font-12 color-red">Please fill in this info.</span>
);
const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === ' ') {
    e.preventDefault();
  }
};
const ContactDetailsComponent = ({
  register,
  errors,
  setValue,
  setError,
  clearErrors,
  isCompanyInformation
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const {
    user: {
      email,
      first_name,
      last_name,
      metadata: { customer_name, main_user_email, customer_resale_number }
    }
  } = useSelector((state: RootState) => state.AuthReducer);

  React.useEffect(() => {
    setCompanyName(customer_name);
  }, [customer_name]);

  const onBlurCheckEmail = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const email = event.target.value;
    const isAvailable = await checkEmailAvailable(email, true);
    if (!isAvailable) {
      setError('user.user_email', {
        type: 'manual',
        message: ''
      });
    } else {
      clearErrors('user.user_email');
    }
  };

  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    setCompanyName(event.currentTarget.value.replace(/[^\w\s]/gi, ''));
  };

  return (
    <div>
      <b>1. Create your login</b>
      <div className="input-register my-4">
        <Input
          type="text"
          id={'company_name'}
          placeholder=" "
          {...register('customer.company_name', {
            required: true,
            maxLength: 35,
            pattern: /([^\s])/
          })}
          maxLength={35}
          value={companyName}
          onChange={(evt) => {
            setValue('customer.company_name', evt.target.value);
            handleInput(evt);
          }}
        />
        {errors?.customer?.company_name && (
          <span className="ibf-font-12 color-red">
            Please write a company name
          </span>
        )}
        <label htmlFor="company_name" className="register-label">
          Company Name <span className="text-danger"> * </span>{' '}
        </label>
      </div>

      <FormGroup className="input-register mb-4">
        <Input
          type="text"
          id={'FirstName'}
          {...register('user.first_name', {
            required: true,
            pattern: /([^\s])/
          })}
          data-testid="first-name"
          defaultValue={first_name || ''}
          placeholder=" "
        />
        {errors?.user?.first_name && validationInputMessage}
        <label htmlFor="FirstName" className="register-label">
          Your first name <span className="text-danger"> * </span>
        </label>
      </FormGroup>

      <FormGroup className="input-register mb-3">
        <Input
          type="text"
          id={'LastName'}
          data-testid="last-name"
          placeholder=" "
          defaultValue={last_name || ''}
          {...register('user.last_name', {
            required: true,
            pattern: /([^\s])/
          })}
        />
        {errors?.user?.last_name && validationInputMessage}
        <label htmlFor="LastName" className="register-label">
          Your last name <span className="text-danger"> * </span>
        </label>
      </FormGroup>

      <FormGroup className="input-register my-4">
        <Input
          type="email"
          autoComplete="off"
          placeholder=" "
          id={'user_email'}
          readOnly
          data-testid="user-email"
          onKeyDown={handleKeyDown}
          {...register('user.user_email', {
            required: true,
            pattern: /([^\s])/
          })}
          defaultValue={main_user_email || email || ''}
          onBlur={onBlurCheckEmail}
          onChange={(evt) => setValue('user.user_email', evt.target.value)}
        />

        <div className="d-flex-flex column">
          {errors.user?.user_email?.type === 'manual' && (
            <span className="ibf-font-12 color-red">
              This email is already related to an iBuyFlowers account.{' '}
              <u className="pointer" onClick={() => logout()}>
                Cancel your sign up and login wih this email address
              </u>
              .{' '}
            </span>
          )}
          {errors?.user?.user_email &&
            errors.user?.user_email?.type !== 'manual' &&
            validationInputMessage}
        </div>
        <label
          className={'register-label'}
          htmlFor="user_email"
          placeholder=" "
          //className={`${state.user?.user_email ? 'register-label-email' : 'register-label'} `}
        >
          Email address <span className="text-danger"> * </span>{' '}
        </label>
      </FormGroup>

      {!isCompanyInformation && (
        <FormGroup className="input-register my-4">
          <Input
            type="email"
            id={'repeatEmail'}
            autoComplete="off"
            placeholder=" "
            onKeyDown={handleKeyDown}
            {...register('user.repeatEmail', {
              required: true,
              pattern: /([^\s])/
            })}
          />

          {errors?.user?.repeatEmail && validationInputMessage}
          <label
            className={'register-label'}
            placeholder=" "
            htmlFor={'repeatEmail'}
            //className={`${state.user?.user_email ? 'register-label-email' : 'register-label'} `}
          >
            Repeat email address <span className="text-danger"> * </span>{' '}
          </label>
        </FormGroup>
      )}

      <FormGroup className="input-register mb-3">
        <InputGroup>
          <Input
            type={showPassword ? 'text' : 'password'}
            autoComplete="off"
            placeholder=" "
            id={'Password'}
            onKeyDown={handleKeyDown}
            {...register('user.password', {
              required: true,
              pattern: /([^\s])/,
              minLength: 8
            })}
            defaultValue={isCompanyInformation ? '*********' : ''}
          />

          <label htmlFor="Password" className="register-label">
            Password to login (min 8 characters){' '}
            <span className="text-danger"> * </span>
          </label>
          <InputGroupAddon addonType="prepend">
            <div
              className="input-group-append"
              onClick={() => setShowPassword(!showPassword)}>
              <span className="input-group-text text-danger clickable px-2">
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </span>
            </div>
          </InputGroupAddon>
        </InputGroup>
        {errors?.user?.password && (
          <span className="ibf-font-12 color-red">
            {errors.user.password.type === 'minLength'
              ? 'Min 8 characters'
              : 'Please fill in this info'}
          </span>
        )}
      </FormGroup>

      {!isCompanyInformation && (
        <FormGroup className="input-register my-4">
          <InputGroup>
            <Input
              id={'PasswordRepeat'}
              type={showRepeatPassword ? 'text' : 'password'}
              data-testid="repeat-password"
              placeholder=" "
              onKeyDown={handleKeyDown}
              {...register('user.repeatPassword', {
                required: true,
                pattern: /([^\s])/
              })}
            />
            <div
              className="input-group-append"
              onClick={() => setShowRepeatPassword(!showRepeatPassword)}>
              <span className="input-group-text text-danger clickable px-2">
                <FontAwesomeIcon
                  icon={showRepeatPassword ? faEye : faEyeSlash}
                />
              </span>
            </div>

            <label htmlFor="PasswordRepeat" className="register-label">
              Repeat password<span className="text-danger"> * </span>{' '}
            </label>
          </InputGroup>

          {errors?.user?.repeatPassword && validationInputMessage}
        </FormGroup>
      )}

      <div className="form-group input-register mb-3">
        <Input
          type="text"
          id={'tax_id'}
          placeholder=" "
          autoComplete="off"
          {...register('customer.tax_id', {
            required: true,
            pattern: /([^\s])/
          })}
          defaultValue={customer_resale_number || ''}
        />
        {errors?.customer?.tax_id && validationInputMessage}
        <label htmlFor="tax_id" className="register-label">
          Resale number <span className="text-danger"> * </span>{' '}
        </label>
      </div>
    </div>
  );
};

export default ContactDetailsComponent;
