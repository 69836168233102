import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { authReducer } from '../../../Store/Selectors';

const daysAvailable = [
  'Mondays',
  'Tuesdays',
  'Wednesdays',
  'Thursdays',
  'Fridays'
];
export const initialStateDays = [
  'mondays',
  'tuesdays',
  'wednesdays',
  'thursdays',
  'fridays'
];

interface Iprops {
  returnClosedDays: (data: Array<string>) => void;
  isDisabled?: boolean;
  isUpdateProfileView?: boolean;
}

export default function ClosedDays({
  returnClosedDays,
  isDisabled,
  isUpdateProfileView
}: Iprops) {
  const {
    user: { metadata }
  } = useSelector(authReducer);
  const [availableAllDay, setAvailableAllDay] = useState(true);
  const [noAvailableSpecificDays, setNoAvailableSpecificDays] = useState<
    string[]
  >([]);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (noAvailableSpecificDays.find((res: string) => res === e.target.name)) {
      setNoAvailableSpecificDays(
        noAvailableSpecificDays.filter((item: string) => item !== e.target.name)
      );
      returnClosedDays(
        noAvailableSpecificDays.filter((item: string) => item !== e.target.name)
      );
    } else {
      if (initialStateDays.includes(e.target.name)) {
        setNoAvailableSpecificDays([...noAvailableSpecificDays, e.target.name]);
        returnClosedDays([...noAvailableSpecificDays, e.target.name]);
      }
    }
  };

  React.useEffect(() => {
    if (metadata.customer_closed_days && isUpdateProfileView) {
      setAvailableAllDay(false);
      const noAvailableDays = initialStateDays.filter((day) =>
        metadata.customer_closed_days.includes(day)
      );
      setNoAvailableSpecificDays(noAvailableDays);
    }
  }, [metadata.customer_closed_days, isUpdateProfileView]);

  return (
    <>
      <div className={`d-flex flex-column mb-3`}>
        <span>On which days can you receive deliveries from iBuyFlowers?*</span>
        <div className="d-flex flex-wrap">
          <div className="form-check mr-3">
            <input
              className="form-check-input input-radio-transform"
              type="radio"
              name="allDays"
              // id={option.value.toString()}
              disabled={isDisabled}
              checked={availableAllDay}
              onChange={(event) => {
                setAvailableAllDay(false);
                setNoAvailableSpecificDays([]);
              }}
            />
            <label>Monday to Friday</label>
          </div>
          <div className="form-check mr-3">
            <input
              disabled={isDisabled}
              className="form-check-input input-radio-transform"
              type="radio"
              name="specific"
              checked={!availableAllDay}
              onChange={() => setAvailableAllDay(false)}
            />
            <label>Only on specific days...</label>
          </div>
        </div>
        {!availableAllDay && (
          <div className="d-flex flex-wrap">
            {daysAvailable.map((option, index) => (
              <div className="form-check mr-3" key={index}>
                <input
                  className="form-check-input input-radio-transform"
                  type="checkbox"
                  name={option.toLowerCase()}
                  id={option}
                  disabled={isDisabled}
                  onChange={(event) => {
                    onChange(event);
                  }}
                  checked={
                    !noAvailableSpecificDays.some(
                      (availableDays: string) =>
                        option.toLowerCase() === availableDays
                    )
                  }
                />
                <label htmlFor={option}>{option}</label>
              </div>
            ))}
            <label>
              Disclaimer: we use this information to instruct the growers to pack at the right day. But in case of a delay at our logistic partners, it's out of our control.
              <br />
              Make sure you order for 3-5 days prior to your event.
            </label>
          </div>
        )}
      </div>
    </>
  );
}
