import React, { useEffect, useCallback, useState } from 'react';
import DisplayRouteComponent from '../Shared/DisplayRouteComponent';
import StandingOrdersTable from './StandingOrdersTable/StandingOrdersTable';
import { useDispatch, useSelector } from 'react-redux';
import { standingOrderReducer, authReducer } from '../../Store/Selectors';
import { actionsStandingOrder } from '../../Store/StandingOrder/Slice';
import { Pagination } from 'antd';
import Environment from '../../Environment';
import {
  redirectUrlIbuyflowers,
  standingOrderPageSize
} from '../../Utils/Utils';
import { StandingOrder } from '../../Models/StandingOrder';

export default function StandingOrderRequest() {
  const dispatch = useDispatch();
  const { state, indexCurrentPage, standingOrder } = useSelector(
    standingOrderReducer
  );

  const [totalItems, setTotalItems] = useState(0);
  const [dataSource, setDataSource] = useState<StandingOrder[]>([]);

  const {
    user: {
      metadata: { customer }
    }
  } = useSelector(authReducer);

  useEffect(() => {
    dispatch(
      actionsStandingOrder.getStandingOrder({
        customer
      })
    );
  }, [customer, dispatch]);
  useEffect(() => {
    setDataSource(
      indexCurrentPage !== 0
        ? standingOrder.slice(
            (indexCurrentPage - 1) * standingOrderPageSize,
            (indexCurrentPage - 1) * standingOrderPageSize +
              standingOrderPageSize
          )
        : standingOrder.slice(
            indexCurrentPage * standingOrderPageSize,
            indexCurrentPage * standingOrderPageSize + standingOrderPageSize
          )
    );
    setTotalItems(standingOrder.length);
  }, [standingOrder, indexCurrentPage]);

  const goToPage = useCallback(
    (pageIndex: number) => {
      dispatch(actionsStandingOrder.goToPage(pageIndex));
    },
    [dispatch]
  );

  return (
    <div className="ibf-orders-container mt-2">
      <DisplayRouteComponent currentRouteName={'Standing Orders'} />
      <span className="my-2 d-block">
        Tip: you can easily add Standing Orders via{' '}
        <a
          className="text-black underline"
          href={`${redirectUrlIbuyflowers(
            Environment.baseUrl,
            '#/ordersbyarrival'
          )}`}
          target="_blank"
          rel="noopener noreferrer">
          My Orders.
        </a>{' '}
      </span>
      <span className="mb-1d-block">
        {' '}
        Help: read about the Standing Order policy{' '}
        <a
          className="text-black underline"
          href="https:// help.ibuyflowers.com/ knowledge/standing-order"
          target="_blank"
          rel="noopener noreferrer">
          here.
        </a>
      </span>
      {<StandingOrdersTable standingOrder={dataSource} state={state} />}
      {standingOrder.length > 0 && (
        <Pagination
          pageSize={standingOrderPageSize}
          current={indexCurrentPage}
          className="text-center"
          total={totalItems}
          onChange={goToPage}
        />
      )}
    </div>
  );
}
