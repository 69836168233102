import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actionsCustomer } from '../../Store/Customer/Slice';
import {
  convertNumberDateDDDMMMYYYY,
  isValidClosingTime,
  redirectToCheckout,
  toast,
  USA
} from '../../Utils/Utils';
import { actionsShoppingList } from '../../Store/ShoppingList/Slice';
import CardItemsToPurchase from '../Shared/CardItemsToPurchase/CardItemsToPurchase';
import { Button } from 'reactstrap';
import { State } from '../../Models/State';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

import {
  authReducer,
  cartReducer,
  checkoutReducer,
  customerReducer,
  loadingReducer,
  shoppingListReducer
} from '../../Store/Selectors';

import useDeviceDetect from '../../Hooks/useDeviceDetect';
import { actionsModal } from '../../Store/Modal/Slice';
import { CONFIRMATION_CHECKOUT_MODAL } from '../../Store/Modal/Types';
import ImageComponent from '../Shared/ImageComponent/ImageComponent';
import Environment from '../../Environment';
//import HeaderGuestUserComponent from '../NavBarComponent/HeaderComponent/HeaderGuestUserComponent';

type Props = {
  drawerVisible: boolean;
  toggle: () => void;
};

const CartMenuComponent = ({ drawerVisible, toggle }: Props) => {
  const [count, setCount] = useState(0);
  const { state } = useSelector(shoppingListReducer);
  const [isCalculable, setIsCalculable] = useState(false);
  const { cartProducts } = useSelector(cartReducer);
  const { user } = useSelector(authReducer);
  const { selectedAddress } = useSelector(customerReducer);
  const { cartLoading } = useSelector(loadingReducer);
  const { isMobile } = useDeviceDetect();

  const {
    checkoutInfo,
    itemsInCart,
    incompleteProducts,
    orderRequests,
    products
  } = useSelector(checkoutReducer);

  useEffect(() => {
    const guest = user.metadata.guest;
    if (guest && selectedAddress.zipcode) {
      setIsCalculable(true);
    } else {
      if (
        !guest &&
        selectedAddress.state &&
        selectedAddress.address &&
        selectedAddress.city &&
        selectedAddress.zipcode
      ) {
        setIsCalculable(true);
      } else {
        setIsCalculable(false);
      }
    }
  }, [user.metadata.guest, selectedAddress]);

  const dispatch = useDispatch();
  useEffect(() => {
    let productCount = 0;
    Object.keys(cartProducts).forEach((key: string) => {
      productCount += cartProducts[key].length;
    });
    setCount(productCount);
  }, [cartProducts]);

  // validate if there are items in the checkout
  const [checkout, setCheckout] = useState(false);

  useEffect(() => {
    if (
      (checkoutInfo && checkoutInfo.results && checkoutInfo.results.carts) ||
      incompleteProducts?.length ||
      orderRequests?.length
    ) {
      if (
        checkoutInfo?.results?.carts?.length ||
        incompleteProducts?.length ||
        orderRequests?.length
      ) {
        setCheckout(true);
      }
    }
  }, [checkoutInfo, incompleteProducts, orderRequests]);

  const sendSL = async () => {
    if (isCalculable) {
      dispatch(
        actionsShoppingList.createShoppingList({
          customer: user.metadata.customer,
          address: selectedAddress
        })
      );
    } else {
      toast('Please select an address.', 'error');
    }
  };

  const checkAvailableProducts = () => {
    const noAvailableProducts: {
      _KEY: string;
      product_group: string;
      grower: string;
      eta_date: number;
      variety: string;
      image: string;
    }[] = [];

    products.forEach((cart_box) => {
      if (cart_box.grower.closing_time && cart_box.grower.country === USA) {
        if (
          !isValidClosingTime(
            cart_box.grower.closing_time,
            cart_box.packing_date
          )
        ) {
          cart_box.items.forEach((cbItem) => {
            noAvailableProducts.push({
              _KEY: cbItem._KEY,
              grower: cart_box.grower.company_name ?? '',
              eta_date: cart_box.eta_date,
              variety: cbItem.variety.variety_name,
              product_group: cbItem.product_group.common_name,
              image: Environment.publicPath + cbItem.images[0]
            });
          });
        }
      }
    });

    if (noAvailableProducts.length > 0) {
      const body = (
        <div className="d-flex flex-column">
          <span className="mb-2">
            The following products are not available because the grower time to
            packing this products is closed. The products will be deleted of the
            cart.
          </span>

          <ul className="list-group list-unstyled">
            {noAvailableProducts.map((product) => (
              <li key={product._KEY} className="border-bottom py-3">
                <div className="d-flex align-items-center">
                  <ImageComponent
                    imageUrl={product.image}
                    width={'90px'}
                    height={'90px'}
                    className="border mr-2"
                  />
                  <div className="d-flex flex-column">
                    <span>
                      <b>Product: </b> {product.product_group} {product.variety}
                    </span>
                    <span>
                      <b>Grower: </b> {product.grower}
                    </span>
                    <span>
                      <b>Delivery date: </b>
                      {convertNumberDateDDDMMMYYYY(product.eta_date)}
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );

      dispatch(
        actionsModal.setOpenModal({
          modal: CONFIRMATION_CHECKOUT_MODAL,
          data: {
            title: 'No available products',
            body,
            confirmButtonText: 'Continue to checkout',
            cancelButtonText: 'Change products',
            inlineFooter: true,
            confirmAction: () => redirectToCheckout(isCalculable)
          }
        })
      );
    } else {
      redirectToCheckout(isCalculable);
    }
  };

  const goToCheckout = () => {
    if (checkout && itemsInCart) {
      if (count === 0) {
        const preferences = {
          ...user.metadata.preferences,
          ...{ hideGreetingModal: true }
        };
        dispatch(
          actionsCustomer.updateUserPreferences({
            customer: user.metadata.customer,
            preferences
          })
        );
        checkAvailableProducts();

        // redirectToCheckout(isCalculable);
      } else {
        sendSL();
      }
    } else {
      sendSL();
    }
  };

  const getCheckoutButtonText = () => {
    let upText = null;
    if (checkout && itemsInCart) {
      upText =
        count === 0
          ? 'Review checkout '
          : 'Calculate the best deal & review checkout ';
    } else {
      upText = 'Yes, calculate & review checkout';
    }

    return (
      <>
        <span>{upText} &#187;</span>
        <br />
        <span className="ibf-font-10">(you won’t get charged yet)</span>
      </>
    );
  };

  const cartButton = (
    <div className="ibf-cart-button">
      <Button
        color={'primary'}
        disabled={state === State.PENDING || cartLoading}
        className="ibf-line-height-12 px-5 w-100"
        onClick={goToCheckout}>
        {state === State.PENDING && (
          <FontAwesomeIcon icon={faSpinner} pulse className={'mr-2'} />
        )}
        {getCheckoutButtonText()}
      </Button>
    </div>
  );

  const title = (
    <div className="d-flex flex-column mb-4 ">
      {/* {user.metadata.guest?( <div className="mb-4  py-2  shadow-lg" style={{backgroundColor:"#e83293"}}>
        <HeaderGuestUserComponent  />
      </div>):<div className="mb-4"/>} */}

      <div className="ibf-cart-header mx-4">
        <h5 className="ibf-cart-title">
          {' '}
          Your Cart{' '}
          {!cartLoading &&
            ((itemsInCart > 0 || count) > 0
              ? `(${count + itemsInCart} ${
                  count + itemsInCart === 1 ? 'item' : 'items'
                })`
              : 'is empty')}
        </h5>

        {itemsInCart > 0 || count > 0 ? cartButton : null}

        <div className="ibf-close-button">
          <span onClick={toggle} className="rounded-circle close-button">
            X
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <Drawer
      title={title}
      //headerStyle={{padding:"0"}}
      placement="right"
      closable={false}
      onClose={toggle}
      zIndex={1025}
      width={isMobile ? '94vw' : '40em'}
      style={{ backgroundColor: 'rgb(0 0 0 / 1%)' }}
      bodyStyle={{ padding: '0' }}
      visible={drawerVisible}
      drawerStyle={{ backgroundColor: '#f2f2f2' }}>
      <CardItemsToPurchase toggle={toggle} />
    </Drawer>
  );
};

export default CartMenuComponent;
