import React from 'react';
import StandingOrderPolicyItems from './StandingOrderPolicyItems';

type Props = {
  weeklySelected: string;
  endDate: string | Date;
};

const text = (
  <ul className="px-4">
    <li>Your Standing Order price will be secured from start to end date.</li>
    <li>The Standing Order will stop automatically at the end date.</li>
    <li>
      After the grower approves your Standing Orders, you have a seven days
      review period to cancel, or up to 2 days before the first packing at the
      grower if it applies earlier.
    </li>
    <li>
      After the review period, you are committed to the Standing Order agreement
      until the end date.
    </li>
  </ul>
);

export default function StandingOrderPolicy({
  weeklySelected,
  endDate
}: Props) {
  return (
    <div className="mt-3">
      <span className="d-block">Our Standing Orders policy:</span>
      <StandingOrderPolicyItems
        text={`You get our best price secured up to ${weeklySelected}.`}
      />
      <StandingOrderPolicyItems
        text={`The Standing Order stops automatically, the last delivery will be on ${endDate}.`}
      />
      <StandingOrderPolicyItems
        text={`We confirm your request within 3-7 work days via email
        `}
        element={text}
      />
      <StandingOrderPolicyItems
        text={`You're allowed to pause 2 shipments (weekly) or 1 shipment (bi-weekly).`}
      />
    </div>
  );
}
