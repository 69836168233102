import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import ReactExport from 'react-data-export';
import { Popover } from 'antd';
import {
  columnName,
  convertDateToStringDate,
  convertNumberDateToDate,
  convertNumberDateYYYYMMDD,
  findEventName,
  formatValueExcel,
  getBoxQuantity,
  roundUp,
  showTotalPrice,
  toast,
  // toast,
  totalPriceDataSet,
  uuidV4
} from '../../Utils/Utils';
import { authReducer, eventReducer, orderReducer } from '../../Store/Selectors';
import { actionsModal } from '../../Store/Modal/Slice';
import { CONFIRM_DELIVERY_DATE_MODAL } from '../../Store/Modal/Types';
import { State } from '../../Models/State';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { actionsOrder } from '../../Store/Order/Slice';
import { actionsCustomer } from '../../Store/Customer/Slice';
import OrderCustomBoxRow from './OrderCustomBoxRow';
import OrderBoxRow from './OrderBoxRow';
import moment from 'moment';
import { OrderItem, OrderItemCsv } from '../../Models/Order';
import { TrackEventMonitor } from '../../Classes/TrackEvent';
import './OrderTable.scss';
import SubHeaderEventByName from './SubHeaderEventByName';
import { TypeTable } from '../OrdersByEtaComponent/OrdersByEtaComponent';
import { Order, OrderPurchase } from '../../Store/Order/Types';
import ResponseCS from '../../Models/ResponseCS/ResponseCS';
import { getPriceStandingOrder } from '../../Services/ProductService';
import { getAllStandingOrderService } from '../../Services/CustomBoxService';
import { ResponseSbx } from '../../Models/ResponseSbx';
import { RootState } from '../../Store/Reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsH } from '@fortawesome/pro-light-svg-icons';
// import { RootState } from '../../Store/Reducers';
// import { getPriceStandingOrder } from '../../Services/ProductService';
// import { getAllStandingOrderService } from '../../Services/CustomBoxService';
// import ResponseCS from '../../Models/ResponseCS/ResponseCS';
// import { ResponseSbx } from '../../Models/ResponseSbx';

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet = ExcelFile.ExcelSheet;
const ExcelColumn = ExcelFile.ExcelColumn;

type Props = {
  typeTable: TypeTable;
  data: Order[] | OrderPurchase[];
  headers: string[];
  isSearching?: boolean;
  goToOrderDetail: (order_key: string) => void;
  boxDeleting?: string;
  deleteHandler?: (
    cartbox: string,
    customer: string,
    price: any,
    requestedByCustomer: boolean,
    box: any
  ) => void;
};

interface Data {
  inventories: Inventory[];
  customer: {
    margin: number;
    zipcode: string;
  };
}

interface So {
  address: string;
  consecutive: number;
  customer: string;
  end_date: number;
  frequency: string;
  from_cartbox: string;
  grower: string;
  hubspot_id: any;
  hubspot_sync: boolean;
  running: boolean;
  start_date: number;
  status: string;
  _KEY: string;
  _META: object;
}

interface Inventory {
  _KEY: string;
  variety: string;
  masterlist: string;
  week: number;
  eta_date: number;
  price: number;
  combo: number;
  grower: string;
  quantity: number;
}

interface PriceSo {
  cartbox_prices: object;
}

const OrdersTableComponent = (props: Props) => {
  const authRedux = useSelector(authReducer);
  const [visibleReorderPopover, setVisibleReorderPopover] = useState(false);
  // const [repurchase, setRepurchase] = useState(false);
  // console.log("INI")
  const { state, orderDetail, repurchase, ordersByArrivalLoaded } = useSelector(
    orderReducer
  );
  const { metadata } = useSelector(
    (state: RootState) => state.AuthReducer.user
  );
  const {
    user: {
      metadata: { isFirstRepurchase }
    }
  } = useSelector(authReducer);
  const events = useSelector(eventReducer);
  const dispatch = useDispatch();
  const openCalendar = useCallback(
    (boxes: any) => {
      dispatch(
        actionsModal.setOpenModal({
          modal: CONFIRM_DELIVERY_DATE_MODAL,
          data: { isMultipleSelection: true, boxes }
        })
      );

      let cart_box_key = '';
      const products = boxes.reduce((total: {}[], box: OrderItem) => {
        if (box.product_group !== 'Custom Box') {
          if (boxes.length === 1) {
            cart_box_key = box._KEY ?? '';
          }
          total.push({
            variety_key: box.variety_key,
            product_group_key: box.product_group_key,
            quantity: box.total_stems ?? getBoxQuantity(box),
            length: box.length,
            uom: box.masterlist_uom,
            grower_key: boxes[0].grower_obj._KEY,
            customer: authRedux.user.metadata.customer,
            box_only: box.box_only,
            cart_box_item_key: box.cart_box_item_key,
            repurchase_cartbox_item: box.cart_box_item_key,
            customizable: box.customizable
          });
        } else {
          cart_box_key = box._KEY ?? '';
        }

        return total;
      }, []);

      const propsEvent = {
        cart_box_key,
        selected_dates: [],
        box_items: products
      };

      TrackEventMonitor.newEvent({
        props: propsEvent,
        metadata: { name: 'ibf_reorder_click' }
      });
    },
    [dispatch, authRedux.user.metadata.customer]
  );

  React.useEffect(() => {
    setVisibleReorderPopover(
      !authRedux.user.metadata?.preferences?.hideRepurchasePopin &&
        isFirstRepurchase
    );
  }, [isFirstRepurchase, authRedux.user.metadata]);

  const closeMessage = () => {
    const preferences = {
      ...authRedux.user.metadata.preferences,
      hideRepurchasePopin: true
    };

    dispatch(
      actionsCustomer.updateUserPreferences({
        customer: authRedux.user.metadata.customer,
        preferences
      })
    );
    setVisibleReorderPopover(false);
  };

  useEffect(() => {
    if (repurchase && orderDetail.cart_box) {
      let boxItems: Array<any> = [];
      orderDetail.cart_box.forEach((box: any) => {
        boxItems = [
          ...boxItems,
          ...box.items.map((item: any) => {
            return {
              _KEY: item._KEY,
              variety_key: item.variety,
              product_group_key: item.product_group,
              grower: box.grower,
              grower_obj: item.grower_obj,
              length: item.masterlist.length,
              total_stems: item.quantity * item.stems_per_bunch,
              stem_bunch: item.stems_per_bunch,
              total_bunches: item.quantity,
              customizable: item.masterlist.customizable,
              product_name: `${item.common_name} ${item.variety_name} ${item.masterlist.length}cm`,
              cart_box_item_key: item._KEY
            };
          })
        ];
      });
      openCalendar(boxItems);
    }
  }, [repurchase, openCalendar, orderDetail.cart_box]);

  const fetchDetails = (key: string) => {
    dispatch(
      actionsOrder.fetchOrderDetail({
        key,
        ordersByPurchaseLoaded: true,
        repurchase: true
      })
    );
  };

  const getSubheader = (data: Order) => {
    let dataset: Array<OrderItemCsv> = [];

    /* data?.forEach((item: any) => {
        item.forEach((subitem: any) => {
          dataset.push(subitem);
        });
      }); */
    if (data[0]) {
      (props.data as Order[]).forEach((item: Order) => {
        item.forEach((subitem: any) => {
          subitem.forEach((element: OrderItemCsv) => {
            let { objectElement } = formatValueExcel(element);
            dataset.push(objectElement);
          });
        });
        if (authRedux.user.metadata.is_available_event_name_view) {
          let totals = showTotalPrice(dataset);
          let dataRow: OrderItemCsv = {};
          dataset.push(dataRow);
          let firstRow: boolean = false;

          for (let [key, value] of Object.entries(totals)) {
            if (value !== 0) {
              let row: OrderItemCsv = {
                event_name: key,
                total_price: roundUp(value)
              };
              if (!firstRow) {
                row.grower = 'Total for:';
                firstRow = true;
              }
              dataset.push(row);
            }
          }
        } else {
          let totalPrices = totalPriceDataSet(dataset);
          let dataRow: OrderItemCsv = {};
          dataset.push(dataRow);
          let firstRow: boolean = false;

          let row: OrderItemCsv = {
            total_price: roundUp(totalPrices)
          };
          if (!firstRow) {
            row.grower = 'Total for:';
            firstRow = true;
          }

          dataset.push(row);
        }
      });

      let btn = (
        <button className="btn btn-purple" type="button">
          Download Excel File
        </button>
      );
      const columnNameAlt = { ...columnName };
      columnNameAlt.charge_date = 'Charge date';
      let string_date = data[0][0].eta_date.toString();
      /* const year = string_date.slice(0, 4); */
      /*  const month = string_date.slice(4, 6); */
      /*  const day = string_date.slice(6, 8); */
      const day = string_date.slice(6, 8);

      const dayLetter = moment(string_date).format('dddd');
      const month = moment(string_date).format('MMMM');
      let quantity = data.length;

      return (
        <thead key={uuidV4()} className="overflow-hidden">
          <tr className="bg-ibf-calculating border-0 overflow-hidden">
            <th colSpan={8} className="border-bottom-0 align-top pb-0">
              <span className="ibf-font-17 font-weight-bold">
                {`${dayLetter}, ${month} ${day} delivery (${quantity} ${
                  quantity > 1 ? 'boxes)' : 'box)'
                }`}
              </span>
            </th>

            <th
              className="text-right border-bottom-0 align-top pb-0"
              colSpan={5}>
              <ExcelFile element={btn} filename="Orders">
                <ExcelSheet data={dataset} name="Orders">
                  {Object.keys(columnNameAlt)
                    .filter((item) =>
                      authRedux.user.metadata.is_available_event_name_view
                        ? item
                        : item !== 'event_name'
                    )
                    .map((item: string) => {
                      return (
                        <ExcelColumn
                          label={columnNameAlt[item]}
                          value={item}
                          key={uuidV4()}
                        />
                      );
                    })}
                </ExcelSheet>
              </ExcelFile>
            </th>
          </tr>
          <tr className="bg-ibf-light-gray text-dark overflow-hidden border-bottom-0">
            <th
              className=" font-weight-light border-top-0 py-0 text-nowrap"
              colSpan={3}>
              Purchase# - Order#
            </th>
            <th className=" font-weight-light border-top-0 py-0 text-nowrap">
              Product group - Variety
            </th>
            <th className="font-weight-light border-top-0 py-0">Length</th>
            <th className="font-weight-light border-top-0 py-0"> Grower</th>
            <th className=" font-weight-light border-top-0 py-0">Stems</th>
            <th className="font-weight-light border-top-0 text-nowrap py-0">
              Total price
            </th>
            <th colSpan={5} className=" border-top-0 py-0"></th>
          </tr>
        </thead>
      );
    }

    return null;
  };

  const popoverContent = (
    <div className="d-flex flex-column align-items-center">
      <div className="font-weight-bold">Re-order a box</div>
      <div style={{ width: '250px', textAlign: 'center' }}>
        Use this feature for the first time and get a $10 off coupon code!
        (Valid for 1 month)
      </div>
      <div
        className="clickable underline font-weight-bold"
        onClick={closeMessage}>
        Close
      </div>
    </div>
  );

  const getDetailButton = (order_key: any) => {
    return (
      <div className="btn-group dropdown" key={uuidV4()}>
        <button
          style={{ width: '7em' }}
          className="btn btn-sm btn-purple"
          onClick={() => {
            props.goToOrderDetail(order_key);
          }}>
          Box details
        </button>
      </div>
    );
  };

  const getFooterTable = (source: any) => {
    let total_price = 0;

    let string_date = source[0][0].eta_date.toString();
    const year = string_date.slice(0, 4);
    const month = string_date.slice(4, 6);
    const day = string_date.slice(6, 8);
    // console.log("source", source)
    source.forEach((item: any) => {
      total_price += item[0].is_custom_box
        ? item[0].price
        : item[0].total_price;
      // item.forEach((subitem: any) => {
      //   if (subitem.total_price) {
      //     total_price += subitem.total_price;
      //   }
      // });
    });

    return (
      <tr key={uuidV4()}>
        {events.filteredSearchEvent === 'eventName' && events.eventName !== '' && (
          <th
            className="pb-3"
            colSpan={13}
            style={{
              background: findEventName(
                events.eventList,
                events.eventName,
                events.filteredSearchEvent
              )?.color
            }}
          />
        )}

        {events.filteredSearchEvent !== 'eventName' && (
          <>
            <td colSpan={8} />
            <td className="text-rigth" colSpan={2}>
              <b>
                {' '}
                Total for {month}/{day}/{year}
              </b>
            </td>
            <td className="text-right">
              <b> ${roundUp(total_price)}</b>
            </td>
            <td />
            <td />
          </>
        )}
      </tr>
    );
  };

  const reOrderPurchaseButton = (item: any, index: number) => {
    return (
      <>
        <Popover
          trigger="click"
          placement={'top'}
          overlayClassName="custom-step-desktop-popover"
          content={popoverContent}
          visible={visibleReorderPopover && index === 0}>
          <button
            className="btn ibf-btn-grey"
            onClick={() => fetchDetails(item.order_detail)}>
            Re-order this box (select new date)
          </button>
        </Popover>
      </>
    );
  };

  const shouldDeleteCartbox = (charge_date: any) => {
    let chargeDate = convertNumberDateToDate(charge_date);
    if (chargeDate) {
      chargeDate.setDate(chargeDate.getDate() - 1);

      return !moment()
        .tz('America/New_York')
        .isAfter(
          moment.tz(
            `${convertNumberDateYYYYMMDD(chargeDate)} 08:00:00`,
            'America/New_York'
          )
        );
    }

    return false;
  };

  const [resultPrice, setResultPrice] = useState<PriceSo>({
    cartbox_prices: {}
  });
  const [so, setSo] = useState<So[]>([]);
  useEffect(() => {
    const data = props.data.map((source: any) => source);
    function getData(array: any[]): Data {
      let items: Inventory[] = [];
      const inventories: Inventory[] = array.reduce((array: any[], object) => {
        object.forEach((object2: any) => {
          if (object2[0].custom) {
            object2.slice(1).forEach((object3: any) => {
              if (object3.inventory) {
                let inventory = Object.assign({}, object3.inventory);
                delete inventory.kind_quantity;
                delete inventory.kind;
                delete inventory.end_date;
                delete inventory.is_deal;
                delete inventory._META;
                inventory.total_bunches = object3.quantity;
                items.push(inventory);
              }
            });
            array.push({ cart_box_key: object2[0]._KEY, items: items });
            items = [];
          } else {
            let object3 = object2[0];
            if (object3.inventory) {
              let inventory = Object.assign({}, object3.inventory);
              delete inventory.kind_quantity;
              delete inventory.kind;
              delete inventory.end_date;
              delete inventory.is_deal;
              delete inventory._META;
              inventory.cart_box_key = object2[0]._KEY;
              inventory.total_bunches = object2[0].total_bunches;
              array.push(inventory);
            }
          }
        });
        return array;
      }, []);

      return {
        inventories: inventories,
        customer: {
          margin: metadata.customer_margin,
          zipcode: metadata.customer_zipcode
        }
      };
    }

    const confirmAction = async () => {
      const res: ResponseCS = await getPriceStandingOrder(getData(data));
      if (res.response.success) {
        setResultPrice(res.response);
      } else {
        toast('Error getting price from so', 'error');
      }
    };
    if (data.length) {
      confirmAction();
      getAllStandingOrder();
    }
  }, [props.data, metadata]);

  const getAllStandingOrder = async () => {
    const res: ResponseSbx = await getAllStandingOrderService();
    if (res.success) {
      setSo(res.results);
    }
  };

  const renderTableByType = (props: any) => {
    switch (props.typeTable) {
      case TypeTable.ARRIVAL_DATE:
        // from each day

        //Check first if it is empty
        if (props.data[0]) {
          return (
            props.data &&
            props.data.map((source: Order, dataIndex: number) => {
              const lastIndexSource = source.length - 1;
              let element = source;
              let header = null;
              header = getSubheader(element);
              return (
                <Fragment key={uuidV4()}>
                  {ordersByArrivalLoaded && <>{header}</>}

                  <tbody key={uuidV4()}>
                    {source.map((item, sourceIndex: any) => {
                      const lastIndex = item.length - 1;

                      // from each item in the order
                      const isCustomBox =
                        item[0].product_group === 'Custom Box';
                      return item.map((row, index: any) => {
                        let buttonAction;

                        let tr;
                        if (index === 0) {
                          buttonAction = getDetailButton(row.order_detail);
                        }
                        let soExist =
                          so && so.find((res) => res.from_cartbox === row._KEY);
                        if (row.product_group === 'Custom Box') {
                          tr = (
                            <OrderCustomBoxRow
                              masterlist_prices={resultPrice?.cartbox_prices}
                              soExist={soExist}
                              box={row}
                              shouldDeleteCartbox={shouldDeleteCartbox}
                              deleteHandler={props.deleteHandler}
                              openCalendar={openCalendar}
                              itemOrder={item}
                              popoverContent={popoverContent}
                              isDeleting={row._KEY === props.boxDeleting}
                              buttonAction={buttonAction}
                              onClick={() =>
                                props.goToOrderDetail(row.order_detail)
                              }
                              isPopoverVisible={
                                visibleReorderPopover &&
                                dataIndex === 0 &&
                                sourceIndex === 0 &&
                                index === 0
                              }
                            />
                          );
                        } else {
                          tr = (
                            <OrderBoxRow
                              soExist={soExist}
                              // soExist={false}
                              masterlist_prices={resultPrice?.cartbox_prices}
                              box={row}
                              isDeleting={row._KEY === props.boxDeleting}
                              deleteHandler={props.deleteHandler}
                              openCalendar={openCalendar}
                              itemOrder={item}
                              isCustomBox={isCustomBox}
                              buttonAction={buttonAction}
                              onClick={() => {
                                props.goToOrderDetail(row.order_detail);
                              }}
                              popoverContent={popoverContent}
                              isPopoverVisible={
                                visibleReorderPopover &&
                                dataIndex === 0 &&
                                sourceIndex === 0 &&
                                index === 0
                              }
                              shouldDeleteCartbox={shouldDeleteCartbox}
                            />
                          );
                        }

                        // footer with the total of the day shown at the end
                        let footerTable;
                        if (
                          lastIndex === index &&
                          lastIndexSource === sourceIndex
                        ) {
                          footerTable = getFooterTable(source);
                        }

                        return (
                          <Fragment key={uuidV4()}>
                            {tr}
                            {footerTable}
                          </Fragment>
                        );
                      });
                    })}
                  </tbody>
                </Fragment>
              );
            })
          );
        } else {
          return (
            <tbody>
              <tr>
                <td colSpan={13}>
                  <h4>No results found</h4>
                </td>
              </tr>
            </tbody>
          );
        }
      case TypeTable.PURCHASE_DATE:
        return (
          <tbody key={uuidV4()}>
            {props.data.map((item: any, index: number) => {
              let buttonAction = getDetailButton(item.order_detail);

              return (
                <Fragment key={uuidV4()}>
                  <tr key={`po-pd-${index}`}>
                    <td className="p-2"> #{item.po} </td>
                    <td className="p-2">
                      {' '}
                      {convertDateToStringDate(item.date)}{' '}
                    </td>
                    <td className="p-2"> {item.user} </td>
                    <td className="p-2"> {item.boxes} </td>
                    <td className="p-2"> {item.status} </td>
                    <td className="p-2"> ${roundUp(item.subtotal)} </td>
                    <td className="p-2"> ${roundUp(item.total)} </td>
                    <td className="p-2"> {buttonAction} </td>
                    <td className="d-none d-lg-table-cell p-2">
                      {reOrderPurchaseButton(item, index)}
                    </td>
                  </tr>

                  <tr
                    className="d-table-row d-lg-none"
                    style={{ borderTop: 'solid 2px white' }}>
                    <td colSpan={10} className="pt-0 pr-2 text-right">
                      {reOrderPurchaseButton(item, index)}
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        );
      default:
        break;
    }
  };

  return (
    <div
      className={`d-flex   ${
        state === State.PENDING || props.isSearching
          ? 'justify-content-center'
          : ''
      }`}>
      {state === State.PENDING || props.isSearching ? (
        <LoadingSpinner />
      ) : (
        <>
          <span
            className="position-absolute px-2 ibf-font-12 align-items-center d-flex d-lg-none text-white"
            style={{
              left: '45vw',
              background: '#848484',
              borderBottomRightRadius: '10px',
              borderBottomLeftRadius: '10px'
            }}>
            <FontAwesomeIcon
              icon={faArrowsH}
              className="mr-1"
              size={'2x'}
              color={'white'}
            />
            SCROLL
          </span>
          <Table
            className={`table table-responsive-sm w-100 rounded
              ${
                events.filteredSearchEvent === 'eventName' &&
                events.tableHeaderColor !== '' &&
                props.typeTable !== TypeTable.PURCHASE_DATE
                  ? 'ibf-border-table'
                  : 'border'
              } 
            `}
            style={{
              borderColor: findEventName(
                events.eventList,
                events.tableHeaderColor,
                events.filteredSearchEvent
              )?.color
            }}>
            <thead className="rounded">
              {props.typeTable === TypeTable.PURCHASE_DATE && (
                <tr>
                  {props.headers.map((header) => (
                    <th key={uuidV4()}>{header}</th>
                  ))}
                </tr>
              )}
            </thead>

            {props.typeTable !== TypeTable.PURCHASE_DATE && (
              <SubHeaderEventByName data={props.data as any} />
            )}

            {renderTableByType(props)}
          </Table>
        </>
      )}
    </div>
  );
};

export default OrdersTableComponent;
