import React, { useEffect, useState } from 'react';
import { faWindowClose } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { actionsCustomer } from '../../../Store/Customer/Slice';
import { RootState } from '../../../Store/Reducers';
import { getCountDownHeaderGuest, hrefToObject } from '../../../Utils/Utils';
import moment from 'moment';
import { actionsModal } from '../../../Store/Modal/Slice';
import { SIGN_UP_MODAL } from '../../../Store/Modal/Types';
import { Popover } from 'antd';
import { authReducer, checkoutReducer } from '../../../Store/Selectors';
import { referralPopoverText } from '../../Shared/Modal/LoginModal/WelcomeModal/WelcomeModal';

type Props = {
  getNavHeight?: () => void;
};

const HeaderGuestUserComponent: React.FC<Props> = ({ getNavHeight }) => {
  const { customer, referral_code }: { [key: string]: any } = hrefToObject();
  const { user } = useSelector(authReducer);
  const { products } = useSelector(checkoutReducer);
  const [customerName, setCustomerName] = useState('');
  const [countDown, setCountDown] = useState<string | any>('');

  const cartProducts = useSelector(
    (state: RootState) => state.CartReducer.cartProducts
  );
  const [hideHeader, setHideHeader] = useState(
    user.metadata.preferences && user.metadata.preferences.hideHeader
      ? user.metadata.preferences.hideHeader
      : false
  );

  const dispatch = useDispatch();

  const closeMessage = async () => {
    const preferences = {
      ...user.metadata.preferences,
      ...{ hideHeader: true }
    };
    dispatch(
      actionsCustomer.updateUserPreferences({
        customer: user.metadata.customer,
        preferences
      })
    );
    await setHideHeader(true);
    getNavHeight && getNavHeight();
  };

  useEffect(() => {
    if (Object.values(cartProducts).length > 0 || products.length > 0) {
      const key: any = Object.keys(cartProducts)[0];
      let product;
      if (Object.values(cartProducts).length > 0 && products.length > 0) {
        let time1 = cartProducts[key][0]._META.created_time;
        let time2 = products[0]._META.created_time;
        time1 = moment(time1)
          .tz('America/New_York')
          .format();
        time2 = moment(time2)
          .tz('America/New_York')
          .format();
        if (Date.parse(time1) > Date.parse(time2)) {
          product = products[0];
        } else {
          product = cartProducts[key][0];
        }
      } else if (cartProducts[key]) {
        product = cartProducts[key][0];
      } else if (products.length > 0) {
        product = products[0];
      } else {
        setHideHeader(true);
      }

      if (product?._META?.created_time) {
        let getCount = getCountDownHeaderGuest(
          moment.utc(product?._META?.created_time)
        );

        setCountDown(getCount);

        setHideHeader(false);
      }
    } else {
      setHideHeader(user.metadata?.preferences['hideHeader']);
    }
  }, [products, cartProducts, user.metadata]);

  React.useEffect(() => {
    if (referral_code) {
      setCustomerName(customer);
    }
    return () => {};
  }, [hideHeader, customer, referral_code]);

  useEffect(() => {
    getNavHeight && getNavHeight();
  }, [getNavHeight]);

  const showSignUpModal = () => {
    dispatch(actionsModal.setOpenModal({ modal: SIGN_UP_MODAL }));
  };

  return (
    <>
      {!hideHeader &&
        (Object.values(cartProducts).length > 0 || products.length > 0 ? (
          <div className="ibf-header-background p-2 d-flex justify-content-center">
            <div className="ibf-header-text d-flex flex-column ">
              <div className="d-flex mx-1 text-break">
                {countDown === 'Expired' ? (
                  <span>
                    Your cart items were deleted because your 3 hour guest user
                    window has expired.
                  </span>
                ) : (
                  <span>
                    <span id="customer_header_guest" className="mr-1" />
                    left to check out cart items.
                    <span
                      className="ml-1 font-weight-bold"
                      onClick={showSignUpModal}>
                      {' '}
                      Want more time?{' '}
                      <span className="underline pointer font-weight-light">
                        Sign up with us (it's free!){' '}
                      </span>
                    </span>
                  </span>
                )}
              </div>
            </div>
          </div>
        ) : (
          <Popover
            visible={referral_code?.length > 0}
            placement={'leftTop'}
            content={referralPopoverText(customerName)}
            overlayClassName={'ibf-popover-referral'}>
            <div className="ibf-header-background  px-3 py-2 d-flex justify-content-between">
              <div className="d-flex justify-content-between w-100">
                <div className="ibf-guest-header-text">
                  Hello{' '}
                  {user && (user?.metadata?.main_user_email ?? user.email)}.
                  We'll save your cart for 3 hours. To save your cart longer,
                  <u onClick={showSignUpModal} className="pointer ml-1">
                    sign up now
                  </u>{' '}
                  or place your first order within 3 hours.
                </div>
                <div onClick={closeMessage}>
                  <FontAwesomeIcon
                    className="pointer"
                    color="white"
                    icon={faWindowClose}
                  />
                </div>
              </div>
            </div>
          </Popover>
        ))}
    </>
  );
};

export default HeaderGuestUserComponent;
