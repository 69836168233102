import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../Models/State';
import { CartState } from './Types';
import {
  convertDateToNumberDate,
  convertNumberDateToDate
} from '../../Utils/Utils';
const { IDLE, REJECTED, RESOLVED, PENDING } = State;

const initialState: CartState = {
  cartProducts: {},
  charge_dates: [],
  deliveryDateCart: [],
  state: IDLE
};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addProduct(state, { payload }) {
      return state;
    },
    listCart(
      state,
      { payload }: PayloadAction<{ customer: string; deleteCartbox?: boolean }>
    ) {
      state.state = PENDING;
    },
    setProductsToListCart(
      state,
      { payload }: PayloadAction<{ [key: string]: any }>
    ) {
      state.state = RESOLVED;
      state.cartProducts = payload;
      if (payload) {
        const eta_days = Object.keys(payload)
          .sort((a, b) => parseInt(a) - parseInt(b))
          .map((date) => parseInt(date));

        if (eta_days.length > 0) {
          state.deliveryDateCart = eta_days.map((eta) => ({ eta_date: eta }));
          state.charge_dates = eta_days.map((eta) => {
            let eta_day = convertNumberDateToDate(eta);
            eta_day.setDate(eta_day.getDate() - 3);
            return { charge_date: convertDateToNumberDate(eta_day) };
          });
        } else {
          state.charge_dates = [];
        }
      }
    },
    setRejected(state) {
      state.state = REJECTED;
    },
    setResolved(state) {
      state.state = RESOLVED;
    }
  }
});

export const actionsCart = slice.actions;
export default slice.reducer;
