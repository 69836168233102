import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
type Props = {
  text: string;
  element?: JSX.Element;
};
export default function StandingOrderPolicyItems({ text, element }: Props) {
  return (
    <div className="d-flex ">
      <FontAwesomeIcon icon={faCheck} className="color-green-light mt-1" />
      <div className="d-block">
        <span className="ml-2">{text}</span>
        <>{element}</>
      </div>
    </div>
  );
}
