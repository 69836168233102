import React from 'react';
import { itemsMasterList, Masterlist } from '../../../../Models/StandingOrder';

export default function getPrices(
  items: itemsMasterList,
  masterList: Masterlist,
  stemsPerBunch: number
) {
  const selling_price = items?.selling_price;

  const bunches_in_box = masterList?.bunches_in_box!;

  const stemInbunch = (
    (stemsPerBunch * bunches_in_box * selling_price!) /
    bunches_in_box
  )?.toFixed(2);

  const bunches = ((bunches_in_box * selling_price!) / bunches_in_box)?.toFixed(
    2
  );

  return (
    <>
      {masterList?.uom?.uom === 'stem' ? (
        <>
          ${(stemsPerBunch * bunches_in_box * selling_price!)?.toFixed(2)}
          &nbsp;(${selling_price!.toFixed(2)}/st
          <br />${stemInbunch}
          /bunch )
        </>
      ) : (
        <>
          {!isNaN(bunches_in_box * selling_price!) &&
            !isNaN(selling_price! / stemsPerBunch) && (
              <>
                ${(bunches_in_box * selling_price!)?.toFixed(2)}
                &nbsp;($
                {(selling_price! / stemsPerBunch)?.toFixed(2)}
                /st
                <br />${bunches}
                /bunch )
              </>
            )}
        </>
      )}
    </>
  );
}
