import React, { useCallback, useEffect, useState } from 'react';
import Filter from './../../../Models/Filter';
import FilterItem from './../../../Models/FilterItem';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addObjectParamsToPath,
  getCountryName,
  getFiltersKeys,
  hrefToObject,
  sortObjByKey
} from '../../../Utils/Utils';
import MixpanelService from './../../../Services/MixpanelService';
import Select from 'react-select';
import Environment from '../../../Environment';
import { actionsFilter } from '../../../Store/Filter/Slice';
import { RootState } from '../../../Store/Reducers';

type Props = {
  growers: Filter | undefined;
  filterList: Filter[];
};
const GrowerFilterComponent: React.FC<Props> = ({ growers, filterList }) => {
  const dispatch = useDispatch();

  const { date } = useSelector((store: RootState) => store.ProductReducer);
  const { search, filters_conditions, selectedGrower } = useSelector(
    (store: RootState) => store.FilterReducer
  );
  const [growersList, setGrowersList] = useState<FilterItem[]>([]);
  const [growersByCountry, setGrowersByCountry] = useState<{
    [country: string]: FilterItem[];
  }>({});
  const history = useHistory();

  const options = useCallback(
    () =>
      growersList.map((grower: FilterItem) => ({
        value: grower.key,
        label: grower.value,
        country_key: (grower as any).country_key ?? ''
      })),
    [growersList]
  );

  const [select, setSelect] = useState<{
    value: { label: string; value: any };
    options: any[];
  }>({
    value: { label: 'Growers: all', value: '' },
    options: options()
  });

  useEffect(() => {
    if (growersList.length > 0) {
      setSelect({ value: options()[0], options: options() });
    }
  }, [growersList, options]);

  useEffect(() => {
    if (growers) {
      let list: FilterItem[] = [];

      const growersByCountryList: any = growers.values.reduce(
        (obj: any, grower) => {
          const country = grower.country || '';
          const countryName = getCountryName(
            Environment.country_flag_map[country]
          );
          if (obj[countryName]) {
            obj[countryName] = [
              ...obj[countryName],
              {
                ...grower
              }
            ];
          } else {
            obj[countryName] = [
              {
                ...grower
              }
            ];
          }
          return obj;
        },
        {}
      );

      setGrowersByCountry(growersByCountryList);

      const growerList1 = Object.keys(sortObjByKey(growersByCountryList)).map(
        (key, index) => {
          const obj = [
            {
              key: key + '_' + index,
              value: key,
              country_key: growersByCountryList[key][0]?.country ?? ''
            }
          ];
          return [...obj, ...growersByCountryList[key]];
        }
      );

      list = [{ key: '', value: 'Growers: all', selected: false }];
      if (growerList1) {
        list.push(...growerList1.flat());
      }

      if (filters_conditions.grower?.length > 0) {
        const grower = growers.values.find(
          (grower: FilterItem) => grower.key === filters_conditions.grower[0]
        );
        if (grower) {
          list.unshift(grower);
          list = [...new Set(list)];

          const growerCountry = Object.keys(
            growersByCountryList
          ).find((country) =>
            growersByCountryList[country].find(
              (growerC: { key: string }) => growerC.key === grower.key
            )
          );

          if (!selectedGrower.key) {
            dispatch(
              actionsFilter.setSelectedGrower({
                // name: grower.value,
                name: `${grower.value} ${
                  growerCountry ? '(' + growerCountry + ')' : ''
                }`,
                key: grower.key
              })
            );
          }
        }
      } else {
        if (
          filters_conditions.country &&
          filters_conditions.country.length > 0
        ) {
          list.unshift({
            key: '',
            value: getCountryName(
              Environment.country_flag_map[filters_conditions.country[0]]
            ),
            selected: false
          });
        }
      }

      setGrowersList(list);
    }
  }, [growers, filters_conditions, selectedGrower.key, dispatch]);

  const setFilter = (itemId: string) => {
    const filters = filterList.map((filterRedux: Filter) => {
      let filter = { ...filterRedux };
      if ('Growers' === filter.label) {
        filter.values = filterRedux.values.map((item: FilterItem) => {
          if (item.key === itemId) {
            MixpanelService.track(
              `filter_${filter.label.toLowerCase()}_${item.value}`
            );
            item = { ...item, selected: !item.selected };
          } else {
            if (!itemId || (item.selected && item.key !== itemId)) {
              item = { ...item, selected: false };
            }
          }
          return item;
        });
      }
      return filter;
    });

    const filtersKeys = getFiltersKeys(filters);
    const {
      orderBy,
      sortOrder,
      combo
    }: { [key: string]: any } = hrefToObject();
    const params = {
      date,
      search_val: search,
      favorites: filters_conditions.favorites,
      orderBy,
      sortOrder,
      combo,
      ...filtersKeys
    };

    history.push(addObjectParamsToPath('/search', params));
  };

  const handleChange = (e: any) => {
    if (e.country_key) {
      setSelect((cSelect) => ({
        ...cSelect,
        value: { label: e.label, value: '' }
      }));
      const growerParams = { name: '', key: '' };
      dispatch(actionsFilter.setSelectedGrower(growerParams));

      const { ...filters }: { [key: string]: any } = hrefToObject();

      const params: { [key: string]: any } = {
        date,
        search_val: search,
        country: [e.country_key]
      };

      if (filters && Object.keys(filters).length > 0) {
        for (const key in filters) {
          if (filters.hasOwnProperty(key)) {
            params[key] = filters[key];
          }
        }
      }
      history.push(addObjectParamsToPath('/search', params));
    } else {
      const grower = [e.value];
      if (e.value) {
        dispatch(actionsFilter.setFilters({ ...filters_conditions, grower }));
      }

      const growerCountry = Object.keys(growersByCountry).find((country) =>
        growersByCountry[country].find((grower) => grower.key === e.value)
      );

      dispatch(
        actionsFilter.setSelectedGrower({
          name: `${e.label} ${growerCountry ? '(' + growerCountry + ')' : ''}`,
          key: e.value
        })
      );
      setFilter(e.value);
      MixpanelService.track('filter_grower', {
        name: e.label === 'Growers: all' ? 'All' : e.label
      });
    }
  };

  return (
    <>
      {growersList.length > 0 && (
        <Select
          className="pl-3 pr-5 px-md-0"
          onChange={handleChange}
          value={select.value as any}
          options={options()}
          styles={{
            option: (styles, { isDisabled }) => ({
              ...styles,
              paddingLeft: isDisabled ? '' : '25px',
              color: isDisabled ? '#888888;' : ''
            })
          }}
        />
      )}
    </>
  );
};

export default GrowerFilterComponent;
