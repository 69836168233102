import React from 'react';
import { DeepMap, FieldError, UseFormClearErrors } from 'react-hook-form';
import { CreateStandingOrder } from '../../../../Models/StandingOrder';
import ChoosingDate from './ChoosingDate';
import { roundUp } from '../../../../Utils/Utils';
type Props = {
  data: any;
  selectedDay: string | Date;
  setSelectedDay: (day: Date) => void;
  weeklySelected: string;
  setWeeklySelected: (event: string) => void;
  chooseFrecuency: string;
  setChooseFrecuency: (event: string) => void;
  errors: DeepMap<CreateStandingOrder, FieldError>;
  clearErrors: UseFormClearErrors<CreateStandingOrder>;
};
export default function OptionBox({
  data,
  selectedDay,
  setSelectedDay,
  weeklySelected,
  setWeeklySelected,
  chooseFrecuency,
  setChooseFrecuency,
  errors,
  clearErrors
}: Props) {
  const handleOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChooseFrecuency(event.currentTarget.value);
    if (chooseFrecuency === 'Weekly') {
      setWeeklySelected('for 12 months');
    }
  };
  const totalBunches = data?.box?.total_bunches;
  const weekly_price = data?.prices?.weekly_price;
  const biWeeklyPrice = data?.prices?.bi_weekly_price;
  const pricePerBunch = data?.prices?.normal_price?.price_per_bunch;
  return (
    <div className="d-block mt-3">
      <b>Choose your buying option for your box</b>
      <div className="custom-control custom-radio d-flex align-items-lg-center">
        <input
          type="radio"
          id="weekly"
          value="Weekly"
          onChange={handleOnchange}
          checked={chooseFrecuency === 'Weekly'}
          className="custom-control-input"
        />
        <label className="custom-control-label" htmlFor="weekly">
          Weekly {'  '}
          {!data?.custom
            ? totalBunches
            : data?.box?.find((item: any) => item.custom)?.total_bunches}{' '}
          bunches:{'  '}
          {!data?.custom && (
            <>
              {' '}
              <span className="ibf-text-line-through">{`$${roundUp(
                pricePerBunch
              )}`}</span>
              <span className="ibf-color--dark-green">
                ${roundUp(weekly_price?.price_per_bunch)}
              </span>
              /bunch x
            </>
          )}
          {!data?.custom ? (
            <>
              {totalBunches} = $
              {(weekly_price?.price_per_bunch * totalBunches).toFixed(2)}
            </>
          ) : (
            <>
              {' '}
              $
              {Object.keys(data?.prices?.masterlist_prices)
                .reduce((sum, i) => {
                  let it = data?.prices?.masterlist_prices[i];
                  let item = data.box.filter((it2: any) => {
                    return it2.masterlist_key === i;
                  })[0];
                  sum = sum + it.weekly_price.price_per_bunch * item?.quantity;
                  return sum;
                }, 0)
                .toFixed(2)}
            </>
          )}
          /box
        </label>
      </div>

      <div className="custom-control custom-radio  d-flex align-items-lg-center">
        <input
          type="radio"
          id="Bi-Weekly"
          value="Bi-Weekly"
          className="custom-control-input"
          onChange={handleOnchange}
          checked={chooseFrecuency === 'Bi-Weekly'}
        />
        <label className="custom-control-label" htmlFor="Bi-Weekly">
          Bi-weekly{'  '}
          {!data?.custom
            ? totalBunches
            : data?.box?.find((item: any) => item.custom)?.total_bunches}{' '}
          bunches:{' '}
          {!data?.custom && (
            <>
              {' '}
              <span className="ibf-text-line-through">{`$${roundUp(
                pricePerBunch
              )}`}</span>
              <span className="ibf-color--dark-green">
                ${roundUp(biWeeklyPrice?.price_per_bunch)}
              </span>
              /bunch x
            </>
          )}
          {!data?.custom ? (
            <>
              {totalBunches} = $
              {(biWeeklyPrice?.price_per_bunch * totalBunches).toFixed(2)}
            </>
          ) : (
            <>
              $
              {Object.keys(data?.prices?.masterlist_prices)
                .reduce((sum, i) => {
                  let it = data?.prices?.masterlist_prices[i];
                  let item = data.box.filter((it2: any) => {
                    return it2.masterlist_key === i;
                  })[0];
                  sum =
                    sum + it.bi_weekly_price.price_per_bunch * item?.quantity;

                  return sum;
                }, 0)
                .toFixed(2)}
            </>
          )}
          (12 months only)/box
        </label>
      </div>

      <ChoosingDate
        chooseFrecuency={chooseFrecuency !== 'Bi-Weekly'}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        weeklySelected={weeklySelected}
        setWeeklySelected={setWeeklySelected}
        errors={errors}
        clearErrors={clearErrors}
      />
    </div>
  );
}
