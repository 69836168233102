import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import NavBar from './Components/NavBarComponent/NavBar';
import { Auth } from 'sbx-react-auth-hoc';
import AuthResponse from './Models/AuthResponse';
import { plainToClass } from 'class-transformer';
import { useDispatch, useSelector } from 'react-redux';
import NavBarCalculatingComponent from './Components/CalculatingComponent/NavBarCalculatingComponent';
import LoginModal from './Components/Shared/Modal/LoginModal/LoginModal';
import {
  CONFIRM_PROFILE_MODAL,
  CONFIRM_ZIPCODE_MODAL,
  CONFIRMATION_CHECKOUT_MODAL,
  MESSAGE_COOKIE_MODAL,
  SIGN_UP_MODAL
} from './Store/Modal/Types';
import {
  checkCookie,
  getTimeEST,
  isMailAvailable,
  logout
} from './Services/AuthService';
import Environment from './Environment';
import {
  convertNumberDateDDDMMMYYYY,
  getCountryName,
  getTodayESTNumber,
  hrefContainsProperty,
  hrefToObject,
  setTrackingAuthorization,
  showConfirmProfileModal,
  signIn,
  utmOnErrorRedirect,
  utmOnSuccessRedirect,
  utmReferralEvent
} from './Utils/Utils';
import { useTracking } from './Hooks/useTracking';
import HomeComponent from './Components/HomeComponent/HomeComponent';
import LoginAsCustomer from './Components/LoginAsCustomer/LoginAsCustomer';
import ModalWrapperContainer from './Components/Shared/Modal/ModalWrapperContainer';
import CalculatingComponent from './Components/CalculatingComponent/CalculatingComponent';
import { actionsProduct } from './Store/Product/Slice';
import { actionsAuth } from './Store/Auth/Slice';
import { actionsHomeLoading } from './Store/HomeLoading/Slice';
import { actionsModal } from './Store/Modal/Slice';
import { actionsCalendar } from './Store/Calendar/Slice';
import { actionsCustomer } from './Store/Customer/Slice';
import { RootState } from './Store/Reducers';
import { Grower } from './Models/Grower';
import ProductWrapperComponent from './Components/ProductWrapperComponent/ProductWrapperComponent';
import ProductDetailComponent from './Components/ProductWrapperComponent/ProductComponent/ProductDetailComponent/ProductDetailComponent';
import ReferralComponent from './Components/ReferralComponent/ReferralComponent';
import BackToShoppingNavBar from './Components/ReferralComponent/BackToShoppingNavBar';
import { actionsDashboard } from './Store/Dashboard/Slice';
import LoadingSpinner from './Components/Shared/LoadingSpinner';
import EmergencyMessage from './Components/Shared/Modal/EmergencyMessage/EmergencyMessage';
import OrdersByEtaComponent from './Components/OrdersByEtaComponent/OrdersByEtaComponent';
import OrdersByPurchaseComponent from './Components/OrdersByPurchaseComponent/OrdersByPurchaseComponent';
import OrderDetailComponent from './Components/OrderDetailComponent/OrderDetailComponent';
import { useHistory, useLocation } from 'react-router';
import { Alert } from 'reactstrap';
import { actionsLoading } from './Store/Loading/Slice';
import {
  authReducer,
  checkoutReducer,
  loadingReducer
} from './Store/Selectors';
import { eventActions } from './Store/Event/Slice';
import StatementsComponent from './Components/StatementsComponent/StatementsComponent';
import { SignInGuestParams } from './Models/Auth';
import initHelpHero from 'helphero';
import PaymentOptionsComponent from './Components/PaymentOptionsComponent/PaymentOptionsComponent';
import UspMobileAndDesktopView from './Components/UpsComponent/UspMobileAndDesktopView';
import {
  getListDeleteProducts,
  updateCheckDeleteProducts
} from './Services/ProductService';
import { DeleteProduct } from './Models/Product';
import ImageComponent from './Components/Shared/ImageComponent/ImageComponent';
import StandingOrderRequest from './Components/StandingOrdersView/StandingOrderRequest';
import TrackingComponent from './Components/Shared/TrackingComponent';
import { deleteKometOrders, listCart } from './Services/CheckoutService';
import { actionsCheckout } from './Store/Checkout/Slice';
import moment from 'moment';
import { Cartbox } from './Models/Cartbox';

// import StandingOrderRequest from './Components/StandingOrdersView/StandingOrderRequest';

export const hlp = initHelpHero('2v1MImlY5q');

export const App: React.FC = () => {
  const dispatch = useDispatch();

  const { time } = useSelector((state: RootState) => state.CalendarReducer);
  const { date } = useSelector((store: RootState) => store.ProductReducer);
  const { config, user } = useSelector(authReducer);
  const { yellowAlert } = useSelector(loadingReducer);
  const [isValid, setIsValid] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const history = useHistory();
  const location = useLocation();
  let serviceCartRed = useRef<ReturnType<typeof setInterval>>();
  useTracking(Environment.tagManagerTrackingId);
  const { products } = useSelector(checkoutReducer);

  useEffect(() => {
    let res = checkCookie();
    if (res === 'Not Enabled') {
      dispatch(actionsModal.setOpenModal({ modal: MESSAGE_COOKIE_MODAL }));
    }
  }, [dispatch]);

  useEffect(() => {
    const { date: d }: { [key: string]: any } = hrefToObject();
    if (!date && d) {
      dispatch(actionsProduct.setProductDate({ date: d }));
    }
  }, [dispatch, date]);

  useEffect(() => {
    /**
     * It gets the customer cart from the backend.
     */
    const getCustomerCart = async () => {
      await deleteKometOrders();

      const response = await listCart(user.metadata.customer);
      if (response?.success && response.results && response.results.carts) {
        dispatch(
          actionsCheckout.checkoutProducts({
            products: response.results.carts
          })
        );
      }
    };

    /* Checking if the products array has any products and if the products have a grower and if the product was created 30
    minutes ago. */
    serviceCartRed.current = setInterval(() => {
      if (
        products.length > 0 &&
        products.some(
          (product) =>
            product.grower.komet_intg &&
            moment(product._META.created_time)
              .add('30', 'm')
              .diff(moment().tz('America/New_York')) <= 0
        )
      ) {
        getCustomerCart();
      } else {
        if (
          products.some(
            (product) =>
              product.komet_delete_date &&
              moment().diff(
                moment((product as Cartbox).komet_delete_date),
                'm'
              ) >= 5
          )
        ) {
          getCustomerCart();
        }
      }
    }, 30 * 1000);

    return () => {
      if (serviceCartRed.current) {
        clearInterval(serviceCartRed.current);
      }
    };
  }, [products, dispatch, user.metadata.customer]);

  React.useEffect(() => {
    const { user_id, code } = hrefToObject();
    if (user_id && code && location.pathname === '/home') {
      logout('/#/home' + location.search);
    }
  }, [location.pathname, location.search]);

  const authSuccess = async (res: any) => {
    setIsValid(false);
    setIsLogged(true);
    if (typeof res !== 'boolean') {
      const authData = plainToClass(AuthResponse, res);

      if (authData?.config?.trends?.items?.length > 0) {
        dispatch(actionsDashboard.setTrendingList(authData?.config?.trends));
      }

      if (hrefContainsProperty('utm_source')) {
        const hrefData: { [key: string]: any } = hrefToObject();
        if (
          (hrefData.utm_source === 'email_verification' ||
            hrefData.utm_source === 'email_verification_signed') &&
          hrefData.email !== authData.user.email
        ) {
          logout('/' + window.location.hash);
          window.location.reload();
          return;
        }
      }

      if (hrefContainsProperty('referral_code')) {
        const { email }: { [key: string]: any } = hrefToObject();
        if (email !== authData.user.email) {
          logout('/' + window.location.hash);
          window.location.reload();
          return;
        } else {
          utmReferralEvent('ibf_referral_accepted');
        }
      }
      if (authData.user.role !== 'FLORIST') {
        window.location.href = `${Environment.version1Url}?token=${authData.token}`;
        return;
      }
      dispatch(actionsAuth.setAuthData(authData));
      setTrackingAuthorization(authData);

      if (
        showConfirmProfileModal(authData.user) &&
        !location.pathname.includes('/login_as')
      ) {
        dispatch(actionsModal.setOpenModal({ modal: CONFIRM_PROFILE_MODAL }));
      }
      dispatch(
        eventActions.getEventList({
          customer_key: authData.user.metadata.customer
        })
      );

      dispatch(
        actionsProduct.setFavoriteProducts(authData.user.metadata.favorites)
      );
      let update = false;
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key) {
          const value = localStorage[key];
          if (value === 'true') {
            if (!authData.user.metadata.preferences[key]) {
              authData.user.metadata.preferences[key] = true;
              update = true;
            }
          }
        }
      }
      if (update) {
        dispatch(
          actionsCustomer.updateUserPreferences({
            customer: authData.user.metadata.customer,
            preferences: authData.user.metadata.preferences
          })
        );
      }

      utmOnSuccessRedirect({
        customer: authData.user.metadata.customer,
        history
      });
      const growerList = authData.dependencies.grower.reduce(
        (obj: any, grower: Grower) => {
          if (!grower.active) {
            return obj;
          }
          const country = getCountryName(
            Environment.country_flag_map[grower.country]
          );
          if (obj[country]) {
            obj[country] = [...obj[country], grower];
          } else {
            obj[country] = [grower];
          }
          return obj;
        },
        {}
      );
      if (authData?.dependencies?.category?.length > 0) {
        dispatch(
          actionsDashboard.setHomeCategoryList(
            authData.dependencies.category
              .sort((a: any, b: any) => a.index_order - b.index_order)
              .filter(
                (category: any) =>
                  !authData.config.excludeCategoriesKey.includes(category._KEY)
              )
          )
        );
      }

      dispatch(actionsDashboard.setGrowerList(growerList));
      dispatch(
        actionsDashboard.setColorList(
          authData.dependencies.color.sort(
            (a: any, b: any) => a.index_order - b.index_order
          )
        )
      );

      dispatch(
        actionsHomeLoading.initHomeLoading({
          ...authData.user.metadata,
          blockDays: authData.config.blockDays
        })
      );

      dispatch(
        actionsCalendar.initCalendar({
          holidays: authData.dependencies.holidays,
          blockDays: authData.config.blockDays,
          time: authData.time,

          disabled_calendar_days: authData?.config?.disabled_calendar_days
        })
      );

      dispatch(
        actionsLoading.toggleYellowAlert(
          authData.config?.yellowAlert?.visible ?? false
        )
      );

      const resDeleteProducts = await getListDeleteProducts(
        authData.user.metadata.customer
      );
      if (
        resDeleteProducts?.success &&
        resDeleteProducts.response &&
        resDeleteProducts.response.length > 0 &&
        authData.user.metadata.guest &&
        !location.pathname.includes('/login_as')
      ) {
        const body = (
          <div className="d-flex flex-column ">
            <span>
              The following products were deleted because your 3 hour guest user
              window has expired. Checkout within 3 hours OR if you want more
              time from now on, just{' '}
              <span
                className="underline pointer"
                onClick={() =>
                  dispatch(actionsModal.setOpenModal({ modal: SIGN_UP_MODAL }))
                }>
                sign up with us (it's free!) &raquo;
              </span>
            </span>
            {resDeleteProducts.response.map((product: DeleteProduct) => (
              <div className="d-flex align-items-center border-bottom py-2">
                <div className="mr-2">
                  <ImageComponent
                    imageUrl={product.picture}
                    width={'80'}
                    className="border"
                    onErrorUrl={''}
                  />
                </div>

                <div className="d-flex flex-column">
                  <span>{product.product_name}</span>
                  <span>{product.quantity_info}</span>
                  <span>{convertNumberDateDDDMMMYYYY(product.eta_date)}</span>
                </div>
              </div>
            ))}
          </div>
        );

        const confirmAction = async () => {
          await updateCheckDeleteProducts(
            resDeleteProducts.response.map((product: DeleteProduct) => ({
              _KEY: product._KEY,
              user_check: true
            }))
          );
        };

        dispatch(
          actionsModal.setOpenModal({
            modal: CONFIRMATION_CHECKOUT_MODAL,
            data: {
              body: body,
              confirmButtonText: 'OK',
              confirmAction,
              cancelAction: confirmAction
            }
          })
        );
      }

      if (
        authData?.user?.metadata?.customer_main_zipcode?.length < 5 ||
        authData?.user?.metadata.customer_zipcode.length < 5
      ) {
        dispatch(
          actionsModal.setOpenModal({
            modal: CONFIRM_ZIPCODE_MODAL
          })
        );
      }

      if (authData.user.metadata.preferences.showAlert) {
        const preferences = { ...authData.user.metadata.preferences };
        delete preferences.showAlert;
        dispatch(
          actionsCustomer.updateUserPreferences({
            customer: authData.user.metadata.customer,
            preferences
          })
        );
      }
      //Modal

      // if (
      //   (authData?.user?.metadata?.from_manager &&
      //     authData.user.metadata.main_user_first_name === 'Guest' &&
      //     authData.user.metadata.main_user_last_name === 'User' &&
      //     !authData.user.metadata.guest) ||
      //   (authData.user.name === 'Guest User' && !authData.user.metadata.guest)
      // ) {
      //   dispatch(actionsModal.setOpenModal({ modal: GUEST_USER_NAME_CHANGE }));
      // }
    }
  };

  useEffect(() => {
    if (time && time < 800) {
      const getCurrentTime = async () => {
        const timeNow = await getTimeEST();

        if (timeNow.time > 800) {
          dispatch(actionsCalendar.setNextDay(timeNow));
          clearInterval(timer);
        }
      };

      let timer = setInterval(() => {
        getCurrentTime();
      }, 10000);
    }
  }, [time, dispatch]);

  const closeMessage = () => {
    dispatch(actionsLoading.toggleYellowAlert(false));
  };

  const getDefaultRoute = () => {
    // const promoParams = window.location.href.split('?');
    // if (promoParams[1]?.includes('search_val')) {
    //   return "/search"
    // }

    return '/home';
  };

  return (
    <>
      <Auth
        loadingComponent={
          <>
            <NavBar />
            <Switch>
              <Redirect exact from="/" to={getDefaultRoute()} />
              <Route exact path="/home">
                <HomeComponent />
              </Route>
              <Route path="*">
                <LoadingSpinner message={'LOADING'} height={'100vh'} />
              </Route>
            </Switch>
          </>
        }
        logged={isLogged}
        onError={async () => {
          setIsLogged(false);
          dispatch(actionsHomeLoading.initHomeLoading(null));
          if (hrefContainsProperty('utm_source')) {
            const hrefData: { [key: string]: any } = hrefToObject();
            if (hrefData.utm_source === 'email_verification') {
              delete hrefData.utm_source;
              Object.keys(hrefData).forEach((key) => {
                hrefData[key] = decodeURI(hrefData[key]);
              });

              await signIn({
                params: hrefData as SignInGuestParams,
                firstTime: true
              });
            }

            if (hrefData.utm_source === 'email_verification_signed') {
              await isMailAvailable(hrefData.email, false, true);
            }
          }
          if (hrefContainsProperty('guest_email')) {
            const { guest_email }: { [key: string]: any } = hrefToObject();
            await isMailAvailable(guest_email.replace(' ', '+'));
          }

          utmOnErrorRedirect(history);
          setIsValid(true);
        }}
        onSuccess={authSuccess}>
        <>
          <Switch>
            <Route exact path="/calculating">
              <NavBarCalculatingComponent />
            </Route>
            <Route exact path="/referral">
              <BackToShoppingNavBar />
            </Route>
            <Route exact path="/login_as">
              <NavBarCalculatingComponent />
            </Route>
            <Route path="*">
              <NavBar />
            </Route>
          </Switch>
          <Switch>
            <Redirect exact from="/" to={getDefaultRoute()} />
            <Route exact path="/home">
              <HomeComponent />
            </Route>

            <Route exact path="/search">
              <>
                <div className="pt-3 bg-list">
                  <UspMobileAndDesktopView />
                </div>
                {getTodayESTNumber() > 20230210 && (
                  <Alert
                    style={{ backgroundColor: '#ffffce', borderColor: 'black' }}
                    isOpen={yellowAlert}
                    className="mx-4 my-2">
                    <span className="color-black">
                      {config?.yellowAlert?.message}{' '}
                      <u
                        className="pointer font-weight-bold"
                        onClick={closeMessage}>
                        Ok, close
                      </u>
                    </span>
                  </Alert>
                )}
                <ProductWrapperComponent />
              </>
            </Route>

            <Route exact path="/product">
              <>
                <UspMobileAndDesktopView />
                <ProductDetailComponent />
              </>
            </Route>

            <Route exact path="/calculating">
              <CalculatingComponent />
            </Route>
            <Route exact path="/referral">
              <ReferralComponent />
            </Route>
            <Route exact path="/login_as">
              <LoginAsCustomer />
            </Route>
            <Route path="/ordersbyarrival" component={OrdersByEtaComponent} />
            <Route path="/statements" component={StatementsComponent} />
            <Route
              path="/ordersbypurchase"
              component={OrdersByPurchaseComponent}
            />
            <Route path="/paymentoptions" component={PaymentOptionsComponent} />
            <Route
              path="/order_detail/:order_key"
              component={OrderDetailComponent}
            />
            <Route path="/myStandingOrders" component={StandingOrderRequest} />
          </Switch>
          <EmergencyMessage />
        </>
      </Auth>
      {!isLogged && isValid && (
        <Switch>
          <Route
            exact
            path={
              history.location.pathname === '/search' ? '/search' : '/home'
            }>
            <NavBar />
            <HomeComponent />
            <LoginModal />
          </Route>
          <Route exact path="/login_as">
            <NavBarCalculatingComponent />
            <LoginAsCustomer />
          </Route>
        </Switch>
      )}
      <ModalWrapperContainer />
    </>
  );
};

const AppWithHash = () => (
  <HashRouter>
    <TrackingComponent />
    <App />
  </HashRouter>
);

export default Sentry.withErrorBoundary(AppWithHash, { fallback: 'An error' });
