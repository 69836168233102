import React from 'react';
//import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useSelector } from 'react-redux';
//import CalendarInputComponent from '../../../FiltersTableComponent/CalendarInputComponent';
import { calendarReducer } from '../../../../Store/Selectors';
import { DeepMap, FieldError, UseFormClearErrors } from 'react-hook-form';
import { CreateStandingOrder } from '../../../../Models/StandingOrder';
import {
  arrayMothsList,
  calculateDates,
  convertNumberDateToDate
} from '../../../../Utils/Utils';
import moment from 'moment';
type Props = {
  chooseFrecuency: boolean;
  selectedDay: string | Date;
  setSelectedDay: (day: Date) => void;
  weeklySelected: string;
  setWeeklySelected: (event: string) => void;
  errors: DeepMap<CreateStandingOrder, FieldError>;
  clearErrors: UseFormClearErrors<CreateStandingOrder>;
};
///

const validationInputMessage = (
  <span className="ibf-font-12 color-red">Please fill in this info.</span>
);
export default function ChoosingDate({
  chooseFrecuency,
  selectedDay,
  setSelectedDay,
  weeklySelected,
  setWeeklySelected,
  errors,
  clearErrors
}: Props) {
  const { disabledDays } = useSelector(calendarReducer);
  const handleDayChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let converDate = moment(event.currentTarget.value, 'YYYY-MM-DD').format(
      'YYYYMMDD'
    );
    let day = convertNumberDateToDate(converDate);

    if (day instanceof Date) {
      setSelectedDay(day);
    }
  };
  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setWeeklySelected(event.currentTarget.value);
  };
  return (
    <div className="mt-4 d-flex flex-column flex-md-row align-items-lg-center">
      <b>Choose starting date:</b>
      <div className="d-flex align-items-center ml-lg-2">
        <div className="d-flex flex-column">
          <div>
            <select
              className="form-control input-sm"
              onChange={(e) => {
                clearErrors('start_date');
                handleDayChange(e);
              }}>
              <>
                {calculateDates(disabledDays).map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </>
            </select>
          </div>

          {errors?.start_date?.type === 'required' && validationInputMessage}
        </div>
        <div className="ml-2">
          <select
            className="form-control input-sm"
            onChange={handleOnChange}
            value={weeklySelected}>
            {chooseFrecuency && (
              <>
                {arrayMothsList.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>
      </div>
    </div>
  );
}
