import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionsCard } from '../Card/Slice';
import { actionsProduct } from '../Product/Slice';
import { actionsCart } from '../Cart/Slice';
import { actionsCustomBox } from '../CustomBox/Slice';
import { actionsAuth } from '../Auth/Slice';
import { actionsHomeLoading } from '../HomeLoading/Slice';
import { actionsCheckout } from '../Checkout/Slice';
import { State } from '../../Models/State';

const initialState: any = {
  initialLoading: true,
  authLoading: true,
  filterLoading: false,
  cartLoading: true,
  addingCartLoading: false,
  addingCard: false,
  moreProductsLoading: false,
  inventoryLoading: false,
  isAList: false,
  firstTip: true,
  yellowAlert: true,
  isCustomizable: false,
  sortMenuOptions: false,
  isFromProductDetail: false,
  itemKeyDeleteCartbox: [],
  productKey: []
};

const slice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setGeneralLoading(state) {
      state.initialLoading = true;
    },
    setHandlerProperty(
      state,
      {
        payload
      }: PayloadAction<{ property: keyof typeof initialState; state: boolean }>
    ) {
      let property = payload.property;

      if (typeof state[property] === 'boolean') {
        state[property] = payload.state;
      }
    },
    setFilterLoading(state, { payload = true }: PayloadAction<boolean>) {
      state.initialLoading = false;
      state.filterLoading = payload;
    },
    setIsAList(state) {
      state.isAList = !state.isAList;
    },
    toggleForTip(state) {
      state.firstTip = false;
    },
    openSortOptions(state) {
      state.sortMenuOptions = !state.sortMenuOptions;
    },
    toggleYellowAlert(state, { payload }: PayloadAction<boolean>) {
      state.yellowAlert = payload;
    },
    loadingSL(state) {
      state.cartLoading = true;
    },
    addingCard(state, { payload }: PayloadAction<boolean>) {
      state.addingCard = payload;
    },
    setAddingProduct(state, { payload }: PayloadAction<boolean>) {
      state.addingCartLoading = payload;
    },
    setMoreProductsLoading(state) {
      state.moreProductsLoading = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(actionsAuth.setAuthData, (state, { payload }) => {
      state.authLoading = false;
      return state;
    });
    builder.addCase(
      actionsHomeLoading.initHomeLoading,
      (state, { payload }) => {
        state.authLoading = false;
        return state;
      }
    );
    builder.addCase(actionsCard.addCard, (state, { payload }) => {
      state.addingCard = false;
      return state;
    });
    builder.addCase(actionsProduct.setProducts, (state, { payload }) => {
      state.initialLoading = false;
      state.filterLoading = false;
      state.addingCard = false;
      return state;
    });
    builder.addCase(
      actionsProduct.getProductReplacements,
      (state, { payload }) => {
        state.filterLoading = true;
        return state;
      }
    );
    builder.addCase(actionsCustomBox.addToCart, (state, { payload }) => {
      state.cartLoading = true;
      state.addingCartLoading = true;
      return state;
    });
    builder.addCase(
      actionsCheckout.removeFromCustomBox,
      (state, { payload }) => {
        state.cartLoading = true;
        if (payload.productKey) {
          state.productKey.push(payload.productKey);
        }
        if (state === State.REJECTED) {
          state.productKey = [];
        }
        return state;
      }
    );
    builder.addCase(actionsCart.listCart, (state, { payload }) => {
      state.cartLoading = true;
      if (!payload.deleteCartbox) {
        state.addingCartLoading = true;
      }

      return state;
    });
    builder.addCase(actionsCheckout.removeFromBox, (state, { payload }) => {
      if (payload.cartboxes.length) {
        state.itemKeyDeleteCartbox.push(payload.cartboxes);
      }
      if (state === State.REJECTED) {
        state.itemKeyDeleteCartbox = [];
      }
      state.cartLoading = true;
      return state;
    });
    builder.addCase(actionsCheckout.setRejected, (state, { payload }) => {
      state.cartLoading = false;
      return state;
    });
    builder.addCase(actionsCart.setProductsToListCart, (state, { payload }) => {
      state.cartLoading = false;

      // state.addingCartLoading = false;
      return state;
    });
    builder.addCase(actionsCart.addProduct, (state, { payload }) => {
      state.cartLoading = true;
      state.addingCartLoading = true;
      return state;
    });
    builder.addCase(actionsCart.setRejected, (state, { payload }) => {
      state.cartLoading = false;
      // state.addingCartLoading = false;
      return state;
    });
    builder.addCase(actionsCustomBox.closeBox, (state, { payload }) => {
      state.cartLoading = true;
      state.addingCartLoading = true;
      return state;
    });
    builder.addCase(actionsCustomBox.setRejected, (state, { payload }) => {
      state.cartLoading = false;
      state.addingCartLoading = false;
      return state;
    });
    builder.addCase(actionsHomeLoading.setRejected, (state, { payload }) => {
      state.cartLoading = false;
      state.addingCartLoading = false;
      return state;
    });
    builder.addCase(actionsHomeLoading.setResolved, (state, { payload }) => {
      state.cartLoading = false;
      // state.addingCartLoading = false;
      return state;
    });

    builder.addCase(
      actionsCheckout.setCheckoutProducts,
      (state, { payload }) => {
        state.cartLoading = false;

        // state.addingCartLoading = false;
        if (state === State.REJECTED) {
          state.itemKeyDeleteCartbox = [];
        }
        return state;
      }
    );
    builder.addCase(actionsCustomBox.searchInventory, (state, { payload }) => {
      state.inventoryLoading = true;
      return state;
    });

    builder.addCase(actionsCustomBox.setSearchResults, (state, { payload }) => {
      state.inventoryLoading = false;
      state.moreProductsLoading = false;
      state.cartLoading = false;
      // state.addingCartLoading = false;

      return state;
    });
    builder.addCase(actionsCustomBox.setMoreProducts, (state, { payload }) => {
      state.inventoryLoading = false;
      state.moreProductsLoading = false;
      state.cartLoading = false;
      // state.addingCartLoading = false;

      return state;
    });
    // builder.addCase(
    //   actionsCustomBox.setProductsStillFitInBox,
    //   (state, { payload }) => {
    //     state.inventoryLoading = payload ? payload : false;
    //     return state;
    //   }
    // );
    builder.addCase(actionsCustomBox.addToBox, (state, { payload }) => {
      state.inventoryLoading = true;
      state.cartLoading = true;
      state.addingCartLoading = true;

      return state;
    });
    builder.addCase(actionsCustomBox.openBox, (state, { payload }) => {
      state.inventoryLoading = true;
      return state;
    });
    builder.addCase(actionsProduct.setProductDetail, (state, { payload }) => {
      state.isFromProductDetail = !!payload.product.key;

      return state;
    });
  }
});

export const actionsLoading = slice.actions;
export default slice.reducer;
