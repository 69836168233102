import React, { Fragment, useCallback, useEffect, useState } from 'react';
import co_flag from '../../Assets/Images/CO_flag.png';
import ec_flag from '../../Assets/Images/EC_flag.png';
import nl_flag from '../../Assets/Images/NL_flag.png';
import th_flag from '../../Assets/Images/TH_flag.png';
import us_flag from '../../Assets/Images/US_flag.png';
import * as OrderService from '../../Services/OrderService';
import ModalComponent from '../Shared/Modal/ModalComponent';
import CustomBoxDetailItemComponent from './CustomBoxDetailItemComponent';
import { convertNumberDateToFullDate, roundUp } from '../../Utils/Utils';
import Environment from '../../Environment';
import { Cartbox } from '../../Models/Cartbox';
import { Grower } from '../../Models/Grower';
import { Variety } from '../../Models/Variety';
import ProductGroup from '../../Models/ProductGroup';
import dhlLogo from '../../Assets/Images/dhl_logo_mini.png';

const BoxOrderDetailComponent = (props: {
  data: Cartbox & Grower & Variety & ProductGroup;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fedexStatus, setFedexStatus] = useState('');
  const [trackingNumber, setTrackingNumber] = useState(0);

  const errorFedexTracking =
    'Sorry, we couldn’t retrieve the status of your shipment right now. ' +
    'Please contact your Account Manager for more information.';

  useEffect(() => {
    OrderService.getFedexTracking(props.data._KEY)
      .then((res: any) => {
        if (res?.data?.status) {
          setFedexStatus(res.data.status);
          setTrackingNumber(res.data.tracking_number);
        } else {
          setFedexStatus(errorFedexTracking);
        }
      })
      .catch((err) => {
        console.error('Fedex Tracking ERRR', err);
        setFedexStatus(errorFedexTracking);
      });
    return () => {
      setFedexStatus('');
    };
  }, [errorFedexTracking, props.data._KEY]);

  const getCountryFlag = useCallback((country_iso: string) => {
    switch (country_iso) {
      case 'CO':
        return co_flag;
      case 'EC':
        return ec_flag;
      case 'NL':
        return nl_flag;
      case 'TH':
        return th_flag;
      case 'US':
        return us_flag;
      default:
        return us_flag;
    }
  }, []);

  const {
    consecutive,
    common_name,
    variety_name,
    length,
    grower_name,
    items,
    charge_date,
    extra_freight_cost,
    grower_country_iso
  } = props.data;
  let { quantity, price, picture } = props.data.items[0];
  let total_bunches;
  if (props.data.custom) {
    total_bunches = props.data.current_items;
  } else {
    total_bunches = quantity;
  }

  let details;

  if (common_name === 'Custom Box') {
    details = (
      <div className="text-lg-right mb-2">
        <span
          className="text-success mr-2"
          onClick={() => setIsModalOpen(true)}>
          <i className="fa fa-fw fa-info-circle" />
          <u>Details</u>
        </span>
      </div>
    );

    picture = Environment.customBoxImage;
  }

  const getLinkTracking = () => {
    let imgSrc = '';
    let trackingLink = '';

    if (props.data?.tracking_code) {
      if ((props.data.tracking_code as string).includes('dhl')) {
        imgSrc = dhlLogo;
        trackingLink = props.data.tracking_code as string;
      } else {
        imgSrc = 'https://test.ibuyflowers.com/assets/images/fedex.svg';
        if (trackingNumber) {
          trackingLink = `https://www.fedex.com/apps/fedextrack/?action=track&tracknumbers=${trackingNumber}`;
        }
      }
    }

    if (imgSrc && trackingLink) {
      return (
        <div className="ibf-order-detail-delivery-status">
          <div className="d-flex align-items-center">
            <span className="mr-2">Delivery Status By </span>
            <img className="fedex-logo" src={imgSrc} alt="" />{' '}
            <b>
              {fedexStatus && !trackingLink.includes('dhl') ? (
                fedexStatus
              ) : (
                <i className={'fa fa-spinner fa-spin'}> </i>
              )}
            </b>
          </div>
          {trackingLink && (
            <small>
              {' '}
              For more information about your shipment,{' '}
              <a target="_blank" rel="noopener noreferrer" href={trackingLink}>
                click here.
              </a>
            </small>
          )}
        </div>
      );
    }

    return null;
    //   console.log("props", props)
    //   console.log("config", config)
    //   // if ((!props.data || !props.data.carrier ||
    //   //   config?.carrier_available.some((carrier) => carrier && props.data && props.data.carrier === carrier._KEY))){
    //   //
    //   // }
  };

  return (
    <Fragment key={props.data._KEY}>
      <div className="ibf-order-detail-box shadow-sm rounded px-1 pt-1">
        <img src={picture} className="ibf-order-detail-image" alt="" />

        <div className="ibf-order-detail-description">
          <div className="ibf-order-detail-information">
            <ul className="ibf-order-detail-items">
              <b className="ibf-order-detail-title">
                #{consecutive} - {common_name} {variety_name} {length}cm
              </b>

              <li>
                <small>
                  {grower_name}
                  <img
                    className="country-flag"
                    src={getCountryFlag(grower_country_iso as string)}
                    alt=""
                  />
                  {grower_country_iso}
                </small>
              </li>
              {extra_freight_cost === 0 ? null : (
                <li>
                  <small>Freight costs: ${extra_freight_cost}</small>
                </li>
              )}
              <li>
                <small>Price per bunch: ${price}</small>
              </li>
              <li>
                <small>
                  <b>
                    *CC Reservation:{' '}
                    {convertNumberDateToFullDate(
                      (charge_date as any) as string
                    )}
                  </b>
                </small>
              </li>
            </ul>

            <div className="col-lg-6 col-xl-5 d-flex flex-column justify-content-between">
              <div className="row">
                <div className="ibf-order-detail-items">
                  <b>Bunches</b>
                  <p>{total_bunches}</p>
                </div>
                <div className="ibf-order-detail-items">
                  <b>Box Price</b>
                  <br />
                  {props.data.off > 0 && (
                    <span className="text-danger">
                      <s>${roundUp(props.data.price)}</s>
                    </span>
                  )}
                  <br />${roundUp(props.data.price * (1 - props.data.off))}
                </div>
              </div>

              {details}
            </div>
          </div>

          {getLinkTracking()}
        </div>
      </div>

      {isModalOpen && (
        <ModalComponent
          title={'Custom Box Details\n'}
          onClose={() => setIsModalOpen(false)}
          size="lg">
          <ul className="list-unstyled mb-0 custom-box-list">
            {items.map((item: any) => {
              return <CustomBoxDetailItemComponent data={item} />;
            })}
          </ul>
        </ModalComponent>
      )}
    </Fragment>
  );
};

export default BoxOrderDetailComponent;
