import React from 'react';

type Props = {
  submit: () => void;
  loadingButton: boolean;
  buttonName: string | JSX.Element;
  greenButton?: boolean;
  disabledButton?: boolean;
  isProductDetail: boolean;
};

const CardButtonForm: React.FC<Props> = ({
  submit,
  greenButton,
  loadingButton,
  buttonName,
  disabledButton,
  isProductDetail
}) => {
  // order-4  order-lg-3 my-2 p-0 d-flex
  return (
    <div
      className={`order-4 order-lg-3 my-2  d-flex align-items-end mt-auto pt-2 ${
        isProductDetail ? ' px-2 ml-1' : 'p-0  px-1'
      }`}>
      <button
        onClick={submit}
        style={{
          borderRadius: '4px',
          backgroundColor: greenButton ? 'btn-primary' : ''
        }}
        disabled={(loadingButton || disabledButton) && true}
        className={`btn btn-primary btn-sm justify-content-center btn-block`}>
        {loadingButton ? (
          <>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true">
              {' '}
            </span>
            <span className="sr-only">Loading...</span>
          </>
        ) : (
          <>
            <span>{buttonName}</span>
          </>
        )}
      </button>
    </div>
  );
};

export default CardButtonForm;
