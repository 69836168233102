import { formatedString } from './../../Utils/Utils';
import { customerState } from './Types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../Models/State';
import { actionsAuth } from '../Auth/Slice';
import { CustomerModel, CustomerAddress } from '../../Models/CustomerModel';
import { compareJSONObjects } from '../../Utils/Utils';

const { IDLE, RESOLVED, PENDING, REJECTED } = State;

const initialState: customerState = {
  addressList: [],
  selectedAddress: {},
  state: IDLE,
  showMessage: false,
  visibleDropdown: false
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    addCustomerAddress(
      state: customerState,
      {
        payload
      }: PayloadAction<{
        customer: string;
        slAddress: object;
        closedDays: Array<string> | string;
        save: boolean;
      }>
    ) {
      state.state = PENDING;
    },
    setCustomerAddresses(
      state: customerState,
      { payload }: PayloadAction<any[]>
    ) {
      state.state = RESOLVED;
      state.addressList = payload;
    },
    getCurrentAddress(
      state,
      {
        payload
      }: PayloadAction<{
        customer_closed_days: any;
        customer: string;
        customer_state: string;
        customer_street: string;
        customer_city: string;
        customer_zipcode: string;
        closed_days: string;
      }>
    ) {
      state.state = PENDING;
    },
    setSelectedAddress(
      state: customerState,
      {
        payload
      }: PayloadAction<{
        _KEY?: string;
        address?: string;
        city?: string;
        zipcode?: string;
        state?: object;
        closedDays?: string | Array<string>;
      }>
    ) {
      state.state = RESOLVED;
      state.selectedAddress = payload;
    },
    updateUserPreferences(
      state: customerState,
      {
        payload
      }: PayloadAction<{
        customer: string;
        preferences: { [key: string]: boolean };
      }>
    ) {
      state.state = PENDING;
    },
    updateCustomer(
      state,
      {
        payload
      }: PayloadAction<{
        customer: CustomerModel;
        token: string;
        reload?: boolean;
      }>
    ) {
      state.state = PENDING;
    },
    updateCustomerAndUserInfo(
      state: customerState,
      {
        payload
      }: PayloadAction<{
        customer: CustomerModel;
        user: {
          first_name: string;
          last_name: string;
          _KEY: string;
          hubspot_pending_update: boolean;
        };
        token: string;
      }>
    ) {
      state.state = PENDING;
    },
    setCurrentEmailWeekly(
      state: customerState,
      {
        payload
      }: PayloadAction<{ _KEY: string; statement_email_weekly: boolean }>
    ) {
      state.state = PENDING;
    },

    setResolved(state) {
      state.state = RESOLVED;
    },
    setRejected(state) {
      state.state = REJECTED;
    },
    setShowMessage(state: customerState, { payload }: PayloadAction<boolean>) {
      state.showMessage = payload;
    },
    setVisibleDropdown(state: customerState) {
      state.visibleDropdown = !state.visibleDropdown;
    },
    getCustomerAddress(
      state: customerState,
      { payload }: PayloadAction<string>
    ) {
      state.state = PENDING;
    },
    saveCustomerAddresses(
      state: customerState,
      { payload }: PayloadAction<Array<CustomerAddress>>
    ) {
      state.state = RESOLVED;
      state.addressList = [...payload];
    },
    deleteCustomerAddress(
      state: customerState,
      { payload }: PayloadAction<{ _KEY: string; action: string }>
    ) {
      state.state = PENDING;
    },
    setDeleteCustomerAddress(
      state: customerState,
      { payload }: PayloadAction<string>
    ) {
      state.state = RESOLVED;
      state.addressList = state.addressList.filter(
        (item) => item._KEY !== payload
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(actionsAuth.setAuthData, (state, { payload }) => {
      let selectedAddress = {};
      const metadata = payload.user.metadata;
      if (payload.user.metadata.customer_actual_address) {
        const actual = JSON.parse(
          payload.user.metadata.customer_actual_address
        );

        let tempAddress = JSON.parse(
          payload.user.metadata.customer_actual_address
        );
        delete tempAddress._KEY;
        delete tempAddress.state.name;
        const deafultAdress = {
          state: { abbr: metadata.customer_main_state },
          address: metadata.customer_main_street,
          city: metadata.customer_main_city,
          zipcode: metadata.customer_main_zipcode
        };
      
        if (
          metadata.customer_closed_days&&
          compareJSONObjects( JSON.stringify(deafultAdress),JSON.stringify(tempAddress))
        
        ) {
          const closedDays:string = formatedString(payload.user.metadata.customer_closed_days);

          selectedAddress = {
            ...actual,
            closedDays
          };
        } else {
          selectedAddress = {
            ...actual
          };
        }
      } else {
        selectedAddress = {
          state: { abbr: payload.user.metadata.customer_state },
          address: payload.user.metadata.customer_street,
          city: payload.user.metadata.customer_city,
          zipcode: payload.user.metadata.customer_zipcode,
          closedDays: formatedString(payload.user.metadata.customer_closed_days)
        };
      }
      state.selectedAddress = selectedAddress;
      state.state = State.RESOLVED;
      return state;
    });
  }
});

export const actionsCustomer = slice.actions;
export default slice.reducer;
