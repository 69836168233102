import React from 'react';

import ProductFilterList from './ProductFilterList';

type Props = {
  showMobileView: boolean;
  toggleMobileView: () => void;
  setFilterCount: (count: number) => void;
  filtersCount: number;
};

const ProductFilter: React.FC<Props> = ({
  showMobileView,
  toggleMobileView,
  filtersCount,
  setFilterCount
}) => {
  return (
    <ProductFilterList
      toggle={toggleMobileView}
      setFilterCount={setFilterCount}
      filtersCount={filtersCount}
      showMobile={showMobileView}
    />
  );
};

export default ProductFilter;
