import React, { ChangeEvent, useEffect, useState } from 'react';
import useDeviceDetect from '../../../Hooks/useDeviceDetect';
import { useSelector } from 'react-redux';
import { addObjectParamsToPath, hrefToObject } from '../../../Utils/Utils';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../../Store/Reducers';

const FavoriteFilterComponent = () => {
  const [filterSelected, setFilterSelected] = useState('all_products');
  const { isMobile } = useDeviceDetect();
  const { filters_conditions, search } = useSelector(
    (state: RootState) => state.FilterReducer
  );
  const { date, favorites } = useSelector(
    (state: RootState) => state.ProductReducer
  );

  const location = useLocation();

  const history = useHistory();
  useEffect(() => {
    const { ...filters }: { [key: string]: any } = hrefToObject();
    if (filters?.favorites) {
      setFilterSelected('favorites');
    } else {
      setFilterSelected('all_products');
    }
  }, [location]);

  const handleFavorite = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    let params = { ...filters_conditions, search_val: search, date };
    if (value === 'favorites') {
      params = { ...params, favorites: true };
    } else {
      if (filters_conditions?.favorites) {
        delete params.favorites;
        delete params.variety;
      }
    }
    history.push(addObjectParamsToPath('/search', params));
    setFilterSelected(value);
  };

  return (
    <div className="d-flex justify-content-between ibf-font-14 my-2 ml-3 mr-5 mx-lg-0">
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          id="all_products"
          value="all_products"
          checked={filterSelected === 'all_products'}
          onChange={handleFavorite}
        />
        <label className="form-check-label" htmlFor="all_products">
          All products
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          id="favorites"
          value="favorites"
          checked={filterSelected === 'favorites'}
          onChange={handleFavorite}
        />
        <label className="form-check-label" htmlFor="favorites">
          {isMobile
            ? `Favorites only (${Object.keys(favorites).length})`
            : 'Available favorites'}
        </label>
      </div>
    </div>
  );
};

export default FavoriteFilterComponent;
