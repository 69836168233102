import React, { RefObject, useState } from 'react';
import Select from 'react-select';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import ZipcodeService from '../../Services/ZipcodeService';
import {
  DeepMap,
  FieldError,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError
} from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Store/Reducers';
import { SignUpState } from './SignupGuestModal';
import { actionsModal } from '../../Store/Modal/Slice';
import { SIGN_UP_MODAL } from '../../Store/Modal/Types';
import { CustomerRegisterModel } from '../../Models/CustomerRegisterModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import { getCheckAvailableUserPhone } from '../../Services/UserService';
import {
  companyInformationOptions,
  getNumbersWithoutSymbols,
  replaceSpecialCharacters
} from '../../Utils/Utils';

type Props = {
  register: UseFormRegister<CustomerRegisterModel>;
  errors: DeepMap<CustomerRegisterModel, FieldError>;
  setValue: any;
  formState: SignUpState;
  dispatchForm: (name: keyof SignUpState, value: string | number) => void;
  setError: UseFormSetError<CustomerRegisterModel>;
  clearErrors: UseFormClearErrors<CustomerRegisterModel>;
  loading: boolean;
  checkboxConfirm: RefObject<HTMLInputElement>;
};

const validationInputMessage = (
  <span className="ibf-font-12 color-red">Please fill in this info.</span>
);
const validationSelectMessage = (
  <span className="ibf-font-12 color-red">Please choose an option.</span>
);
const validationCheckboxMessage = (
  <span className="ibf-font-12 color-red">
    Please select this checkbox if the info is correct.
  </span>
);

const selectValidationStyle = () => {
  return {
    container: (base: any) => ({
      ...base,
      backgroundColor: 'red',
      padding: 1,
      borderRadius: '4px'
    })
  };
};

const options = {
  employees: [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10+', value: 10 }
  ],
  expenses: [
    '> $1000USD',
    '$500USD - $999USD',
    '$100USD - $499USD',
    '< $100USD'
  ],
  events: [
    { label: 'With 0 - 9 events a year', value: '< 10' },
    { label: 'With 10 - 24 events a year', value: '10 - 24' },
    { label: 'With 25 - 59 events a year', value: '25 - 59' },
    { label: 'With 60+ events a year', value: '> 60' }
  ],
  socialMedia: [
    'Another florist or wedding and event planner told me',
    'Facebook',
    'LinkedIn',
    'Google',
    'Instagram',
    'E-mail',
    'iBuyFlowers.com reached out to me (phone or e-mail)'
  ],
  signUpToday: [
    'My wholesaler closed (I need another source)',
    'My wholesaler is still open (but I want another source)'
  ],
  placeOrder: ['Within 7 days', 'Within 14 days', 'Within a month', 'Later'],
  storefront: [
    { label: 'Physical shop', value: 'Physical shop' },
    { label: 'Studio (not home-based)', value: 'Studio (not home-based)' },
    { label: 'Flower truck', value: 'Flower truck' },
    { label: 'Home-based studio', value: 'Home-based studio' }
  ]
};

const CompanyInformationComponent = ({
  register,
  errors,
  setValue,
  formState,
  dispatchForm,
  loading,
  setError,
  clearErrors,
  checkboxConfirm
}: Props) => {
  const {
    user: {
      metadata: {
        customer_zipcode,
        customer_business,
        customer_events_per_year,
        customer_mobile_phone,
        customer_store_type,
        customer_stores_quantity,
        // customer_employees_quantity,
        main_user_key,
        customer_facebook_page,
        customer_website,
        customer_instagram_page
      },
      key
    }
  } = useSelector((state: RootState) => state.AuthReducer);
  const [states] = useState<{ [key: string]: string }>(
    ZipcodeService.getStateMap()
  );

  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const dispatch = useDispatch();

  React.useEffect(() => {
    setValue('customer.zipcode', customer_zipcode);
    setValue('customer.mobile_phone', customer_mobile_phone);
    setMobilePhone(customer_mobile_phone);
  }, [customer_zipcode, setValue, customer_mobile_phone]);

  const backToHome = () => {
    dispatch(actionsModal.setCloseModal({ modal: SIGN_UP_MODAL }));
  };

  const getSelectValue = (
    options: { label: string; value: string | number }[],
    value: string
  ) => {
    return options.find((option) => option.value === value);
  };

  const onBlurCheckPhone = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const phone = getNumbersWithoutSymbols(event.target.value);

    const response = await getCheckAvailableUserPhone({
      phone,
      user_key: main_user_key ?? key
    });
    if (phone && response.success && response.row_count > 0) {
      setError('customer.mobile_phone', {
        type: 'manual',
        message: ''
      });
    } else {
      clearErrors('customer.mobile_phone');
    }
  };

  return (
    <div>
      <b>
        2. Confirm your business type (as the info we're sending depends on
        this)
      </b>

      <div
        className="d-flex flex-column flex-lg-row mt-4"
        style={{ columnGap: '5px' }}>
        <Select
          id="business"
          name="business"
          className="flex-grow-1"
          defaultValue={
            getSelectValue(
              companyInformationOptions.business,
              customer_business
            ) ?? {
              label: companyInformationOptions.business[0].label,
              value: companyInformationOptions.business[0].value
            }
          }
          onChange={(e) => dispatchForm('business', e?.value ?? '')}
          styles={
            !formState.business && errors.customer?.business
              ? selectValidationStyle()
              : {}
          }
          options={companyInformationOptions.business.map((business) => ({
            value: business.value,
            label: business.label
          }))}
        />

        <Select
          id="events_per_year"
          name="events_per_year"
          className="flex-grow-1 mt-2 mt-lg-0"
          defaultValue={
            getSelectValue(options.events, customer_events_per_year) ?? {
              label: options.events[0].label,
              value: options.events[0].value
            }
          }
          onChange={(e) => dispatchForm('events_per_year', e?.value ?? '')}
          styles={
            !formState.events_per_year && errors.customer?.events_per_year
              ? selectValidationStyle()
              : {}
          }
          options={options.events
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((event) => ({
              value: event.value,
              label: event.label
            }))}
        />
      </div>

      {formState.business === 'consumer' ? (
        <div className={'d-flex flex-column'}>
          <span className="mb-3">
            We're so sorry. iBuyFlowers is a business to business wholesaler
            marketplace and can only sell to businesses with a resale number.
          </span>
          <span className="underline pointer" onClick={backToHome}>
            {'<'} Stop and go back to home
          </span>
        </div>
      ) : (
        <>
          <div className="d-flex flex-column mt-2">
            <Label>My business is a: </Label>
            <div className="d-flex flex-column flex-lg-row">
              <div className="flex-grow-1">
                <Select
                  id="storefront_type"
                  name="storefront_type"
                  className="mt-2 mt-lg-0 mr-2"
                  defaultValue={
                    getSelectValue(options.storefront, customer_store_type) ?? {
                      label: options.storefront[0].label,
                      value: options.storefront[0].value
                    }
                  }
                  onChange={(e) => {
                    if (e?.value === 'Home-based studio') {
                      clearErrors('customer.stores_quantity');
                    }
                    dispatchForm('storefront_type', e?.value ?? '');
                  }}
                  styles={
                    !formState.storefront_type &&
                    errors.customer?.storefront_type
                      ? selectValidationStyle()
                      : {}
                  }
                  options={options.storefront
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((store) => ({
                      value: store.value,
                      label: store.label
                    }))}
                />
                {errors?.customer?.storefront_type && validationSelectMessage}
              </div>

              {formState.storefront_type !== 'Home-based studio' && (
                <div className="flex-grow-1">
                  <Select
                    id="stores_quantity"
                    name="stores_quantity"
                    className="mt-2 mt-lg-0"
                    placeholder={'-- Select the number of stores'}
                    defaultValue={
                      getSelectValue(
                        companyInformationOptions.stores_quantity,
                        customer_stores_quantity
                      ) ?? null
                    }
                    onChange={(e) =>
                      dispatchForm('stores_quantity', e?.value ?? '')
                    }
                    styles={
                      !formState.stores_quantity &&
                      errors.customer?.stores_quantity
                        ? selectValidationStyle()
                        : {}
                    }
                    options={companyInformationOptions.stores_quantity}
                  />
                  {errors?.customer?.stores_quantity && validationSelectMessage}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex flex-column mt-2">
            <span>
              How many people are working in your business (owners and employees
              in total)?*
            </span>
            <div className="d-flex flex-wrap">
              {options.employees.map((option, index) => (
                <div className="form-check mr-3" key={index}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name={option.value.toString()}
                    id={option.value.toString()}
                    checked={formState.employees_quantity === option.value}
                    // defaultChecked={
                    //   // option.value === parseInt(customer_employees_quantity) ||
                    //   formState.employees_quantity === option.value
                    // }
                    onChange={(event) =>
                      dispatchForm(
                        'employees_quantity',
                        parseInt(event.target.name)
                      )
                    }
                  />
                  <label
                    className="normal-register-label"
                    htmlFor={option.value.toString()}>
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
            {errors?.customer?.employees_quantity && validationSelectMessage}
          </div>

          <b>
            3. Your primary address (other addresses can be set while shopping)
          </b>

          <div className="d-flex flex-column mt-2">
            <div
              className="d-flex flex-column flex-lg-row "
              style={{ columnGap: '5px' }}>
              <FormGroup className="input-register flex-grow-1 mb-3">
                <Input
                  type="text"
                  id={'street'}
                  placeholder=" "
                  maxLength={35}
                  value={street}
                  {...register('customer.street', {
                    required: true,
                    pattern: /^[a-zA-Z0-9 ]+$/
                  })}
                  onChange={(evt) => {
                    let value = replaceSpecialCharacters(evt.target.value);
                    setStreet(value);
                  }}
                />

                {errors?.customer?.street?.type === 'required' &&
                  validationInputMessage}
                {errors?.customer?.street?.type === 'pattern' && (
                  <span className="ibf-font-12 color-red">
                    {errors?.customer?.street?.message}
                  </span>
                )}
                <label htmlFor="street" className="register-label">
                  Street <span className="text-danger"> * </span>{' '}
                </label>
              </FormGroup>
              <div className="form-group input-register flex-grow-1">
                <Input
                  type="text"
                  placeholder=" "
                  id={'city'}
                  value={city}
                  {...register('customer.city', {
                    required: true,
                    pattern: /([^\s])/
                  })}
                  onChange={(evt) => {
                    let value = replaceSpecialCharacters(evt.target.value);
                    setCity(value);
                  }}
                />

                {errors?.customer?.city?.type === 'required' &&
                  validationInputMessage}
                {errors?.customer?.city?.type === 'pattern' && (
                  <span className="ibf-font-12 color-red">
                    {errors?.customer?.city?.message}
                  </span>
                )}
                <label htmlFor="city" className="register-label">
                  City <span className="text-danger"> * </span>{' '}
                </label>
              </div>
            </div>

            <div className="state-row mb-2">
              <div className=" normal-register-label input-register input-states mt-0 flex-grow-1">
                <label htmlFor="state">
                  <b>State</b> <span className="text-danger"> * </span>{' '}
                </label>
                <Select
                  styles={
                    !formState.state && errors.customer?.state
                      ? selectValidationStyle()
                      : {}
                  }
                  name="state"
                  onChange={(evt) => dispatchForm('state', evt?.value ?? '')}
                  options={Object.keys(states).map((state) => ({
                    value: state,
                    label: states[state]
                  }))}
                />
                {errors?.customer?.state && validationSelectMessage}

                {!errors?.customer?.state && errors?.customer?.zipcode && (
                  <span className="ibf-font-12 text-white">
                    Please write a zipcode
                  </span>
                )}
              </div>
              <div className="input-register flex-grow-1">
                <Input
                  placeholder=" "
                  id={'zipcode'}
                  maxLength={5}
                  {...register('customer.zipcode', {
                    required: true,
                    pattern: /^[0-9]/,
                    maxLength: 5,
                    minLength: 5
                  })}
                  onChange={(evt) =>
                    setValue('customer.zipcode', evt.target.value)
                  }
                  defaultValue={customer_zipcode || ''}
                />
                {errors.customer?.zipcode?.type === 'minLength' ||
                errors.customer?.zipcode?.type === 'maxLength' ? (
                  <span className="ibf-font-12 ibf-text-red">
                    Sorry, your zip code doesn't seem right
                  </span>
                ) : (
                  errors?.customer?.zipcode?.type === 'required' &&
                  validationInputMessage
                )}
                {errors?.customer?.state && !errors?.customer?.zipcode && (
                  <span className="ibf-font-12 text-white">
                    Please write a zipcode
                  </span>
                )}
                {errors?.customer?.zipcode?.type === 'pattern' && (
                  <span className="ibf-font-12 ibf-text-red">
                    Only numbers are accepted
                  </span>
                )}
                <label htmlFor="zipcode" className={'register-label'}>
                  Zipcode <span className="text-danger"> * </span>{' '}
                </label>
              </div>
            </div>

            <div className="d-flex flex-column">
              <div
                className="d-flex flex-column flex-lg-row"
                style={{ columnGap: '5px' }}>
                <div className="input-register flex-grow-1">
                  <Input
                    type="text"
                    placeholder=" "
                    id={'mobile_phone'}
                    value={mobilePhone}
                    {...register('customer.mobile_phone', {
                      required: true,
                      pattern: /([^\s])/,
                      minLength: 10
                    })}
                    onBlur={onBlurCheckPhone}
                    onChange={(evt) => {
                      //setMobilePhone(evt.target.value.replace(/\D/, ''));
                      setMobilePhone(evt.target.value);
                      setValue('customer.mobile_phone', evt.target.value);
                    }}
                  />

                  <div className="d-flex flex-column">
                    {errors.customer?.mobile_phone?.type === 'minLength' && (
                      <span className="ibf-font-12 color-red">
                        Please fill in the 10 digits of your mobile phone
                        number.
                      </span>
                    )}

                    {errors.customer?.mobile_phone &&
                      errors.customer.mobile_phone.type !== 'manual' &&
                      validationInputMessage}
                  </div>
                  <label htmlFor="mobile_phone" className="register-label">
                    (Mobile) number when contact is needed{' '}
                    <span className="text-danger"> * </span>{' '}
                  </label>
                </div>
                <div className="form-group input-register flex-grow-1">
                  <Input
                    type="text"
                    autoComplete="none"
                    placeholder=" "
                    id={'office_phone'}
                    {...register('customer.office_phone', {
                      required: true,
                      pattern: /([^\s])/
                    })}
                  />
                  {errors?.customer?.office_phone && validationInputMessage}
                  <label htmlFor="office_phone" className="register-label">
                    Office Phone <span className="text-danger"> * </span>{' '}
                  </label>
                </div>
              </div>
              {errors.customer?.mobile_phone &&
                errors.customer.mobile_phone.type === 'manual' && (
                  <span className="ibf-font-12 color-red mb-2">
                    This (mobile) number is already related to an iBuyFlowers
                    account. Please, cancel this sign up , and search in your
                    email program for "iBuyFlowers login credentials" and login
                    with that account. Please don't use another (mobile) number
                    as a workaround, as this will cause login issue and
                    duplicate account later on.
                  </span>
                )}
            </div>
            <div
              className="d-flex flex-column flex-lg-row"
              style={{ columnGap: '5px' }}>
              <div className="form-group input-register flex-grow-1 mt-1">
                <Input
                  id={'website'}
                  type="text"
                  placeholder=" "
                  {...register('customer.website')}
                  defaultValue={customer_website || ''}
                />
                <label htmlFor="website" className="register-label">
                  Your website address (optional)
                </label>
              </div>

              <div className="form-group input-register flex-grow-1 mt-1">
                <Input
                  type="text"
                  id={'facebook_page'}
                  placeholder=" "
                  defaultValue={customer_facebook_page || ''}
                  {...register('customer.facebook_page')}
                />
                <label htmlFor="facebook_page" className="register-label">
                  Your facebook address (optional)
                </label>
              </div>
            </div>

            <div className="d-flex flex-lg-row" style={{ columnGap: '5px' }}>
              <div className="form-group input-register flex-grow-1 w-lg-50 flex-lg-grow-0">
                <Input
                  id={'instagram_page'}
                  type="text"
                  placeholder=" "
                  defaultValue={customer_instagram_page || ''}
                  {...register('customer.instagram_page')}
                />
                <label htmlFor="instagram_page" className="register-label">
                  Your Instagram address (optional)
                </label>
              </div>
            </div>

            <div className="form-group">
              <Label
                className="my-1 normal-register-label"
                htmlFor="hear_about_us_first_time">
                <b>
                  Where did you learn about iBuyFlowers.com for the first time?{' '}
                </b>
                <span className="text-danger"> * </span>{' '}
              </Label>

              <Select
                menuPlacement="top"
                id="hear_about_us_first_time"
                {...register('customer.hear_about_us_first_time')}
                name="hear_about_us_first_time"
                defaultValue={{
                  label:
                    '--- Select where you learned about iBuyFlowers for the first time ---',
                  value: ''
                }}
                styles={
                  errors.customer?.hear_about_us_first_time &&
                  !formState?.hear_about_us_first_time
                    ? selectValidationStyle()
                    : {}
                }
                onChange={(evt) =>
                  dispatchForm('hear_about_us_first_time', evt?.value ?? '')
                }
                options={options.socialMedia
                  .sort((a, b) => a.localeCompare(b))
                  .map((number) => ({
                    value: number,
                    label: number
                  }))}
              />

              {errors.customer?.hear_about_us_first_time &&
                !formState?.hear_about_us_first_time &&
                validationInputMessage}
            </div>
            <FormGroup check className={'mb-2 d-flex flex-column'}>
              <Label check>
                <Input
                  type="checkbox"
                  id={'checkboxConfirm'}
                  {...register('customer.checkboxConfirm')}
                  onChange={(evt) => evt.currentTarget.checked && clearErrors()}
                  innerRef={checkboxConfirm}
                />{' '}
                Yes, this info is correct, and I agree to all{' '}
                <a
                  href="https://help.ibuyflowers.com/knowledge/term-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer">
                  <u className="text-dark">general terms</u>
                </a>{' '}
                (including communication, claims, security, and delivery terms).
              </Label>
              {errors.customer?.checkboxConfirm && validationCheckboxMessage}
            </FormGroup>
            <div className="mt-3 d-flex justify-content-center justify-content-lg-start">
              <Button className={'px-5'} color={'primary'} disabled={loading}>
                {loading && (
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    pulse
                    className="mr-2"
                  />
                )}
                <span className="mx-3">Sign up (free) and continue</span>
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CompanyInformationComponent;
