import React, { useState, useRef, useEffect } from 'react';
import WelcomeModal from './WelcomeModal/WelcomeModal';
import GuestModal from './GuestModal/GuestModal';
import {
  getListEmailGuest,
  isMailAvailable,
  LoginService
} from '../../../../Services/AuthService';
import {
  convertNumberDateDDDMMMYYYY,
  hrefToObject,
  toast,
  validateEmail
} from '../../../../Utils/Utils';
import ForgotPasswordModal from './ForgotPasswordModal/ForgotPasswordModal';
import MixpanelService from '../../../../Services/MixpanelService';
import RecoveryPasswordModal from './RecoveryPasswordModal/RecoveryPasswordModal';
import { useDispatch } from 'react-redux';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { SIGN_IN_FLOW } from '../../../../Store/Modal/Types';
import AuthWelcomeModal from './WelcomeModal/AuthWelcomeModal';
import { setCookie, getCookie } from '../../../../Services/AuthService';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';

const storedEmail = localStorage.getItem('ibf_lastEmailUsed');

interface Params {
  user_id?: string;
  code?: string;
  email?: string;
}

export interface EmailGuest {
  _KEY: string;
  email?: string;
  requested_date?: string;
  _META?: object;
}

const { code, user_id, email: emailReferral }: Params = hrefToObject();

const LoginModal = () => {
  // regular login
  const [email, setEmail] = useState(
    emailReferral || storedEmail || getCookie('email') || '' || ''
  );

  const [password, setPassword] = useState('');
  const [loginVisible, setLoginVisible] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [invalidInput, setInvalidInput] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);

  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingVerification, setLoadingVerification] = useState(false);
  const [guestLoginVisibility, setGuestLoginVisibility] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const [isNewEmail, setIsNewEmail] = useState(false);
  const [isNewGuest, setIsNewGuest] = useState({ active: false, date: '' });
  const [recoveryPasswordModal, setRecoveryPasswordModal] = useState(
    Boolean(code) && Boolean(user_id)
  );
  const [isNewUser, setIsNewUser] = useState(!email);
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState('open');
  const [stepsForgetPassword, setStepsForgetPassword] = useState(false);
  const [
    validateMessageForgotPassword,
    setValidateMessageForgotPassword
  ] = useState('');

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (localStorage.getItem('user_key')) {
      localStorage.removeItem('user_key');
    }

    if (invalidPassword && password !== '') {
      setValidateMessageForgotPassword('show error');
    } else {
      setValidateMessageForgotPassword('');
    }
  }, [password, invalidPassword]);

  const handleChangeEmail = (text: any) => {
    if (isNewEmail) {
      setIsNewEmail(false);
    }

    setInvalidInput(false);
    setLoginVisible(false);
    if (password.length) {
      setPassword('');
    }

    if (loadingVerification) {
      setLoadingVerification(false);
    }

    setEmail(text);
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    let event = e.target.value.replace(/\s/g, '');
    setPassword(event);

    setInvalidPassword(false);
    setStepsForgetPassword(false);
  };

  const verifyEmail = async () => {
    if (validateEmail(email)) {
      setLoadingVerification(true);
      localStorage.setItem('ibf_lastEmailUsed', email);
      const isAvailable = await isMailAvailable(email);

      if (isAvailable === null) {
        setLoadingVerification(false);
        return;
      }

      if (!isAvailable) {
        setLoginVisible(true);
        setInvalidInput(false);
      } else {
        const res = await getListEmailGuest();

        if (res && res.data.success) {
          const listEmailGuest = res.data;
          const newGuest = listEmailGuest.item.find(
            (r: EmailGuest) => r.email === email
          );
          if (newGuest) {
            setIsNewGuest({
              active: true,
              date: convertNumberDateDDDMMMYYYY(newGuest.requested_date)
            });
          }
          setIsNewEmail(true);
        }
        setLoadingVerification(false);
        //setGuestLoginVisibility(true);
      }
    } else {
      setInvalidInput(true);
    }
  };

  const loginSubmit = async () => {
    if (!email || !password) {
      return setInvalidInput(true);
    } else {
      setLoadingLogin(true);
      const res = await LoginService(email, password);
      if (res.success) {
        MixpanelService.track('login_customer');
        const token = res.data.token;
        localStorage.setItem('token', token);
        window.location.reload();
        toast('login successful', 'success');
        ///
        setCookie('email', email);
      } else {
        MixpanelService.track('login_error', { error: res.error });
        setLoadingLogin(false);
        setInvalidPassword(true);

        Sentry.withScope(function(scope) {
          scope.setLevel(Severity.Error);
          Sentry.setExtra('Email', email);
          Sentry.setExtra('Response', JSON.stringify(res));
          // The exception has the event level set by the scope (info).
          Sentry.captureException(new Error('Login Error'));
        });
        toast(res.error, 'error');
      }
    }
  };

  const openSignInFlowModal = () => {
    dispatch(actionsModal.setOpenModal({ modal: SIGN_IN_FLOW }));
  };

  const headerModalText = (
    <h4 className="text-white">
      Order farm-fresh wholesale flowers directly from 100+ farms
    </h4>
  );
  const goToBack = () => {
    setIsNewUser(true);
    setInvalidPassword(false);
    setStepsForgetPassword(false);
    setValidateMessageForgotPassword('');
  };
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (loginVisible) {
      inputRef?.current?.focus();
    } else {
      setInvalidPassword(false);
    }
  }, [loginVisible]);

  return (
    <div>
      {!isNewUser ? (
        !guestLoginVisibility &&
        !forgotPassword &&
        !recoveryPasswordModal &&
        forgotPasswordMessage === 'open' && (
          <WelcomeModal
            loadingLogin={loadingLogin}
            loadingVerification={loadingVerification}
            invalidInput={invalidInput}
            invalidPassword={invalidPassword}
            loginSubmit={loginSubmit}
            passwordVisibility={passwordVisibility}
            setPasswordVisibility={setPasswordVisibility}
            verifyEmail={verifyEmail}
            isNewEmail={isNewEmail}
            isNewGuest={isNewGuest}
            loginVisible={loginVisible}
            password={password}
            email={email}
            handleChangeEmail={handleChangeEmail}
            handleChangePassword={handleChangePassword}
            forgotPassword={() => setForgotPassword(true)}
            continueAsNewCustomer={openSignInFlowModal}
            headerModalText={headerModalText}
            back={goToBack}
            stepsForgetPassword={stepsForgetPassword}
            setStepsForgetPassword={() => {
              setStepsForgetPassword((prev) => !prev);
              setInvalidPassword(true);
              setValidateMessageForgotPassword('');
            }}
            validateMessageForgotPassword={validateMessageForgotPassword}
            innerRef={inputRef}
          />
        )
      ) : (
        <AuthWelcomeModal
          open={isNewUser}
          goToLogin={() => {
            setIsNewUser(false);
            setIsNewEmail(false);
            setIsNewGuest({ ...isNewGuest, active: false });
          }}
          continueAsNewCustomer={openSignInFlowModal}
          headerModalText={headerModalText}
        />
      )}

      {forgotPassword && (
        <ForgotPasswordModal
          close={() => {
            setForgotPassword(false);
            setInvalidPassword(false);
            setStepsForgetPassword(false);
          }}
          setForgotPasswordMessage={(value: string) => {
            setForgotPasswordMessage(value);
            setInvalidPassword(false);
            setValidateMessageForgotPassword('');
          }}
          email={email}
        />
      )}

      {guestLoginVisibility && (
        <GuestModal back={() => setGuestLoginVisibility(false)} email={email} />
      )}

      {/*<LearnAboutModal setOptimisedModal={setOptimisedModal} />*/}

      {recoveryPasswordModal && (
        <RecoveryPasswordModal
          userId={user_id}
          code={code}
          close={() => setRecoveryPasswordModal(false)}
        />
      )}
    </div>
  );
};

export default LoginModal;
