import React, { useState, useEffect } from 'react';
import ReactExport from 'react-data-export';
import { columnName } from '../../Utils/Utils';
import {
  uuidV4,
  formatValueExcel,
  roundUp,
  showTotalPrice
} from '../../Utils/Utils';
import { OrderItemCsv } from '../../Models/Order';
import moment from 'moment';
const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet = ExcelFile.ExcelSheet;
const ExcelColumn = ExcelFile.ExcelColumn;
type Props = {
  element?: React.ReactNode;
  data: Array<OrderItemCsv>;
  fileName?: string;
};
const columnNameAlt = { ...columnName };
columnNameAlt.charge_date = 'Charge date';
const ExcelDataStatements = ({ element, data, fileName }: Props) => {
  const [excelData, setExcelData] = useState<Array<OrderItemCsv>>([]);
  //const [ignoreColumn] = useState<Array<string>>([]);

  useEffect(() => {
    let dataSet: Array<OrderItemCsv> = [];
    let element: OrderItemCsv;

    for (element of data) {
      let { objectElement } = formatValueExcel(element, element.charge_date);
      dataSet.push(objectElement);
    }
    dataSet.sort((a, b) => {
      let dateChargeA = moment(a.charge_date, 'YYYY-MM-DD');
      let dateChargeB = moment(b.charge_date, 'YYYY-MM-DD');
      let dateEtaDateA = moment(a.eta_date, 'YYYY-MM-DD');
      let dateEtaDateB = moment(b.eta_date, 'YYYY-MM-DD');

      if (dateChargeB < dateChargeA) {
        return -1;
      }
      if (dateChargeB > dateChargeA) {
        return 1;
      }

      if (dateEtaDateB < dateEtaDateA) {
        return -1;
      }
      if (dateEtaDateB > dateEtaDateA) {
        return 1;
      }

      return 0;
    });

    let totals = showTotalPrice(dataSet);
    //let skiptEventName = !data.some((element) => element?.event_name);

    for (let item of dataSet) {
      if (item.parent) {
        item.charge_capture = undefined;
        item.charge_date = undefined;
        item.eta_date = undefined;
      }
    }

    let firstRow: boolean = false;
    let dataRow: OrderItemCsv = {};
    dataSet.push(dataRow);
    Object.entries(totals).forEach(([key, value]) => {
      if (value !== 0) {
        let row: OrderItemCsv = {
          event_name: key,
          total_price: `$ ` + roundUp(value)
        };
        /*  if (skiptEventName) {
          row.grower = key;
        } else {
          row.event_name = key;
        } */
        if (!firstRow) {
          row.grower = 'Total for:';
          firstRow = true;
        }
        dataSet.push(row);
      }
    });
    /* if (!data.some((element) => element?.event_name)) {
      ignoreColumn.push('event_name');
    }*/

    setExcelData(dataSet);
  }, [data]);

  return (
    <>
      <ExcelFile element={element} filename={fileName}>
        <ExcelSheet data={excelData} name="Orders">
          {Object.keys(columnNameAlt)
            // .filter((item) => !ignoreColumn.includes(item))
            .map((name: string) => {
              return (
                <ExcelColumn
                  label={columnNameAlt[name]}
                  value={name}
                  key={uuidV4()}
                />
              );
            })}
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};
export default ExcelDataStatements;
