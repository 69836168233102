import { actionsShoppingList } from './Slice';
import {
  createShoppingList,
  getShoppingListsProcessed,
  setDeleteShoppingListItem
} from '../../Services/ShoppingListService';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  CHECK_LAST_SL_ITEM,
  CHECK_LAST_SL_ITEM_BY_BOX,
  CREATE_SHOPPING_LIST,
  GET_SHOPPING_LIST_PROCESSED,
  REMOVE_INCOMPLETE_ITEM,
  REMOVE_ITEM_FROM_SL,
  REMOVE_ORI_ITEM,
  REMOVE_SHOPPING_LIST_ITEM
} from './Types';
import { RootState } from '../Reducers';
import { ShoppingList } from '../../Models/ShoppingList';
import { addObjectParamsToPath, toast } from '../../Utils/Utils';
import { customerKey } from '../Selectors';
import { actionsCheckout } from '../Checkout/Slice';
import MixpanelService from '../../Services/MixpanelService';
import { actionsCart } from '../Cart/Slice';
import { actionsCustomer } from '../Customer/Slice';
import { actionsFilter } from '../Filter/Slice';
import { removeItemFromSL } from '../../Services/ProductService';

export function* removeShoppingListItemSaga({
  payload: { slItem }
}: ReturnType<typeof actionsShoppingList.removeShoppingListItem>): any {
  try {
    const res = yield call(setDeleteShoppingListItem, slItem);
    if (res?.success) {
      const customer = yield select(customerKey);
      yield put(actionsShoppingList.setRemoveShoppingListItem(slItem));
      yield put(actionsCart.listCart({ customer, deleteCartbox: true }));
    } else {
      toast(res.error, 'error');
      yield put(actionsShoppingList.setRejected());
    }
  } catch (e) {
    console.error('Error on remove SL item saga');
    yield put(actionsShoppingList.setRejected());
  }
}

export function* removeORItemSaga({
  payload: { orderRequest, productKey }
}: ReturnType<typeof actionsShoppingList.removeORItem>): any {
  try {
    const itemsInCart = yield select(
      (state: RootState) => state.CheckoutReducer.itemsInCart
    );
    const customer = yield select(customerKey);
    const shoppingListCheckout = yield select(
      (state: RootState) => state.ShoppingListReducer.shoppingListCheckout
    );
    const sl = shoppingListCheckout.find(
      (sl: ShoppingList) => sl.order_request === orderRequest
    );
    if (sl) {
      const shoppingListItem = sl.items.find(
        (sli: any) => sli.order_request_item === productKey
      );
      const params = {
        payload: { slItem: shoppingListItem._KEY },
        type: 'shoppingList/removeShoppingListItem'
      };
      yield removeShoppingListItemSaga(params);
      yield put(actionsCheckout.setItemsInCart(itemsInCart - 1));
      yield put(actionsShoppingList.setRemoveShoppingListItem(productKey));
      yield put(actionsCheckout.getCheckoutInfoByCustomer({ customer }));
    }
  } catch (e) {
    console.error('Error on remove remove ORI saga');
    yield put(actionsShoppingList.setRejected());
  }
}

export function* removeIncompleteItemSaga({
  payload: { slItem, productKey }
}: ReturnType<typeof actionsShoppingList.removeIncompleteItem>): any {
  try {
    const params = {
      payload: { slItem: slItem },
      type: 'shoppingList/removeShoppingListItem'
    };
    yield removeShoppingListItemSaga(params);
    const itemsInCart = yield select(
      (state: RootState) => state.CheckoutReducer.itemsInCart
    );
    const customer = yield select(customerKey);
    yield put(actionsCheckout.setItemsInCart(itemsInCart - 1));
    yield put(actionsShoppingList.setRemoveShoppingListItem(productKey));
    yield put(actionsCheckout.getCheckoutInfoByCustomer({ customer }));
  } catch (e) {
    console.error('Error on remove remove ORI saga');
    yield put(actionsShoppingList.setRejected());
  }
}

export function* checkLastSlItemSaga({
  payload: { slItemKey, productKey }
}: ReturnType<typeof actionsShoppingList.checkLastSLItem>) {
  try {
    const { products, incompleteProducts } = yield select(
      (state: RootState) => state.CheckoutReducer
    );
    if (products.length > 0 || incompleteProducts.length > 0) {
      const productSL = products.some((product: any) =>
        product.items.some((item: any) => {
          if (item.shopping_list_item) {
            return (
              item.shopping_list_item._KEY === slItemKey &&
              item._KEY !== productKey
            );
          }
          return false;
        })
      );
      if (!productSL) {
        const incompleteSL = incompleteProducts.some(
          (product: any) => product.shoppingListItem === slItemKey
        );
        if (!incompleteSL) {
          const params = {
            payload: { slItem: slItemKey },
            type: 'shoppingList/removeShoppingListItem'
          };
          yield removeShoppingListItemSaga(params);
        }
      }
    }
    yield put(actionsShoppingList.setResolved());
  } catch (e) {
    console.error('Error on check las SL item saga');
    yield put(actionsShoppingList.setRejected());
  }
}

export function* checkLastSlItemByBoxSaga({
  payload: { slItemKey, productKey }
}: ReturnType<typeof actionsShoppingList.checkLastSLItemByBox>) {
  try {
    const { products, incompleteProducts } = yield select(
      (state: RootState) => state.CheckoutReducer
    );
    if (products.length > 0 || incompleteProducts.length > 0) {
      const cartbox = products.find(
        (product: any) => product._KEY === productKey
      );
      if (cartbox) {
        const findCartbox = cartbox.items.some((cartboxItem: any) => {
          const productSL = products.some((product: any) =>
            product.items.some((item: any) => {
              if (item.shopping_list_item && cartboxItem.shopping_list_item) {
                return (
                  item.shopping_list_item._KEY ===
                    cartboxItem.shopping_list_item._KEY &&
                  item._KEY !== productKey
                );
              }
              return false;
            })
          );
          if (!productSL) {
            const incompleteSL = incompleteProducts.some((product: any) => {
              if (product.shoppingListItem) {
                return (
                  product.shoppingListItem ===
                  cartboxItem.shopping_list_item._KEY
                );
              }
              return false;
            });
            if (!incompleteSL) {
              return true;
            }
          }
          return false;
        });

        if (findCartbox) {
          const params = {
            payload: { slItem: slItemKey },
            type: 'shoppingList/removeShoppingListItem'
          };
          yield removeShoppingListItemSaga(params);
        }
      }
    }
    yield put(actionsShoppingList.setResolved());
  } catch (e) {
    console.error('Error on check las SL item by box saga');
    yield put(actionsShoppingList.setRejected());
  }
}

export function* getShoppingListsProcessedSaga({
  payload
}: ReturnType<typeof actionsShoppingList.getShoppingListProcessed>): any {
  try {
    const shoppingListsProcessed = yield call(
      getShoppingListsProcessed,
      payload
    );
    if (shoppingListsProcessed.success) {
      yield put(
        actionsShoppingList.setShoppingListsProcessed(
          shoppingListsProcessed.results
        )
      );
    }
  } catch (e) {
    console.error('Error on get processed shopping lists saga');
    yield put(actionsShoppingList.setRejected());
  }
}

export function* createShoppingListSaga({
  payload
}: ReturnType<typeof actionsShoppingList.createShoppingList>): any {
  try {
    const response = yield call(
      createShoppingList,
      payload.customer,
      payload.address
    );

    if (response && response.success) {
      const { user } = yield select((state: RootState) => state.AuthReducer);
      MixpanelService.track('calculate_to_checkout');
      yield put(actionsShoppingList.setShoppingLists(response.box));
      yield put(actionsCart.listCart({ customer: payload.customer }));
      toast('Order received correctly', 'success');
      const preferences = {
        ...user.metadata.preferences,
        ...{ hideGreetingModal: true }
      };
      yield put(
        actionsCustomer.updateUserPreferences({
          customer: user.metadata.customer,
          preferences
        })
      );
      yield put(actionsFilter.setSearch({ search: '' }));
      yield put(actionsFilter.setFilters({}));
      window.location.href = addObjectParamsToPath('#/calculating', {});
    } else {
      console.error('Error creating shopping list');
      yield put(actionsShoppingList.setRejected());
    }
  } catch (e) {
    console.error('Error on creating shopping lists saga');
    yield put(actionsShoppingList.setRejected());
  }
}

export function* removeItemFromSLSaga({
  payload
}: ReturnType<typeof actionsShoppingList.removeItemFromSl>): any {
  try {
    const res = yield call(removeItemFromSL, payload.key);
    if (res.success) {
      yield put(actionsCart.listCart({ customer: payload.customer }));
      toast('item has been deleted');
      yield put(actionsShoppingList.setResolved());
    }
  } catch (e) {
    console.error('Error remove item from SL saga');
    yield put(actionsShoppingList.setRejected());
  }
}

export default function* AllSagas() {
  yield all([
    takeEvery(REMOVE_SHOPPING_LIST_ITEM, removeShoppingListItemSaga),
    takeEvery(CHECK_LAST_SL_ITEM, checkLastSlItemSaga),
    takeEvery(CHECK_LAST_SL_ITEM_BY_BOX, checkLastSlItemByBoxSaga),
    takeEvery(REMOVE_ORI_ITEM, removeORItemSaga),
    takeEvery(REMOVE_INCOMPLETE_ITEM, removeIncompleteItemSaga),
    takeEvery(CREATE_SHOPPING_LIST, createShoppingListSaga),
    takeEvery(REMOVE_ITEM_FROM_SL, removeItemFromSLSaga),
    takeEvery(GET_SHOPPING_LIST_PROCESSED, getShoppingListsProcessedSaga)
  ]);
}
