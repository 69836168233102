import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PurchaseItems from './PurchaseItems';
import { convertDateToCalendar } from '../../../Utils/Utils';
import CheckoutCartProducts from '../CheckoutCartProducts/CheckoutCartProducts';
import CartProduct from './../../../Models/CartProduct';
import {
  cartReducer,
  checkoutReducer,
  loadingReducer
} from '../../../Store/Selectors';
import LoadingSpinner from '../LoadingSpinner';
import useDeviceDetect from '../../../Hooks/useDeviceDetect';

const CardItemsToPurchase = ({ toggle }: { toggle: () => void }) => {
  const [count, setCount] = useState(0);
  const { cartProducts } = useSelector(cartReducer);
  const { isMobile } = useDeviceDetect();
  const { checkoutInfo, incompleteProducts, orderRequests } = useSelector(
    checkoutReducer
  );

  const { addingCartLoading } = useSelector(loadingReducer);

  useEffect(() => {
    let productCount = 0;
    Object.keys(cartProducts).forEach((key: string) => {
      productCount += cartProducts[key].length;
    });
    setCount(productCount);
  }, [cartProducts]);

  // validate if there are items in the checkout
  const [checkout, setCheckout] = useState(false);

  useEffect(() => {
    if (
      (checkoutInfo && checkoutInfo.results && checkoutInfo.results.carts) ||
      incompleteProducts?.length ||
      orderRequests?.length
    ) {
      if (
        checkoutInfo?.results?.carts?.length ||
        incompleteProducts?.length ||
        orderRequests?.length
      ) {
        setCheckout(true);
      }
    }
  }, [checkoutInfo, incompleteProducts, orderRequests]);

  return (
    <>
      {addingCartLoading ? (
        <div className="p-5 text-center">
          <LoadingSpinner
            message={'Loading'}
            width={isMobile ? '70vw' : '30em'}
          />
        </div>
      ) : (
        <div className="ibf-cart-items">
          {Object.keys(cartProducts).length > 0 && (
            <div className="bg-white px-3 py-2">
              {checkout && count >= 1 && (
                <div className="px-2 pb-2">
                  <span>
                    {count} new {count === 1 ? 'item' : 'items'} added:
                  </span>
                </div>
              )}

              {Object.keys(cartProducts)
                .sort((a: any, b: any) => b - a)
                .map((key: any, index: number) => (
                  <Fragment key={'cart-item-' + index}>
                    <span style={{ display: 'none' }}>
                      {convertDateToCalendar(key)}
                    </span>
                    {cartProducts[key].map(
                      (product: CartProduct, index: number) => (
                        <>
                          <PurchaseItems
                            key={product.key}
                            quantity={product.quantity}
                            length={product.length}
                            product={product}
                          />
                        </>
                      )
                    )}
                  </Fragment>
                ))}
            </div>
          )}
          <CheckoutCartProducts count={count} toggle={toggle} />
        </div>
      )}
      {/* {cartLoading ? (
        <div className="p-5 text-center">
          <LoadingSpinner
            message={'Loading'}
            width={isMobile ? '70vw' : '30em'}
          />
        </div>
      ) :  */}
    </>
  );
};

export default CardItemsToPurchase;
