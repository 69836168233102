import Product from '../../Models/Product';
import { ProductState } from './Types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../Models/State';
import uniqBy from 'lodash.uniqby';
import { arrayToObject, convertNumberDateToDate } from '../../Utils/Utils';
import { isDate } from 'util';
import { getISOWeek } from 'date-fns';
import FilterItem from '../../Models/FilterItem';
import { GetProductParams } from '../../Models/GetProductParams';
import CartProduct from '../../Models/CartProduct';
import ProductGroup, { ProductGroupByGrower } from '../../Models/ProductGroup';
import SearchInventoryParams from '../../Models/SearchInventory/SearchInventoryParams';
import { TotalProductByDate } from '../../Models/Product';

const { IDLE, RESOLVED, PENDING, REJECTED } = State;
const initialState: ProductState = {
  products: [],
  unavailableProducts: [],
  filters: [],
  favorites: {},
  moreOptions: [],
  currentPage: 1,
  totalItems: 0,
  totalPages: 0,
  productGroup: '',
  categoryList: [],
  imageDetail: '',
  product: new Product(),
  productReplaces: [],
  numberWeek: 0,
  date: 0,
  productGroupSuggestions: [],
  productGroupSuggestionsBySearch: [],
  state: IDLE,
  totalProductByDate: [],
  productGroupByGrower: [],
  productByProductGroup: [],
  loadingProductSuggestion: false,
  varietySuggestionsBySearch: [],
  loadingVarietySuggestion: false
};
let product: Product;
const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getProducts(
      state: ProductState,
      {
        payload
      }: PayloadAction<{
        params: GetProductParams;
        loading?: boolean;
        pageProduct?: boolean;
      }>
    ) {
      state.state = PENDING;
    },
    getProductDetail(
      state: ProductState,
      {
        payload
      }: PayloadAction<{
        variety: string;
        date: number;
        customer: string;
      }>
    ) {
      state.state = PENDING;
    },
    getProductReplacements(
      state: ProductState,
      { payload }: PayloadAction<any>
    ) {
      state.state = PENDING;
    },
    setProductReplacements(
      state: ProductState,
      { payload }: PayloadAction<Array<any>>
    ) {
      state.productReplaces = payload;
      state.state = RESOLVED;
    },
    clearUnavailableProducts(state: ProductState) {
      state.unavailableProducts = [];
    },
    setProductDate(
      state: ProductState,
      { payload }: PayloadAction<{ date: number }>
    ) {
      state.state = RESOLVED;
      const numberWeek = isDate(convertNumberDateToDate(payload.date))
        ? getISOWeek(convertNumberDateToDate(payload.date))
        : 0;

      product = state.product.weeks
        ? {
            ...state.product,
            isAvailable: state.product?.weeks?.substr(numberWeek - 1, 1) === '1'
          }
        : state.product;
      state.numberWeek = numberWeek;
      state.product = product;
      state.date = payload.date;
    },
    setProductDetail(
      state: ProductState,
      {
        payload
      }: PayloadAction<{
        productGroup: string;
        product: Product;
        imageDetail: string;
        productReplaces: Product[];
      }>
    ) {
      state.state = RESOLVED;
      if (payload.product) {
        const prod = state?.products?.find(
          (p) => payload.product.key === p.key
        );
        state.product = {
          ...payload.product,
          starting_price:
            payload.product?.starting_price || (prod && prod.starting_price),
          isAvailable: state.date
            ? payload.product?.weeks?.substr(state.numberWeek - 1, 1) === '1'
            : true
        };

        state.productGroup = payload.productGroup;
        state.imageDetail = payload.imageDetail;
        state.productReplaces = payload.productReplaces;
      }
    },
    setProducts(
      state: ProductState,
      {
        payload: { results }
      }: PayloadAction<{
        results: {
          products: Product[];
          unavailableProducts: any[];
          filters: any[];
          currentPage: number;
          totalItems: number;
          totalPages: number;
          availableDates?: number[];
          availableGrowers?: FilterItem[];
          moreOptions?: any[];
        };
      }>
    ) {
      state.state = RESOLVED;
      product = state.product;
      if (product.key) {
        const prod = results.products.find((p) => product.key === p.key);
        if (prod) {
          product.starting_price = prod.starting_price;
        }
      }
      state.product = product;
      state.products = results.products;
      state.unavailableProducts = results.unavailableProducts;
      state.filters = results.filters;
      state.currentPage = results.currentPage;
      state.totalItems = results.totalItems;
      state.totalPages = results.totalPages;
      if (results.moreOptions) {
        state.moreOptions = results.moreOptions;
      }
    },
    setCategoryList(state: ProductState, { payload }) {
      state.state = RESOLVED;
      state.categoryList = payload;
    },
    setPageProduct(
      state: ProductState,
      { payload }: PayloadAction<{ products: Product[] }>
    ) {
      state.products = uniqBy([...state.products, ...payload.products], 'key');
      state.currentPage = state.currentPage + 1;
    },
    setFavoriteProducts(
      state: ProductState,
      { payload }: PayloadAction<Product[]>
    ) {
      if (payload) {
        state.favorites =
          payload.length > 0 ? arrayToObject(payload, 'key') : {};
      }
    },
    updateProductQuantity(
      state: ProductState,
      {
        payload
      }: PayloadAction<{
        productKey: string;
        quantity: number;
        purchase?: boolean;
        prevQuantity?: number;
        boxOnly?: boolean;
      }>
    ) {
      state.state = PENDING;
    },
    setCustomizableProduct(
      state: ProductState,
      { payload }: PayloadAction<{ product: Product; customizable: boolean }>
    ) {
      state.products.forEach((product) => {
        if (product.key === payload.product.key) {
          product.customizable = payload.customizable;
        }
      });
    },
    addProduct(
      state: ProductState,
      {
        payload
      }: PayloadAction<{
        params: CartProduct | { products: Array<CartProduct> };
        customer: string;
      }>
    ) {
      state.state = PENDING;
    },
    removeFavoriteProduct(
      state: ProductState,
      { payload }: PayloadAction<{ productKey: string }>
    ) {
      state.state = RESOLVED;
      delete state.favorites[payload.productKey];
      state.products.forEach((product: Product) => {
        if (product.key === payload.productKey) {
          delete product.favorite_key;
        }
      });
    },
    setProductGroupSuggestions(
      state,
      { payload }: PayloadAction<{ masterlist: ProductGroup[] }>
    ) {
      state.state = RESOLVED;
      state.productGroupSuggestions = payload.masterlist;
    },
    filterProductNoAvailable(
      state,
      { payload }: PayloadAction<{ key?: string }>
    ) {
      state.products = state.products.filter(
        (item) => item.key !== payload.key
      );
    },
    setProductGroupSuggestionsBySearch(
      state,
      { payload }: PayloadAction<{ masterlist: ProductGroup[] }>
    ) {
      state.state = RESOLVED;
      state.productGroupSuggestionsBySearch = payload.masterlist;
    },
    setLoadingVarietySuggestionsBySearch(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.loadingVarietySuggestion = payload;
    },
    setVarietySuggestionsBySearch(
      state,
      { payload }: PayloadAction<{ items: Array<string> }>
    ) {
      state.state = RESOLVED;
      state.varietySuggestionsBySearch = payload.items;
    },

    setLoadingProductSuggestion(state, { payload }: PayloadAction<boolean>) {
      state.loadingProductSuggestion = payload;
    },

    setProductByProductGroup(
      state,
      { payload }: PayloadAction<{ product: Product[] }>
    ) {
      state.state = RESOLVED;
      state.productByProductGroup = payload.product;
    },
    setProductToCart(state) {
      return state;
    },
    setRejected(state) {
      state.state = REJECTED;
    },
    setResolved(state) {
      state.state = RESOLVED;
    },
    setPending(state) {
      state.state = RESOLVED;
    },
    getTotalProductByDate(
      state,
      { payload }: PayloadAction<Array<SearchInventoryParams>>
    ) {
      state.state = PENDING;
    },
    setTotalProductByDate(
      state,
      { payload }: PayloadAction<Array<TotalProductByDate>>
    ) {
      state.totalProductByDate = payload;
      state.state = RESOLVED;
    },
    setProductGroupByGrower(
      state,
      { payload }: PayloadAction<ProductGroupByGrower[]>
    ) {
      state.state = RESOLVED;
      state.productGroupByGrower = payload;
    }
  }
});

export const actionsProduct = slice.actions;
export default slice.reducer;
