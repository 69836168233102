import React, { useState } from 'react';
import { faHeart, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleFavoriteProducts } from '../../../Services/ProductService';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from '../../../Utils/Utils';
import Product from '../../../Models/Product';
import { State } from '../../../Models/State';
import { actionsProduct } from '../../../Store/Product/Slice';
import { RootState } from '../../../Store/Reducers';
import noFav from '../../../Assets/icons/noFavorite.png';

type Props = {
  isDetailForm?: boolean;
  favorite: boolean;
  product?: Product;
};

const FavoriteComponent: React.FC<Props> = ({
  isDetailForm,
  favorite,
  product
}) => {
  const dispatch = useDispatch();
  const customer = useSelector(
    (state: RootState) => state.AuthReducer.user.metadata.customer
  );
  const { favorites } = useSelector((state: RootState) => state.ProductReducer);
  const { PENDING, RESOLVED, IDLE, REJECTED } = State;
  const [isLoading, setIsLoading] = useState(IDLE);

  const handleFavorites = async () => {
    const params = {
      customer,
      variety: product?.favorite_key ? '' : product?.key,
      favorite_key: product?.favorite_key
    };
    if (isLoading !== PENDING) {
      setIsLoading(PENDING);
      const response = await handleFavoriteProducts(params);
      if (!favorite) {
        if (response?.success && !product?.favorite_key) {
          dispatch(
            actionsProduct.setFavoriteProducts([
              ...Object.values(favorites),
              { ...response.newFavorite }
            ])
          );
          toast('Product added to favorites.');
          setIsLoading(RESOLVED);
        } else {
          setIsLoading(REJECTED);
        }
      } else {
        if (product?.favorite_key) {
          dispatch(
            actionsProduct.removeFavoriteProduct({ productKey: product.key })
          );
          setIsLoading(RESOLVED);
        }
      }
    }
  };

  return (
    <div
      className={
        isDetailForm ? 'd-flex align-items-center pointer mt-2 p-2' : ''
      }
      onClick={handleFavorites}>
      {isLoading === PENDING ? (
        <FontAwesomeIcon
          icon={faSpinner}
          className={isDetailForm ? '' : 'ibf-heart-icon'}
          pulse
        />
      ) : (
        <>
          {favorite ? (
            <FontAwesomeIcon
              icon={faHeart}
              color={'deeppink'}
              className={isDetailForm ? '' : 'ibf-heart-icon'}
            />
          ) : (
            <img
              src={noFav}
              alt="no favorite icon"
              width="18"
              height="16"
              className={isDetailForm ? '' : 'ibf-heart-icon'}
            />
          )}
        </>
      )}
      {isDetailForm && (
        <span className="underline ml-1">
          {favorite ? 'Saved as favorite (undo)' : 'Save as favorite'}
        </span>
      )}
    </div>
  );
};

export default FavoriteComponent;
