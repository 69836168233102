import React from 'react';

import { GrowerPricing } from '../../../../../Models/GrowerPricing';

import Product from '../../../../../Models/Product';
type Props = {
  product: Product;
  grower: GrowerPricing;
  selectData: (value: number) => void;
  growerSelected?: string;
  boxOnly?: boolean;
  activeTab: string;
};
export default function GrowerLengths({
  product,
  selectData,
  grower,
  growerSelected,
  activeTab
}: Props) {
  return (
    <div className="my-1 mx-1">
      <div className="d-flex flex-wrap align-items-center mt-2">
        {Object.values(product.pricing!)
          .filter((item) => item.grower === growerSelected)
          .map((price) =>
            price.options
              .filter((item) =>
                product.customizable && activeTab === '2'
                  ? item.customizable
                  : item
              )
              .map((option) => option.length)
          )
          .reduce((prev, curr) => prev.concat(curr), [])
          .filter((filterArr, i, arr) => arr.indexOf(filterArr) === i)
          .sort((a, b) => a - b)
          .map((element, index) => {
            return (
              <span
                onClick={() => {
                  selectData(element);
                }}
                key={index}
                className={`mr-1 mb-1 ibf-font-12 size-growers ${
                  element === grower.length
                    ? `btn text-dark font-weight-bold border pointer`
                    : `underline pointer`
                }`}>
                {`${element}cm`}
              </span>
            );
          })}
      </div>
    </div>
  );
}
