import React from 'react';

export default function StandingTableHead() {
  return (
    <thead>
      <tr className="bg-ibf-light-gray text-dark overflow-hidden border-bottom-0">
        <th className=" font-weight-light border-top-0  text-nowrap">S.O.#</th>
        <th className=" font-weight-light border-top-0  text-nowrap">Status</th>
        <th className="font-weight-light border-top-0" colSpan={3}>
          Cycle
        </th>
        <th className="font-weight-light border-top-0 text-nowrap">
          {' '}
          Product group - Variety
        </th>
        <th className=" font-weight-light border-top-0 ">Length</th>
        <th className="font-weight-light border-top-0 text-nowrap ">Grower</th>
        <th className=" border-top-0 font-weight-light">Stems</th>
        <th className=" border-top-0  font-weight-light text-nowrap">
          Total price
        </th>
      </tr>
    </thead>
  );
}
