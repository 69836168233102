import { actionsCustomer } from './Slice';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  addCustomerAddress,
  getCustomer,
  loadCustomerAddresses,
  setCustomerAddress,
  setUserPreferences,
  updateCustomerInfoService,
  updateUserInfoService,
  setCustomerEmailWeekly,
  deleteCustomerAddress
} from '../../Services/UserService';
import { toast } from '../../Utils/Utils';
import {
  ADD_CUSTOMER_ADDRESS,
  GET_CURRENT_ADDRESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_AND_USER_INFO,
  UPDATE_USER_PREFERENCES,
  SET_CURRENT_EMAIL_WEEKLY,
  GET_CUSTOMER_ADDRESS,
  DELETE_CUSTOMER_ADDRESS
} from './Types';
import { actionsAuth } from '../Auth/Slice';
import { actionsModal } from '../Modal/Slice';
import {
  COMPANY_INFORMATION_MODAL,
  CONFIRM_PROFILE_MODAL
} from '../Modal/Types';
import { ResponseSbx } from '../../Models/ResponseSbx';
import moment from 'moment';
import { select } from 'typed-redux-saga';
import { authReducer } from '../Selectors';
import { Actions } from '../../Models/CustomerModel';

export function* addCustomerAddressSaga({
  payload: { customer, save, slAddress, closedDays }
}: ReturnType<typeof actionsCustomer.addCustomerAddress>): any {
  try {
    const res = yield call(
      addCustomerAddress,
      customer,
      slAddress,
      closedDays,
      save
    );
    // const res = yield call(addCustomerAddress, customer, slAddress, save);

    if (res) {
      const response = yield call(loadCustomerAddresses, customer);

      if (response.success && response.results) {
        yield put(
          actionsCustomer.setCustomerAddresses(response.results.addresses)
        );
        yield put(
          actionsCustomer.setSelectedAddress({
            ...slAddress,
            closedDays: closedDays
          })
        );
        toast('Address changed successfully');
      }
    } else {
      yield put(actionsCustomer.setRejected());
      toast('There is an error, please contact support.', 'error');
    }
  } catch (e) {
    console.error('Error on add customer address saga');
    yield put(actionsCustomer.setRejected());
  }
}

export function* getCurrentAddress({
  payload
}: ReturnType<typeof actionsCustomer.getCurrentAddress>): any {
  try {
    const customer = yield call(getCustomer, payload.customer);
    const actual_address = customer.actual_address;
    if (!actual_address) {
      const defaultAddress = {
        state: { abbr: payload.customer_state },
        address: payload.customer_street,
        city: payload.customer_city,
        zipcode: payload.customer_zipcode,
        closedDays: payload.customer_closed_days
      };
      yield setCustomerAddress(
        payload.customer,
        JSON.stringify(defaultAddress)
      );
      yield put(actionsCustomer.setSelectedAddress(defaultAddress));
    } else {
      yield put(actionsCustomer.setResolved());
    }
  } catch (e) {
    console.error('Error on get current customer address saga');
    yield put(actionsCustomer.setRejected());
  }
}
export function* updateUserPreferencesSaga({
  payload
}: ReturnType<typeof actionsCustomer.updateUserPreferences>): any {
  try {
    const res = yield call(
      setUserPreferences,
      payload.customer,
      payload.preferences
    );
    if (res.success) {
      yield put(
        actionsAuth.setUserPreferences({ preferences: payload.preferences })
      );
      yield put(actionsCustomer.setResolved());
    } else {
      yield put(actionsCustomer.setRejected());
    }
  } catch (e) {
    console.error('Error in set user preferences saga');
    yield put(actionsCustomer.setRejected());
  }
}

function* updateCustomerAndUserInfoSaga({
  payload
}: ReturnType<typeof actionsCustomer.updateCustomerAndUserInfo>): any {
  const {
    user: { metadata, key }
  } = yield select(authReducer);
  try {
    let letNull = false;
    if (metadata.from_manager) {
      letNull = true;
    }
    const [resCustomer, resUser] = yield all([
      call(updateCustomerInfoService, payload.customer, letNull),
      call(updateUserInfoService, payload.user)
    ]);
    if (resCustomer.success) {
      toast('Company information was updated!');
    } else {
      toast('An error ocurred with customer update.', 'error');
    }

    if (resUser.success) {
      toast('User information was updated!');
    } else {
      toast('An error ocurred with user update.', 'error');
    }

    const validateParams: { token: string; user_key?: string } = {
      token: payload.token
    };

    if (metadata.from_manager) {
      validateParams.user_key = key;
    }

    yield put(actionsAuth.validateToken(validateParams));
    yield put(actionsModal.setCloseModal({ modal: COMPANY_INFORMATION_MODAL }));
    yield put(actionsCustomer.setResolved());
  } catch (e) {
    console.error('Error update customer and user info saga');
    yield put(actionsCustomer.setRejected());
  }
}

function* updateCustomerSaga({
  payload
}: ReturnType<typeof actionsCustomer.updateCustomer>) {
  try {
    const response: ResponseSbx = yield call(updateCustomerInfoService, {
      ...payload.customer,
      confirm_profile: moment.tz('America/New_York')
    });
    if (response.success) {
      toast('Profile saved!');
      yield put(actionsModal.setCloseModal({ modal: CONFIRM_PROFILE_MODAL }));
      if (payload.reload) {
        window.location.reload();
      }
      const {
        user: { metadata, key }
      } = yield select(authReducer);
      const validateParams: { token: string; user_key?: string } = {
        token: payload.token
      };

      if (metadata.from_manager) {
        validateParams.user_key = key;
      }

      yield put(actionsAuth.validateToken(validateParams));
    } else {
      toast(response.error || 'An error ocurred', 'error');
      yield put(actionsCustomer.setRejected());
    }
  } catch (e) {
    console.error('Error update customer and user info saga');
    yield put(actionsCustomer.setRejected());
  }
}
export function* setCurrentEmailWeeklySaga({
  payload
}: ReturnType<typeof actionsCustomer.setCurrentEmailWeekly>): any {
  try {
    const res = yield call(
      setCustomerEmailWeekly,
      payload._KEY,
      payload.statement_email_weekly
    );
    if (res.success) {
      const customer = yield call(getCustomer, payload._KEY);
      yield put(
        actionsAuth.setUserStatementEmailWeekly(customer.statement_email_weekly)
      );
      if (customer.statement_email_weekly) {
        toast(
          'An email will be sent every Sunday with last weeks account statement',
          'success'
        );
      } else {
        toast('Your email has been successfully unmarked', 'info');
      }

      yield put(actionsCustomer.setResolved());
    } else {
      yield put(actionsCustomer.setRejected());
    }
  } catch (error) {
    yield put(actionsCustomer.setRejected());
  }
}

export function* getCurrentAddressSaga({
  payload
}: ReturnType<typeof actionsCustomer.getCustomerAddress>): any {
  try {
    const res = yield call(loadCustomerAddresses, payload);

    if (res.success) {
      yield put(actionsCustomer.saveCustomerAddresses(res.results.addresses));
    } else {
      toast(res.error || 'An error ocurred', 'error');
    }
  } catch (error) {
    yield put(actionsCustomer.setRejected());
  }
}

export function* deleteCustomerAddressSaga({
  payload
}: ReturnType<typeof actionsCustomer.deleteCustomerAddress>): any {
  try {
    const res = yield call(deleteCustomerAddress, payload._KEY);
    let action = payload.action;
    if (action === Actions.DELETE) {
      if (res.success) {
        yield put(actionsCustomer.setDeleteCustomerAddress(payload._KEY));
        toast('The address has been successfully removed');
      } else {
        toast(res.error || 'An error ocurred', 'error');
      }
    }
    if (!res.success) {
      toast(res.error || 'An error ocurred', 'error');
    }
  } catch (error) {
    yield put(actionsCustomer.setRejected());
  }
}

export default function* AllSagas() {
  yield all([
    //Auth sagas
    takeEvery(ADD_CUSTOMER_ADDRESS, addCustomerAddressSaga),
    takeEvery(GET_CURRENT_ADDRESS, getCurrentAddress),
    takeEvery(UPDATE_USER_PREFERENCES, updateUserPreferencesSaga),
    takeEvery(UPDATE_CUSTOMER_AND_USER_INFO, updateCustomerAndUserInfoSaga),
    takeEvery(UPDATE_CUSTOMER, updateCustomerSaga),
    takeEvery(SET_CURRENT_EMAIL_WEEKLY, setCurrentEmailWeeklySaga),
    takeEvery(GET_CUSTOMER_ADDRESS, getCurrentAddressSaga),
    takeEvery(DELETE_CUSTOMER_ADDRESS, deleteCustomerAddressSaga)
  ]);
}
