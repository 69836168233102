import React, { useCallback, useEffect } from 'react';
import { Alert, Nav, Navbar, NavbarBrand, NavItem } from 'reactstrap';
import { ReactComponent as Logo } from '../../Assets/Images/logo-ibuyflowers.svg';
import MyAccountMenu from '../Shared/MyAccountMenu/MyAccountMenu';
import AllFlowersListComponent from './AllFlowersListComponent/AllFlowersListComponent';
import {
  addObjectParamsToPath,
  appendToFirst,
  convertDateToNumberDate,
  convertNumberDateToDate,
  getNextBusinessDay,
  hrefContainsProperty,
  removeTo,
  toggleClassWithId
} from '../../Utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MenuComponent from './MenuComponent/MenuComponent';
import SearchInput from './SearchInputComponent/SearchInput';
import TraceComponent from './TraceComponent/TraceComponent';
import MixpanelService from '../../Services/MixpanelService';
import GrowerListComponent from './GrowerListComponent/GrowerListComponent';
import ColorListComponent from './ColorListComponent/ColorListComponent';
import FavoriteListComponent from './FavoriteListComponent/FavoriteListComponent';
import { actionsDisplay } from '../../Store/Display/Slice';
import { actionsFilter } from '../../Store/Filter/Slice';
import { RootState } from '../../Store/Reducers';
import Skeleton from 'react-loading-skeleton';
import HeaderDeletedProducts from './HeaderComponent/HeaderDeletedProducts';
import { State } from '../../Models/State';
import CartComponent from '../CartComponent/CartComponent';
import HeaderComponent from './HeaderComponent/HeaderComponent';
import { calendarReducer } from '../../Store/Selectors';
import { DateUtils } from 'react-day-picker';

// import MessageReminderNav from "../MessageReminderNav/MessageReminderNav";
// import HeaderComponent from './HeaderComponent/HeaderComponent';
import HeaderGuestUserComponent from './HeaderComponent/HeaderGuestUserComponent';
import Environment from '../../Environment';

const NavBar: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { date, numberWeek } = useSelector(
    (state: RootState) => state.ProductReducer
  );
  const {
    user: { metadata },
    config
  } = useSelector((state: RootState) => state.AuthReducer);

  const { disabledDays } = useSelector(calendarReducer);

  const { navOptions } = useSelector(
    (state: RootState) => state.DisplayReducer
  );

  const { authLoading } = useSelector(
    (store: RootState) => store.LoadingReducer
  );
  const { state: homeLoadingState } = useSelector(
    (store: RootState) => store.HomeLoadingReducer
  );

  const getNavHeight = useCallback(() => {
    const navigation = document.getElementById('navBar');
    const height = navigation?.offsetHeight;
    // header height
    const navigationBar = document.getElementById('navBarHeader');
    let headerHeight = navigationBar?.offsetHeight;
    if (height) {
      dispatch(actionsDisplay.setNavHeight(height));
    }
    if (headerHeight) {
      dispatch(actionsDisplay.setNavHeaderHeight(headerHeight));
    } else {
      dispatch(actionsDisplay.setNavHeaderHeight(0));
    }
  }, [dispatch]);

  useEffect(() => {
    getNavHeight();
  }, [getNavHeight]);

  const getElementByCategory = async (category: string) => {
    MixpanelService.track(`${category}_menu`);
    const keys =
      Environment.categoriesKey[category] &&
      Environment.categoriesKey[category].split(',');

    const params: any = { date, category: keys };
    if (hrefContainsProperty('week')) {
      params.week = numberWeek;
    }
    history.push(addObjectParamsToPath('/search', params));
    closeMenu();
  };

  const getComboBoxes = async () => {
    const params: any = {
      date,
      // combo: true
      product_group: 'dc2d4e70-31a7-4009-972a-f5bbe103df9a'
    };
    if (hrefContainsProperty('week')) {
      params.week = numberWeek;
    }

    history.push(addObjectParamsToPath('/search', params));
    closeMenu();
  };

  const goHome = async () => {
    MixpanelService.track('home_menu');
    history.push('/home');
    dispatch(actionsFilter.setResetFilters(false));
    closeMenu();
  };

  const openMenu = (menuName: string) => {
    const options = Object.keys(navOptions).reduce((obj: any, key: string) => {
      obj[key] = key === menuName;
      return obj;
    }, {});
    MixpanelService.track(`${menuName}_menu`);
    dispatch(actionsDisplay.setOpenNavOptions(options));
    const divOverlayElement = <div />;
    const overlay = document.getElementById('overlay');
    if (overlay) {
      removeTo('overlay');
    }
    appendToFirst('products-container', 'overlay', divOverlayElement);
    toggleClassWithId('overlay', 'dropdown-overlay');
  };

  const closeMenu = () => {
    const options = Object.keys(navOptions).reduce((obj: any, key: string) => {
      obj[key] = false;
      return obj;
    }, {});

    dispatch(actionsDisplay.setOpenNavOptions(options));
    const overlay = document.getElementById('overlay');
    if (overlay) {
      removeTo('overlay');
    }
  };

  const nextDeliveryDay = (dateToCheck?: number) => {
    let date1: null | Date = null;
    if (dateToCheck) {
      date1 = new Date(convertNumberDateToDate(dateToCheck));
    } else {
      date1 = new Date();
    }
    if (date1) {
      const nextAvailable = convertDateToNumberDate(date1);
      const disableNumberDates = disabledDays
        .filter((days) => DateUtils.isDate(days))
        .map((days) => convertDateToNumberDate(days));

      if (
        disableNumberDates.some((days) => days === nextAvailable) ||
        nextAvailable === convertDateToNumberDate(new Date())
      ) {
        nextDeliveryDay(convertDateToNumberDate(getNextBusinessDay(date1)));
      } else {
        history.push(`/search?date=${nextAvailable}`);
      }
    }
  };

  return (
    <div
      id="navBar"
      className="ibf-nav-sticky"
      style={{ zIndex: window.innerWidth > 980 ? 1024 : 10 }}>
      {authLoading ? (
        <div className="p-2">
          <Skeleton height={60} />
          <Skeleton height={45} />
        </div>
      ) : (
        <>
          <div>
            {homeLoadingState === State.RESOLVED && (
              <HeaderDeletedProducts getNavHeight={getNavHeight} />
            )}
          </div>
          <div id="navBarHeader">
            {!authLoading && metadata && !metadata.guest && (
              <HeaderComponent getNavHeight={getNavHeight} />
            )}
            {config.vDayAlert && (
              <Alert
                style={{
                  backgroundColor: '#ffffce',
                  borderColor: 'black',
                  color: 'black',
                  fontSize: '14px'
                }}
                className="pl-1 rounded-0 mb-0"
                isOpen>
                <div className="d-lg-inline-flex d-block">
                  <span className="font-weight-bold">
                    {config.vDayAlert.title ?? ''}
                  </span>

                  {config.vDayAlert.messages &&
                    config.vDayAlert.messages.length > 0 && (
                      <ol className="pl-1 m-0">
                        {config.vDayAlert.messages.map((message, index) => (
                          <li key={index} className="d-inline mr-1">
                            <b>{index + 1})</b>{' '}
                            <b className="mr-1">{message.bold ?? ''}</b>
                            <span>{message.message ?? ''}</span>
                          </li>
                        ))}
                      </ol>
                    )}
                </div>
              </Alert>
            )}

            {/*{!authLoading && metadata && metadata.guest && (*/}
            {/*  <HeaderGuestUserComponent getNavHeight={getNavHeight} />*/}
            {/*)}*/}
            {!authLoading && metadata && metadata.guest && (
              <HeaderGuestUserComponent getNavHeight={getNavHeight} />
            )}
          </div>
          {/*<MessageReminderNav />*/}
          <Navbar
            className="align-items-baseline pb-0 px-3 ibf-nav-sm-shadow ibf-nav-block "
            light
            expand="md">
            <div className="d-flex align-items-center justify-content-between w-100 mb-1">
              <MenuComponent redirectToCategory={getElementByCategory} />
              <NavbarBrand onClick={goHome} className={'ibf-logo'}>
                <Logo />
              </NavbarBrand>
              <CartComponent className="ibf-nav-mobile-cart" />
            </div>
            <Nav className="mr-auto ibf-search-width" navbar>
              {/*Desktop*/}
              <NavItem className="w-100">
                <SearchInput />
              </NavItem>
            </Nav>
            <Nav>
              <NavItem className="pl-3">
                <CartComponent className="ibf-nav-desktop-cart" />
              </NavItem>
            </Nav>
          </Navbar>

          {/*<div className={isUp ? "" : "d-none"} >*/}
          <div>
            <Navbar className="ibf-nav-shadow ibf-nav-second align-items-baseline  py-0 px-1">
              <Nav>
                <NavItem className="my-3 px-3">
                  <span
                    onClick={goHome}
                    className="ibf-text-navbar d-none d-sm-inline pointer">
                    Home
                  </span>
                </NavItem>
                <NavItem className="my-3 px-3">
                  <span
                    onClick={() => nextDeliveryDay()}
                    className="ibf-text-navbar d-none d-sm-inline pointer">
                    Next day (Tu, We, Th)
                  </span>
                </NavItem>
                <NavItem className="my-3 px-3">
                  <AllFlowersListComponent
                    openMenu={() => openMenu('flowers')}
                    closeMenu={closeMenu}
                  />
                </NavItem>
                <NavItem className="my-3 px-3">
                  <GrowerListComponent
                    openMenu={() => openMenu('growers')}
                    closeMenu={closeMenu}
                  />
                </NavItem>

                <NavItem className="my-3 px-3">
                  <ColorListComponent
                    openMenu={() => openMenu('colors')}
                    closeMenu={closeMenu}
                  />
                </NavItem>

                <NavItem className="my-3 px-3">
                  <span
                    onClick={() => getElementByCategory('bouquets')}
                    className="ibf-text-navbar d-none d-sm-inline pointer">
                    Bouquets/CB
                  </span>
                </NavItem>

                <NavItem className="my-3 px-3">
                  <span
                    className="ibf-text-navbar d-none d-sm-inline pointer"
                    onClick={getComboBoxes}>
                    Mixed box
                  </span>
                </NavItem>

                <NavItem className="my-3 px-3">
                  <span
                    className="ibf-text-navbar d-none d-sm-inline pointer"
                    onClick={() => getElementByCategory('driedPreserved')}>
                    Dried/ Preserved
                  </span>
                </NavItem>

                {/* <NavItem className="my-3 px-3">
                  <span
                    className="ibf-text-navbar d-none d-sm-inline pointer"
                    onClick={() => getElementByCategory('supplies')}>
                    Supplies
                  </span>
                </NavItem> */}
              </Nav>

              <Nav className="align-items-baseline">
                <TraceComponent />
                <NavItem className="my-3 px-3">
                  <FavoriteListComponent
                    openMenu={() => openMenu('favorites')}
                    closeMenu={closeMenu}
                  />
                </NavItem>
                <NavItem className="px-3">
                  <MyAccountMenu
                    openMenu={() => openMenu('myAccount')}
                    closeMenu={closeMenu}
                  />
                </NavItem>
              </Nav>
            </Navbar>
          </div>
        </>
      )}
    </div>
  );
};

export default NavBar;
