import React, { useEffect, useState } from 'react';
import {
  Button,
  // Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { getStateList, toast } from '../../../../Utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import ZipcodeService from '../../../../Services/ZipcodeService';
import MixpanelService from '../../../../Services/MixpanelService';
import { RootState } from '../../../../Store/Reducers';
import { actionsCustomer } from '../../../../Store/Customer/Slice';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { ADDRESSES_MODAL } from '../../../../Store/Modal/Types';
import { modalReducer } from '../../../../Store/Selectors';
import ClosedDays from '../../ClosedDays';
type Props = {
  state: string;
  city: string;
  address: string;
  zipcode: string;
};

const AddressModal = () => {
  const { cartProducts } = useSelector((state: RootState) => state.CartReducer);
  const { orderRequests, products, incompleteProducts } = useSelector(
    (state: RootState) => state.CheckoutReducer
  );
  const { user } = useSelector((state: RootState) => state.AuthReducer);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [closedDays, setClosedDays] = useState(['']);

  const [state, setState] = useState({
    newAddress: '',
    city: '',
    zipcode: '',
    state: '{"name":"Alabama","abbr":"AL"}'
  });

  const [dataAddress, setDataAddress] = useState<Props>({
    address: '',
    city: '',
    zipcode: '',
    state: '{"name":"Alabama","abbr":"AL"}'
  });

  /* const [save] = useState(false); */

  const toggle = () => {
    dispatch(actionsModal.setCloseModal({ modal: ADDRESSES_MODAL }));
  };

  useEffect(() => {
    // const parsedState = JSON.parse(state.state).abbr;
    // const codes = ZipcodeService.getZipcodeByState(parsedState);
    // setZipcodes(codes);
    const slAddress: Props = {
      state: JSON.parse(state.state),
      city: state.city,
      address: state.newAddress,
      zipcode: state.zipcode
    };
    setDataAddress(slAddress);
  }, [state.state, state.city, state.newAddress, state.zipcode]);

  useEffect(() => {
    MixpanelService.track('select_address');
  }, []);

  // const loadAddress = () => {
  //   if (state.newAddress && state.city && state.zipcode && state.state) {
  //     const regexp = /^[0-9]{5}$/;
  //     if (regexp.test(state.zipcode)) {
  //     } else {
  //       toast('Invalid zip code', 'error');
  //     }
  //   } else {
  //     toast('Fill all the data before adding another address', 'error');
  //   }
  // };

  const validateAddress = () => {
    const { newAddress, city, zipcode } = state;

    setLoading(true);

    if (newAddress.length === 0 || city.length === 0 || zipcode.length === 0) {
      toast('There is an empty field, please complete it.', 'error');
      setLoading(false);
    } else if (zipcode.length < 5) {
      toast('The zipcode must have 5 digits.', 'error');
      setLoading(false);
    } else {
      if (
        Object.keys(cartProducts).length > 0 ||
        orderRequests.length ||
        products.length ||
        incompleteProducts.length
      ) {
        toast(
          'Please checkout the items in your shopping cart before changing the destination address.',
          'error'
        );
        setLoading(false);
      } else {
        /* const slAddress = {
          state: JSON.parse(state.state),
          city,
          address: newAddress,
          zipcode
        }; */
        if (state.newAddress && state.city && state.zipcode && state.state) {
          const regexp = /^[0-9]{5}$/;
          if (regexp.test(state.zipcode)) {
            const customer = user.metadata.customer;
            dispatch(actionsCustomer.setVisibleDropdown());
            dispatch(
              actionsCustomer.addCustomerAddress({
                customer,
                slAddress: dataAddress,
                closedDays: closedDays.filter((res) => res !== ''),
                save: true
              })
            );
            // loadAddress();
            toggle();
            setLoading(false);
            dispatch(actionsCustomer.getCustomerAddress(customer));
          } else {
            toast('Invalid zip code', 'error');
            setLoading(false);
          }
        } else {
          toast('Fill all the data before adding another address', 'error');
          setLoading(false);
        }
      }
    }
  };

  const handleChange = (event: any) => {
    let { value, name } = event.target;

    if (name === 'city' || name === 'newAddress') {
      value = value.replace(/[^\w\s]/gi, '');
    }

    setState({ ...state, [name]: value });
  };

  const {
    ADDRESSES_MODAL: { open }
  } = useSelector(modalReducer);

  return (
    <div>
      <Modal size="md" isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Add a (one time) delivery address
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column">
            <div className="form-group row align-items-center">
              <label htmlFor="address" className="col-sm-3">
                Street
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  id="address"
                  maxLength={25}
                  className="form-control"
                  placeholder="Street"
                  name="newAddress"
                  value={state.newAddress}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className=" form-group row align-items-center">
              <label className=" col-sm-3">City:</label>
              <div className=" col-sm-9">
                <input
                  type="text"
                  id=" city"
                  className=" form-control"
                  placeholder=" City"
                  name="city"
                  value={state.city}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className=" form-group row align-items-center">
              <label className=" col-sm-3">State:</label>
              <div className=" col-sm-9">
                <select
                  className=" custom-select custom-select-sm"
                  name="state"
                  id=" state"
                  value={state.state}
                  onChange={handleChange}>
                  {getStateList().map((i) => (
                    <option
                      key={i.abbr}
                      value={JSON.stringify(
                        i
                      )}>{`${i.abbr} - ${i.name}`}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group row align-items-center">
              <label className=" col-sm-3">Zipcode:</label>
              <div className=" col-sm-9">
                {/*<select*/}
                {/*  id="zipcode"*/}
                {/*  className=" form-control"*/}
                {/*  placeholder="zipcode"*/}
                {/*  name="zipcode"*/}
                {/*  value={state.zipcode}*/}
                {/*  onChange={handleChange}>*/}
                {/*  {zipcodes.map((zipcode: string) => (*/}
                {/*    <option key={zipcode} value={zipcode}>*/}
                {/*      {zipcode}*/}
                {/*    </option>*/}
                {/*  ))}*/}
                {/*</select>*/}

                <input
                  required
                  pattern="[0-9]{5}"
                  type="text"
                  id="zipcode"
                  step="0.01"
                  name="zipcode"
                  value={state.zipcode}
                  onChange={handleChange}
                  inputMode="numeric"
                  className="form-control ibf-input-number"
                  placeholder={' '}
                  maxLength={5}
                />
              </div>
            </div>
            <div>
              <label>
                <b> For this address </b>
              </label>
              <ClosedDays returnClosedDays={(data) => setClosedDays(data)} />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={loading} onClick={validateAddress}>
            {loading ? (
              <span>
                <FontAwesomeIcon pulse icon={faSpinner} />
              </span>
            ) : (
              'Add Address'
            )}
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddressModal;
