import React from 'react';

type Props = {
  lengthSelect?: JSX.Element;
  growerSelect: JSX.Element;
  isAList: boolean;
};

const ListGrowerInputs: React.FC<Props> = ({
  lengthSelect,
  growerSelect,
  isAList
}) => {
  return (
    <>
      <div
        className={`ibf-product-form-wrapper-grower-select ${
          isAList ? 'px-1' : ''
        }`}>
        {growerSelect}
      </div>

      <div className="ibf-product-form-wrapper-length-select">
        {lengthSelect}
      </div>
    </>
  );
};

export default ListGrowerInputs;
