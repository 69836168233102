import React from 'react';
import {
  StandingOrder,
  itemsMasterList
} from '../../../../Models/StandingOrder';

type Props = {
  standingOrder: StandingOrder;
  res?: itemsMasterList;
  custom: boolean;
  columName: string;
};
export default function StandingOrderContent({
  standingOrder,
  res,
  custom,
  columName
}: Props) {
  let masterlist = custom
    ? res?.masterlist
    : standingOrder?.items[0]?.masterlist;

  let content;
  const bunchesInbox = masterlist?.bunches_in_box;
  const length = masterlist?.length;

  if (columName === 'length') {
    content = <>{length} cm</>;
  } else {
    content = (
      <>
        {' '}
        {parseInt(
          standingOrder?.items[0]?.masterlist?.stems_per_bunch?.stems_per_bunch!
        ) * standingOrder?.items[0]?.masterlist?.bunches_in_box!}{' '}
        stems <br />
        (in {bunchesInbox} bunches)
      </>
    );
  }
  return <>{content}</>;
}
