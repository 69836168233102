import React, { useEffect, useRef, useState } from 'react';
import ProductComponent from './ProductComponent/ProductComponent';
import DisplayFilterComponent from '../Shared/DisplayFilterComponent/DisplayFilterComponent';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import './productList.scss';
import SortOptionComponent from '../Shared/SortOptionComponent/SortOptionComponent';
import { actionsProduct } from '../../Store/Product/Slice';
import { useHistory } from 'react-router-dom';
import {
  convertDateToNumberDate,
  formatedString,
  // convertDateToStantard,
  hrefToObject,
  toast
} from '../../Utils/Utils';
import { State } from '../../Models/State';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons';
import {
  authReducer,
  customerReducer,
  filterReducer,
  loadingReducer,
  productReducer
} from '../../Store/Selectors';
import AvailabilityAirlineCalendar from '../AvailabilityAirlineCalendar/AvailabilityAirlineCalendar';
import { GrowerPricing } from '../../Models/GrowerPricing';
import AlertComponent from '../Shared/AlertComponent';
import MessageReminderShop from '../MessageReminderShop/MessageReminderShop';
import { initialStateDays } from '../Shared/ClosedDays';
import { Grower } from '../../Models/Grower';
// import { actionsCalendar } from '../../Store/Calendar/Slice';
import {
  CategoryResult,
  getProductsSuggestion,
  searchInventory
} from '../../Services/ProductService';
import SearchInventoryParams from '../../Models/SearchInventory/SearchInventoryParams';
import Product, { ResSearchInventory } from '../../Models/Product';
import { Popover } from 'antd';
import moment from 'moment';
import { Variety } from '../../Models/Variety';
import ProductGroup from '../../Models/ProductGroup';
import { Categories } from '../../Models/Categories';

type Props = {
  toggleMobileView: () => void;
  filtersCount: number;
};

const growerCache: { [grower_key: string]: Grower } = {};
let today = new Date();
today.setDate(today.getDate() + 1);
const tomorrow = convertDateToNumberDate(today);

const { PENDING } = State;

const ProductList: React.FC<Props> = ({ toggleMobileView, filtersCount }) => {
  const { filterLoading, isAList, initialLoading } = useSelector(
    loadingReducer
  );
  const {
    user: {
      metadata: { customer }
    }
  } = useSelector(authReducer);
  const { selectedAddress } = useSelector(customerReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    currentPage,
    totalPages,
    state,
    products,
    favorites,
    date,
    unavailableProducts,
    productGroupSuggestionsBySearch,
    //productByProductGroup,
    //loadingProductSuggestion,
    varietySuggestionsBySearch,
    loadingVarietySuggestion
  } = useSelector(productReducer);
  const favoriteArr = useRef(favorites);
  const { search, substitute } = useSelector(filterReducer);
  const [pageNumber, setPageNumber] = useState(currentPage);
  const [weekdayAvailable, setWeekdayAvailable] = useState('');
  const [localSearch, setLocalSearch] = useState('');
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [dayNumber, setDayNumber] = useState(moment(date, 'YYYYMMDD').day());
  const [productSuggestion, setProductSuggestion] = useState<Array<Product>>(
    []
  );

  // const [filterProductBySuggestions, setFilterProductBySuggestions] = useState<
  //   Array<Product>
  // >([]);

  const [loadingProduct, setloadingProduct] = useState(false);
  // const [weekdayAvailable, setWeekdayAvailable] = useState('');
  // const [dayNumber, setDayNumber] = useState(
  //   new Date(convertDateToStantard(date)).getDay()
  // );

  useEffect(() => {
    setPageNumber(currentPage);
  }, [currentPage]);

  React.useEffect(() => {
    const getAllSugestions = async () => {
      const {
        date: d,
        search_val,
        ...filters
      }: { [key: string]: any } = hrefToObject();

      const term =
        filters.product_group || filters.category || filters.variety || '';

      if (term) {
        const responseSuggestion: {
          items: {
            variety: Variety;
            product_group: ProductGroup;
            category: CategoryResult;
          }[];
          term: string;
          success: boolean;
          cancelled: boolean;
        } = await getProductsSuggestion(term);

        if (responseSuggestion?.items) {
          const item = responseSuggestion.items.find((item) =>
            Object.keys(item).find(
              (key) => item[key as keyof typeof item]._KEY === term
            )
          );

          if (item) {
            const key = Object.keys(item).find(
              (key) => item[key as keyof typeof item]._KEY === term
            );

            if (key) {
              const base = item[key as keyof typeof item];

              setLocalSearch(
                (base as Variety).variety_name ||
                  (base as ProductGroup).common_name ||
                  (base as Categories).category ||
                  ''
              );
            }
          }
        }
      }
    };
    if (products?.length === 0) {
      getAllSugestions();
    } else {
      setLocalSearch('');
    }
  }, [products]);

  useEffect(() => {
    setDayNumber(moment(date, 'YYYYMMDD').day());
  }, [date]);

  useEffect(() => {
    let day = dayNumber - 1;
    let dayAvailable = (weekday: string) => {
      if (selectedAddress.closedDays.includes(weekday)) {
        if (day < 4) {
          day = day + 1;
          dayAvailable(initialStateDays[day]);
        } else {
          day = 0;
          dayAvailable(initialStateDays[day]);
        }
      } else {
        setWeekdayAvailable(weekday);
      }
    };

    selectedAddress.closedDays && dayAvailable(initialStateDays[dayNumber - 1]);
  }, [date, selectedAddress, dayNumber]);
  useEffect(() => {
    if (!search) {
      dispatch(
        actionsProduct.setProductGroupSuggestionsBySearch({ masterlist: [] })
      );
      dispatch(actionsProduct.setVarietySuggestionsBySearch({ items: [] }));
      setloadingProduct(false);
    }
  }, [dispatch, search]);

  useEffect(() => {
    if (products.length < 10 && search.length > 0) {
      setloadingProduct(true);

      if (varietySuggestionsBySearch.length) {
        /*let productGroupKeys = productGroupSuggestionsBySearch.map(
          (item) => item._KEY
        );*/

        const params: SearchInventoryParams = {
          eta: date,
          pageSize: 50,

          customer,
          conditions: {
            filters: {
              //product_group: productGroupKeys,
              variety: varietySuggestionsBySearch
            }
          }
        };

        setloadingProduct(true);
        searchInventory(params)
          .then((res: ResSearchInventory) => {
            if (res.success) {
              let product = res?.response?.products;

              const newArrayProduct = product.filter((objProduct) => {
                const filterProducts = products.some(
                  (obj) => obj.key === objProduct.key
                );

                /*const isRelatedToSearch = objProduct?.name
                    .toLowerCase()
                    .includes(search?.toLowerCase());*/

                return !filterProducts;
                //&& isRelatedToSearch;
              });

              setProductSuggestion(
                newArrayProduct?.length! > 0 ? newArrayProduct : []
              );
              setloadingProduct(false);
            } else {
              setProductSuggestion([]);
              setloadingProduct(false);
              toast(
                'There was an error loading the products by suggestion',
                'error'
              );
            }
          })
          .catch(() => {
            setloadingProduct(false);
            setProductSuggestion([]);
          });
      }
    } else {
      setloadingProduct(false);
    }
  }, [
    customer,
    date,
    productGroupSuggestionsBySearch,
    products,
    search,
    search.length,
    varietySuggestionsBySearch,
    varietySuggestionsBySearch.length
  ]);

  // useEffect(() => {
  //   if (
  //     productSuggestion.length &&
  //     productByProductGroup.length &&
  //     search.length
  //   ) {
  //     const newArrayProductByProductGroup = productSuggestion.filter(
  //       (product) => {
  //         const filterProducts = productByProductGroup.some(
  //           (obj) => obj.key === product.key
  //         );
  //         return !filterProducts;
  //       }
  //     );

  //     //setFilterProductBySuggestions(newArrayProductByProductGroup);
  //   }
  // }, [productByProductGroup, productSuggestion, search.length]);

  return (
    <>
      {!initialLoading && <AvailabilityAirlineCalendar />}
      {!filterLoading && products?.length === 0 ? (
        unavailableProducts?.length > 0 ? (
          <div className="ibf-product-list-grid ml-xl-3">
            {unavailableProducts?.map((prod, index) => (
              <div
                key={prod?.key}
                className={isAList ? 'ibf-border px-3 mb-3' : ''}>
                <ProductComponent
                  isAList={isAList}
                  index={index}
                  product={prod}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="ibf-text-result ibf-text-result-small">
            <div>
              There are no {substitute ? 'substitutes' : 'results'} for "
              {search || substitute || localSearch}" at this moment
            </div>
            <button
              className="btn btn-primary mr-2 mt-2"
              onClick={() => history.goBack()}>
              <FontAwesomeIcon className="mr-1" icon={faAngleDoubleLeft} />
              One step back
            </button>
          </div>
        )
      ) : (
        <>
          <div className="d-flex flex-wrap mb-2">
            {!initialLoading &&
              !filterLoading &&
              products.length > 0 &&
              parseInt(date.toString()) === tomorrow && (
                <AlertComponent color={'#ffffce'} borderColor={'black'} isOpen>
                  <MessageReminderShop
                    classIcon="ibf-font-20"
                    textColor={'black'}
                  />
                </AlertComponent>
              )}

            <DisplayFilterComponent
              toggleFilterMobileView={toggleMobileView}
              filtersCount={filtersCount}
            />
          </div>

          {selectedAddress.closedDays &&
            weekdayAvailable !== initialStateDays[dayNumber - 1] && (
              //
              <div className="my-2 p-1" style={{ backgroundColor: '#ffff7a' }}>
                <FontAwesomeIcon
                  className="ml-2"
                  size={'2x'}
                  icon={faExclamationTriangle}
                />
                <label>
                  You have asked not to receive deliveries on{' '}
                  {formatedString(selectedAddress.closedDays)} to
                  <Popover
                    placement={'top'}
                    trigger="click"
                    visible={popoverVisible}
                    onVisibleChange={() => setPopoverVisible(!popoverVisible)}
                    overlayClassName={`custom-step-desktop-popover`}
                    content={
                      <>
                        {`Deliver to: ${selectedAddress.address}, ${selectedAddress.city}, ${selectedAddress.state.abbr} ${selectedAddress.zipcode}`}
                      </>
                    }>
                    <span className="text-dark text-underline pointer">
                      {' '}
                      this address.
                    </span>{' '}
                  </Popover>
                  If you place an order and it can't be delivered, you will not
                  be able to file a claim for "late delivery".
                </label>
              </div>
            )}

          {filterLoading ? (
            <LoadingSpinner message={'LOADING'} height={'60vh'} />
          ) : (
            <div>
              <InfiniteScroll
                pageStart={1}
                loadMore={async () => {
                  if (state !== PENDING && pageNumber === currentPage) {
                    setPageNumber(currentPage + 1);
                    await dispatch(actionsProduct.setPending());
                    const {
                      date: d,
                      search_val: search = '',
                      ...filters
                    }: { [key: string]: any } = hrefToObject();
                    if (d) {
                      const { orderBy, sortOrder, combo, substitute } = filters;
                      delete filters.orderBy;
                      delete filters.sortOrder;
                      delete filters.combo;
                      delete filters.substitute;
                      const params = {
                        eta: d,
                        conditions: {
                          search,
                          filters,
                          orderBy,
                          sortOrder,
                          combo
                        },
                        substitute,
                        customer,
                        page: currentPage + 1
                      };
                      if (filters.favorites) {
                        filters.variety = Object.keys(favoriteArr.current);
                      }
                      Object.keys(filters).forEach((key) => {
                        if (!Array.isArray(filters[key]))
                          filters[key] = !!filters[key] ? [filters[key]] : [];
                      });
                      await dispatch(
                        actionsProduct.getProducts({
                          params,
                          pageProduct: true
                        })
                      );
                    }
                  }
                }}
                hasMore={
                  pageNumber === currentPage &&
                  state !== PENDING &&
                  totalPages > 1 &&
                  totalPages > currentPage
                }
                className={isAList ? '' : 'ibf-product-list-grid'}>
                {products.map((product, index: number) => {
                  if (product.pricing) {
                    product = {
                      ...product,
                      pricing: Object.keys(product.pricing).reduce(
                        (
                          obj: { [grower: string]: GrowerPricing },
                          growerName
                        ) => {
                          if (product.pricing) {
                            if (
                              growerCache[
                                product.pricing[growerName as string].grower
                              ]
                            ) {
                              obj[growerName] = {
                                ...product.pricing[growerName],
                                komet_intg:
                                  growerCache[
                                    product.pricing[growerName as string].grower
                                  ].komet_intg
                              };
                            } else {
                              obj[growerName] = {
                                ...product.pricing[growerName]
                              };
                            }
                          }

                          return obj;
                        },
                        {}
                      )
                    };
                  }

                  return (
                    <div
                      key={product.key}
                      className={isAList ? 'ibf-border px-3 mb-3' : ''}>
                      <ProductComponent
                        isAList={isAList}
                        index={index}
                        product={product}
                        setCustomizableProduct={(customizable: boolean) => {
                          if (product.customizable !== customizable) {
                            dispatch(
                              actionsProduct.setCustomizableProduct({
                                product,
                                customizable
                              })
                            );
                          }
                        }}
                      />
                    </div>
                  );
                })}

                {(pageNumber !== currentPage || state === PENDING) && (
                  <div className="ibf-product-list-grid-item">
                    <div
                      className="border loader d-flex justify-content-center loader w-100 bg-ibf-yellow my-2 py-2"
                      key={0}>
                      Loading ...
                    </div>
                  </div>
                )}
              </InfiniteScroll>

              {(loadingProduct || loadingVarietySuggestion) && (
                <div className="py-2">
                  <LoadingSpinner message={'LOADING'} center={false} />
                </div>
              )}

              {!initialLoading &&
                !filterLoading &&
                products.length < 10 &&
                search.length > 0 &&
                !loadingProduct &&
                productSuggestion.length === 0 &&
                !loadingVarietySuggestion && (
                  <span className="ibf-font-20 d-block my-3">
                    There are no products related to the suggestion of your
                    search "{search}"
                  </span>
                )}

              {!initialLoading &&
                !filterLoading &&
                products.length < 10 &&
                search.length > 0 &&
                !loadingProduct &&
                productSuggestion.length > 0 &&
                !loadingVarietySuggestion && (
                  <>
                    <div className="mt-4">
                      <span className="ibf-font-20 d-block mb-3 ">
                        Related suggestions to your search "{search}"
                      </span>
                      {
                        <div className="ibf-product-list-grid  ">
                          {productSuggestion.map(
                            (product: Product, index: number) => (
                              <div key={product.key} className="">
                                <ProductComponent
                                  product={product}
                                  isAList={false}
                                  isDetailView
                                  index={index}
                                  setCustomizableProduct={(
                                    customizable: boolean
                                  ) => {
                                    if (product.customizable !== customizable) {
                                      dispatch(
                                        actionsProduct.setCustomizableProduct({
                                          product,
                                          customizable
                                        })
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      }
                    </div>

                    <>
                      {/* {loadingProductSuggestion && (
                        <div className="py-2">
                          <LoadingSpinner message={'LOADING'} center={false} />
                        </div>
                      )} */}
                      {/* {filterProductBySuggestions.length ? (
                        <ItemsProductsCarousel
                          products={filterProductBySuggestions}
                        />
                      ) : null} */}
                    </>
                  </>
                )}
            </div>
          )}
          <SortOptionComponent />
        </>
      )}
    </>
  );
};
export default React.memo(ProductList);
