import { Popover } from 'antd';
import React, { ChangeEvent } from 'react';
import { GrowerPricing } from '../../../../../Models/GrowerPricing';
import Product from '../../../../../Models/Product';
import ShowComponentCardView from '../../CardViewComponent/ShowComponentCardView';
import ListGrowerInputs from './ListGrowerInputs';
//import ListQuantityInputs from './ListQuantityInputs';

type Props = {
  lengthSelect: JSX.Element;
  growerSelect: JSX.Element;
  boxSelect: JSX.Element;
  stemInput: JSX.Element;
  events: {
    getBoxesValue: (evt: ChangeEvent<HTMLSelectElement>) => void;
    handleStemChange: (
      evt: React.FormEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
    ) => void;
  };
  buttonName: string | JSX.Element;
  submit: () => void;
  validations: {
    stemValidation: boolean;
    growerSelected: boolean;
    boxOnly?: boolean;
    loadingButton: boolean;
    isProductDetail?: boolean;
    growers: boolean;
  };
  disabledButton: boolean;
  product: Product;
  lengthSelected: GrowerPricing;
  contentPopover: JSX.Element;
  index?: number;
  activeTab: string;
  tabsProductPrice: JSX.Element;
  isProductDetail: boolean;
  boxOnly: boolean;
  isAList: boolean;
  growerLengths: JSX.Element;
};

const ListViewFormComponent: React.FC<Props> = ({
  validations,
  buttonName,
  submit,
  events,
  lengthSelected,
  product,
  contentPopover,
  lengthSelect,
  growerSelect,
  stemInput,
  boxSelect,
  disabledButton,
  index,
  activeTab,
  tabsProductPrice,
  isProductDetail,
  boxOnly,
  isAList,
  growerLengths
}) => {
  const {
    stemValidation,
    growerSelected,
    loadingButton,
    growers
  } = validations;
  /*  const { getBoxesValue, handleStemChange } = events; */

  return (
    <Popover
      overlayClassName="custom-validation-popover"
      placement="top"
      content={contentPopover}
      trigger="focus"
      visible={
        ((product?.growers?.length! > 1 && !boxOnly) ||
          (!growerSelected && !boxOnly)) &&
        stemValidation &&
        growerSelected
      }>
      <div className="my-1 ">
        {growers && (
          <ListGrowerInputs
            //lengthSelect={lengthSelect}
            growerSelect={growerSelect}
            isAList={isAList}
          />
        )}

        {growerLengths}

        <div className="mb-2">
          <ShowComponentCardView
            product={product}
            lengthSelect={lengthSelect}
            growerSelected={growerSelected}
            stemInput={stemInput}
            tabsProductPrice={tabsProductPrice}
            isProductDetail={isProductDetail}
            boxSelect={boxSelect}
            boxOnly={boxOnly!}
            isAList={isAList}
          />
        </div>

        {/*  <ListQuantityInputs
          getBoxesValue={getBoxesValue}
          handleStemChange={handleStemChange}
          lengthSelected={lengthSelected}
          product={product}
          boxSelect={boxSelect}
          index={index}
          stemInput={stemInput}
          validations={validations}
          activeTab={activeTab}
          
          //lengthSelect={lengthSelect}
        /> */}

        <div className="d-flex ibf-product-form-wrapper-add-button mx-1">
          <button
            onClick={submit}
            style={{
              borderRadius: '4px'
            }}
            disabled={(loadingButton || disabledButton) && true}
            className={`btn btn-primary btn-sm justify-content-center btn-block`}>
            {loadingButton ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true">
                  {' '}
                </span>
                <span className="sr-only">Loading...</span>
              </>
            ) : (
              <>
                <span>{buttonName}</span>
              </>
            )}
          </button>
        </div>
      </div>
    </Popover>
  );
};

export default ListViewFormComponent;
