import { runCS, sbxCoreService } from '../Network';
import Environment from '../Environment';
import { UserResponse } from '../Models/UserResponse';
import UserResponseCS from '../Models/ResponseCS/UserResponseCS';
import { Card } from '../Models/Card';
import { CustomerModel } from '../Models/CustomerModel';

export function getUsersList(
  customer: string
): Promise<UserResponseCS<UserResponse>> {
  return runCS(Environment.cloudscripts.getUsersList, { customer }).then(
    (res: UserResponseCS<UserResponse>) => {
      return res;
    }
  );
}

export function saveAddUser(
  customer: any,
  is_new: boolean,
  role: string,
  user: any,
  user_preferences: any
) {
  return runCS(Environment.cloudscripts.newCustomerUser, {
    customer: customer,
    is_new: is_new,
    role: role,
    user: user,
    user_preferences: user_preferences
  })
    .then((res) => res)
    .catch((error: any) => {
      console.error(error);
    });
}

export function updateUser(params: {}): Promise<UserResponseCS<UserResponse>> {
  return runCS(Environment.cloudscripts.updateUser, params).then(
    (res: UserResponseCS<UserResponse>) => {
      return res;
    }
  );
}

export function loadCustomerAddresses(customer: string) {
  return runCS(Environment.cloudscripts.loadAddress, { customer }).then(
    (res: any) => {
      return res;
    }
  );
}

export function setCustomerAddress(
  _KEY: string,
  actual_address: string,
  save: boolean = false
) {
  return sbxCoreService
    .update('customer', { _KEY, actual_address })
    .then((res: any) => res);
}

export function addCustomerAddress(
  customer: string,
  slAddress: object,
  closedDays: Array<string> | string,
  save: boolean = false
) {
  return runCS(Environment.cloudscripts.customerAddress, {
    customer,
    slAddress,
    closedDays: closedDays.toString(),
    save
  }).then((res: any) => {
    return res;
  });
}

export const loadCards = (customer: string) => {
  return sbxCoreService
    .with('customer_payment_option')
    .andWhereIsEqualTo('customer', customer)
    .andWhereIsEqualTo('is_active', true)
    .find()
    .then((res) => res && res.results)
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export function updateCustomerDefaultCard(cardList: Array<Card>, card: Card) {
  const updateArray = cardList.map((item) => {
    return { _KEY: item._KEY, is_default: item.is_default };
  });

  return sbxCoreService
    .update('customer_payment_option', updateArray)
    .then((res: any) => {
      return cardList;
    });
}

export function deleteCustomerCard(
  customer: string,
  card: string,
  isDefault: boolean
) {
  return runCS(Environment.cloudscripts.deletePay, {
    customer,
    payment: card
  }).then((res) => {
    if (res && res.success) {
      return { success: true, cards: res.payments, isDefault };
    } else {
      console.error(res.response.body.error);
      return { success: false };
    }
  });
}

export function addCardService(token: string, customer: string) {
  return runCS(Environment.cloudscripts.addCardToCustomer, {
    token: token,
    customer: customer
  }).then((res) => res);
}

export function getCustomerGrower(key: string, model: string) {
  return runCS(Environment.cloudscripts.customerGrowerRelation, {
    key: key,
    model: model
  })
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
}

export function changeCustomerGrowerRelationship(
  action: string,
  customer: string,
  grower: string,
  block_by: string
) {
  return runCS(Environment.cloudscripts.changeCustomerGrowerRelationship, {
    action: action,
    customer: customer,
    grower: grower,
    block_by: block_by
  })
    .then((res) => res && res.response && res.response.body)
    .catch((error) => {
      console.error(error);
    });
}

export function getManagerInfo(acc_manager: string) {
  return runCS(Environment.cloudscripts.getManagerInfo, {
    acc_manager: acc_manager
  })
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
}

export function sendNewManagerEmail(data: {
  name: string;
  address_date: string;
  acc_manager: string;
  zipcode: string;
  old_manager: string;
  street: string;
  city: string;
  state: string;
  email_address: string;
}) {
  return runCS(Environment.cloudscripts.ibf_sendNewManagerEmail, data)
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
}

export function getArrivalPerGrower() {
  return runCS(Environment.cloudscripts.arrivalPerGrower, {})
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
}

export function getCountryFlag(country_key: string) {
  return Environment.country_flag_map[country_key];
}

export function getStateList() {
  return sbxCoreService
    .with('state')
    .find()
    .then((res) => res && res.results);
}

export function updateCustomerInfo(customer: any) {
  return sbxCoreService.update('customer', customer).then(() => {
    return salesforceSync({
      action: 'update',
      model: 'customer',
      key: customer._KEY
    }).then((res) => res);
  });
}

export function updateCustomerInfoService(
  customer: CustomerModel,
  letNull: boolean = false
) {
  return sbxCoreService
    .update('customer', customer, letNull)
    .then((res: any) => {
      return res;
    });
}

export function updateUserInfoService(user: {
  first_name: string;
  last_name: string;
  _KEY: string;
}) {
  return sbxCoreService.update('user', user).then((res: any) => {
    return res;
  });
}

export function salesforceSync(body: any) {
  return runCS(Environment.cloudscripts.salesforceSync, body).then(
    (res) => res
  );
}

export function loadReferralServices(customer: string) {
  return sbxCoreService
    .with('referral')
    .andWhereIsEqualTo('customer', customer)
    .find()
    .then((res) => {
      return sbxCoreService
        .with('voucher')
        .andWhereIsEqualTo('customer', customer)
        .andWhereIsGreaterThan('quantity', 0)
        .find()
        .then((data) => {
          return { ...res, amountDiscount: data.results.length * 15 };
        });
    })
    .catch((error) => {
      console.error(error);
    });
}

export function sendReferralService(
  user: string,
  firstName: string,
  email: string,
  companyName: string
) {
  return runCS(Environment.cloudscripts.sendReferral, {
    user,
    firstName,
    email,
    companyName
  })
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
}

export function getCustomer(customer: string) {
  return sbxCoreService
    .with('customer')
    .andWhereIsEqualTo('_KEY', customer)
    .find()
    .then((res) => {
      return res.results[0];
    })
    .catch((error) => console.error(error));
}

export function setUserPreferences(
  customer: string,
  preferences: { [key: string]: boolean }
) {
  return sbxCoreService
    .update('customer', {
      _KEY: customer,
      preferences: JSON.stringify(preferences)
    })
    .then((res: any) => res);
}

export function setCustomerEmailWeekly(
  _KEY: string,
  statement_email_weekly: boolean
) {
  return sbxCoreService
    .update('customer', { _KEY, statement_email_weekly })
    .then((res: any) => res);
}

export function getUserInfo(users: UserResponse[]) {
  const query = sbxCoreService.with('user');

  users.forEach((user) => {
    Object.keys(user).forEach((key) => {
      query.andWhereIsEqualTo(key, user[key as keyof UserResponse]);
    });
  });

  return query.find().then((res) => res);
}

export function getCheckAvailableUserPhone({
  user_key,
  phone
}: {
  user_key: string;
  phone: string;
}) {
  const query = sbxCoreService
    .with('user')
    .andWhereIsNotEqualTo('_KEY', user_key)
    .andWhereIsEqualTo('cellphone', phone);

  return query.find().then((res) => res);
}

export function deleteCustomerAddress(_KEY: string) {
  return runCS(Environment.cloudscripts.ibf_deleteCustomerAddress, {
    _KEY: _KEY
  })
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
}

export function checkSqlCustomerService(email: string) {
  return sbxCoreService
    .with('sql_invitation_customer')
    .andWhereIsEqualTo('email', email)
    .andWhereIsNull('customer')
    .find()
    .then((res: any) => res);
}

export function updateSqlCustomerService(
  sql_key: string,
  customer_key: string
) {
  return sbxCoreService
    .with('sql_invitation_customer')
    .update({ _KEY: sql_key, customer: customer_key })
    .then((res) => res);
}
