import React, { useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  authReducer,
  customerReducer,
  modalReducer
} from '../../../../Store/Selectors';
import { Button, Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { COMPANY_INFORMATION_MODAL } from '../../../../Store/Modal/Types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CustomerRegisterModel } from '../../../../Models/CustomerRegisterModel';
import ContactDetailsComponentByCustomer from './ContactDetailsComponentByCustomer';
import CompanyInformationByCustomer from './CompanyInformationByCustomer';
import {
  getStateList,
  sendNewManagerEmail
} from '../../../../Services/UserService';
import { actionsCustomer } from '../../../../Store/Customer/Slice';
import { State } from '../../../../Models/State';
import { TrackEventMonitor } from '../../../../Classes/TrackEvent';
import {
  convertDateToStantard,
  getNumbersWithoutSymbols,
  statesManager
} from '../../../../Utils/Utils';
import Environment from '../../../../Environment';

export type CompanyInformationState = {
  state: string;
  business: string;
  events_per_year: string;
  employees_quantity: number;
  storefront_type: string;
  stores_quantity: number;
};

const initialState: CompanyInformationState = {
  state: '',
  business: '',
  events_per_year: '< 10',
  employees_quantity: 0,
  storefront_type: '',
  stores_quantity: 0
};

enum Types {
  ADD_FORM
}

function reducer(
  state: CompanyInformationState,
  { type, payload }: { type: Types; payload: any }
) {
  switch (type) {
    case Types.ADD_FORM:
      return {
        ...state,
        [payload.name]: payload.value
      };
    default:
      throw new Error();
  }
}

const NewCompanyInformationModal = () => {
  const {
    COMPANY_INFORMATION_MODAL: { open }
  } = useSelector(modalReducer);

  const { state: customerState, showMessage } = useSelector(customerReducer);
  const dispatch = useDispatch();
  const [state, dispatchLocal] = useReducer(reducer, initialState);
  const [allDisable, setAllDisable] = useState(true);
  const [states, setStates] = useState<
    {
      state: string;
      state_iso: string;
      time_zone: string;
      time_zone_abbr: string;
      _KEY: string;
    }[]
  >([]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    clearErrors
  } = useForm<CustomerRegisterModel>();

  const toggle = () =>
    dispatch(actionsModal.setCloseModal({ modal: COMPANY_INFORMATION_MODAL }));

  const {
    token,
    user: {
      first_name,
      last_name,
      key,
      metadata: {
        customer,
        customer_business,
        customer_events_per_year,
        customer_name,
        customer_store_type,
        customer_state,
        customer_street,
        customer_city,
        customer_office_phone,
        customer_mobile_phone,
        customer_website,
        customer_facebook_page,
        customer_stores_quantity,
        customer_employees_quantity,
        main_user_first_name,
        main_user_last_name,
        customer_zipcode,
        customer_resale_number,
        customer_office_email,
        from_manager,
        main_user_key,
        active_so,
        account_manager
      }
    }
  } = useSelector(authReducer);

  React.useEffect(() => {
    dispatchLocal({
      type: Types.ADD_FORM,
      payload: { name: 'business', value: customer_business }
    });
    dispatchLocal({
      type: Types.ADD_FORM,
      payload: { name: 'events_per_year', value: customer_events_per_year }
    });

    dispatchLocal({
      type: Types.ADD_FORM,
      payload: { name: 'storefront_type', value: customer_store_type }
    });

    dispatchLocal({
      type: Types.ADD_FORM,
      payload: { name: 'state', value: customer_state }
    });

    dispatchLocal({
      type: Types.ADD_FORM,
      payload: { name: 'stores_quantity', value: customer_stores_quantity }
    });

    dispatchLocal({
      type: Types.ADD_FORM,
      payload: {
        name: 'employees_quantity',
        value: customer_employees_quantity
      }
    });
  }, [
    customer_business,
    customer_events_per_year,
    customer_store_type,
    customer_state,
    customer_employees_quantity,
    customer_stores_quantity
  ]);

  React.useEffect(() => {
    setValue('customer.company_name', customer_name);
    setValue(
      'user.first_name',
      from_manager ? main_user_first_name : first_name
    );
    setValue('user.last_name', from_manager ? main_user_last_name : last_name);
    setValue('customer.street', customer_street);
    setValue('customer.office_phone', customer_office_phone);
    setValue('customer.city', customer_city);
    setValue('customer.street', customer_street);
    setValue('customer.website', customer_website);
    setValue('customer.facebook_page', customer_facebook_page);
    setValue('customer.mobile_phone', customer_mobile_phone);
    setValue('customer.zipcode', customer_zipcode);
    setValue('customer.tax_id', customer_resale_number);
  }, [
    setValue,
    customer_name,
    customer_city,
    customer_office_phone,
    first_name,
    last_name,
    customer_facebook_page,
    customer_resale_number,
    customer_website,
    customer_mobile_phone,
    customer_zipcode,
    customer_street,
    from_manager,
    main_user_last_name,
    main_user_first_name
  ]);

  React.useEffect(() => {
    getStateList().then((res) => {
      setStates(res);
    });
  }, []);

  const [closedDays, setClosedDays] = useState<string[]>([]);
  const closeModalCompany = () => {
    toggle();
    dispatch(actionsCustomer.setVisibleDropdown());
    dispatch(actionsCustomer.setShowMessage(false));
  };

  const onSubmit: SubmitHandler<CustomerRegisterModel> = async (data) => {
    if (allDisable) {
      setAllDisable((prev) => !prev);
    } else {
      switch (true) {
        case !from_manager && !state.employees_quantity:
          setError('customer.employees_quantity', {
            type: 'manual',
            message: 'Please select the employees_quantity'
          });
          break;
        case !from_manager && !state.storefront_type:
          setError('customer.storefront_type', {
            type: 'manual',
            message: 'Please select the storefront'
          });
          break;
        case !from_manager && !state.state:
          setError('customer.state', {
            type: 'manual',
            message: 'Please select the customer state'
          });
          break;
        case !from_manager && !state.events_per_year:
          setError('customer.events_per_year', {
            type: 'manual',
            message: 'Please select the customer events'
          });
          break;
        default:
          if (
            !from_manager &&
            !state.stores_quantity &&
            state.storefront_type !== 'Home-based studio'
          ) {
            setError('customer.stores_quantity', {
              type: 'manual',
              message: 'Please select stores quantity'
            });
            return;
          }

          let customerState = states.find((stateService) => {
            return stateService.state_iso === state.state;
          });

          const actual_address = JSON.stringify({
            state: {
              abbr: customerState?.state_iso,
              name: customerState?.state
            },
            address: data.customer.street,
            city: data.customer.city,
            zipcode: data.customer.zipcode
          });
          data.customer.mobile_phone = getNumbersWithoutSymbols(
            data?.customer?.mobile_phone!
          );
          data.customer.office_phone = getNumbersWithoutSymbols(
            data?.customer.office_phone!
          );

          const acc_manager = Object.keys(
            statesManager[Environment.domain]
          ).find((manager) =>
            statesManager[Environment.domain][manager].some(
              (stateKey) => stateKey === customerState?._KEY
            )
          );

          if (acc_manager && account_manager !== acc_manager) {
            sendNewManagerEmail({
              acc_manager,
              old_manager: account_manager,
              address_date: convertDateToStantard(new Date()),
              street: data.customer.street as string,
              city: data.customer.city as string,
              email_address: customer_office_email,
              name: main_user_first_name ?? first_name,
              zipcode: data.customer.zipcode as string,
              state: customerState?.state_iso ?? ''
            }).then((res) => res);
          }

          const customerObj = {
            ...data.customer,
            ...state,
            state: customerState?._KEY,
            closed_days: closedDays.filter((res) => res !== ''),
            actual_address,
            _KEY: customer,
            acc_manager: acc_manager ?? '',
            hubspot_pending_update: true
          };

          await TrackEventMonitor.newEvent({
            props: {
              ...customerObj,
              update_place: 'company information modal',
              prev_customer_info: {
                business: customer_business,
                events_per_year: customer_events_per_year,
                employees_quantity: customer_employees_quantity,
                storefront_type: customer_store_type,
                stores_quantity: customer_stores_quantity,
                company_name: customer_name
              }
            },
            metadata: { name: 'ibf_company_update' }
          });

          dispatch(
            actionsCustomer.updateCustomerAndUserInfo({
              customer: customerObj,
              user: {
                last_name: data.user.last_name,
                first_name: data.user.first_name,
                _KEY: main_user_key ?? key,
                hubspot_pending_update: true
              },
              token
            })
          );
          dispatch(actionsCustomer.setShowMessage(false));

          break;
      }
    }
  };

  const dispatchForm = (
    name: keyof CompanyInformationState,
    value: string | number
  ) => {
    clearErrors(`customer.${name}` as keyof CustomerRegisterModel);
    dispatchLocal({ type: Types.ADD_FORM, payload: { name, value } });
  };

  return (
    <Modal isOpen={open} size="xl">
      <ModalBody>
        <div className="hidden-hs-form d-flex align-items-lg-center justify-content-between mb-3 mb-lg-5">
          <div className="d-flex flex-column flex-lg-row">
            <h2 className="mr-2 text-md-nowrap">
              <b>Your company information</b>{' '}
            </h2>
            <div className=" d-flex flex-column">
              <div>
                <Button
                  className={'px-5 mr-2 mr-lg-0'}
                  color={'primary'}
                  type={'button'}
                  onClick={() => handleSubmit(onSubmit)()}
                  disabled={customerState === State.PENDING}>
                  {customerState === State.PENDING && (
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      pulse
                      className="mr-2"
                    />
                  )}
                  <span className="mx-3">
                    {allDisable ? 'Change something' : 'Save and close'}
                  </span>
                </Button>
              </div>

              {showMessage && active_so && (
                <div className="d-flex flex-column">
                  <span className="bg-ibf-cream mt-2 p-1">
                    Changing your primary address will also affect the delivery
                    of your Standing Orders.
                  </span>
                </div>
              )}
              {showMessage && (
                <div className="d-flex flex-column">
                  <span className="bg-ibf-cream mt-2 p-1">
                    Do you want to change the address for just one delivery?
                    Then{' '}
                    <span
                      className="underline pointer mr-1"
                      onClick={() => closeModalCompany()}>
                      close this popup and change the address that is shown at
                      the top of your screen
                    </span>
                    (next to the delivery date).
                  </span>
                </div>
              )}
            </div>
          </div>
          <FontAwesomeIcon
            className={'pointer align-self-start'}
            onClick={toggle}
            icon={faTimes}
            size={'2x'}
          />
        </div>

        <div className="signup-grid hidden-hs-form">
          <ContactDetailsComponentByCustomer
            register={register}
            errors={errors}
            setValue={setValue}
            allDisabled={allDisable}
            setAllDisabled={() => setAllDisable((prev) => !prev)}
          />
          <CompanyInformationByCustomer
            returnClosedDays={(data) => setClosedDays(data)}
            // returnClosedDays={(data) => {}}
            register={register}
            dispatchForm={dispatchForm}
            setValue={setValue}
            setAllDisabled={() => setAllDisable((prev) => !prev)}
            formState={state}
            errors={errors}
            allDisabled={allDisable}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NewCompanyInformationModal;
