import { Popover } from 'antd';
import React, { ChangeEvent } from 'react';
import { GrowerPricing } from '../../../../../Models/GrowerPricing';
import Product from '../../../../../Models/Product';
import CardGrowerInputs from './CardGrowerInputs';
import ShowComponentCardView from '../ShowComponentCardView';
//import CardQuantityInputs from './CardQuantityInputs';

type Props = {
  lengthSelect: JSX.Element;
  growerSelect: JSX.Element;
  boxSelect: JSX.Element;
  stemInput: JSX.Element;
  events: {
    getBoxesValue: (evt: ChangeEvent<HTMLSelectElement>) => void;
    handleStemChange: (
      evt: React.FormEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
    ) => void;
  };
  validations: {
    stemValidation: boolean;
    growerSelected: boolean;
    boxOnly?: boolean;
    isProductDetail?: boolean;
    growers: boolean;
  };
  product: Product;
  lengthSelected: GrowerPricing;
  contentPopover: JSX.Element;
  //index?: number;
  tabsProductPrice: JSX.Element;
  isProductDetail: boolean;
  isAList: boolean;
  growerLengths: JSX.Element;
};

const CardViewFormComponent: React.FC<Props> = ({
  validations,
  events,
  lengthSelected,
  product,
  contentPopover,
  lengthSelect,
  growerSelect,
  stemInput,
  boxSelect,
  //index,
  tabsProductPrice,
  isProductDetail,
  isAList,
  growerLengths
}) => {
  const {
    stemValidation,
    growerSelected,
    //isProductDetail,
    growers,
    boxOnly
  } = validations;
  // const { getBoxesValue, handleStemChange } = events;

  /* const growerValidations = {
    growerSelected,
    isProductDetail,
    boxOnly
  }; */

  return (
    <Popover
      overlayClassName="custom-validation-popover"
      placement="top"
      content={contentPopover}
      trigger="focus"
      visible={
        ((product?.growers?.length! > 1 && !boxOnly) ||
          (!growerSelected && !boxOnly)) &&
        stemValidation &&
        growerSelected
      }>
      <div className="mx-0">
        {growers && (
          <CardGrowerInputs
            lengthSelect={lengthSelect}
            growerSelect={growerSelect}
            validations={validations}
            product={product}

            //tabsProductPrice={tabsProductPrice}
            // growerLengths={growerLengths}
            //validate_Detail
          />
        )}
        {growerLengths}

        <ShowComponentCardView
          product={product}
          lengthSelect={lengthSelect}
          growerSelected={growerSelected}
          stemInput={stemInput}
          tabsProductPrice={tabsProductPrice}
          isProductDetail={isProductDetail}
          boxSelect={boxSelect}
          boxOnly={boxOnly!}
          isAList={isAList}
        />

        {/*  <CardQuantityInputs
          getBoxesValue={getBoxesValue}
          handleStemChange={handleStemChange}
          lengthSelected={lengthSelected}
          product={product}
          boxSelect={boxSelect}
          index={index}
          stemInput={stemInput}
          validations={validations}
        /> */}
      </div>
    </Popover>
  );
};

export default CardViewFormComponent;
