import { actionsCalendar } from './Slice';
import { all, put, select, takeEvery, call } from 'redux-saga/effects';
import { RootState } from '../Reducers';
import {
  convertNumberDateToDate,
  getNextBusinessDay,
  getOrangeDays,
  calculateDisabledDays,
  USA,
  getGrowerTime,
  getEstNowTimeMinutes,
  showDaysOfWeek
} from '../../Utils/Utils';
import {
  INIT_CALENDAR,
  SET_NEXT_DAY,
  GET_TOTAL_PRODUCTS_BY_DATE
} from './Types';
import { getTotalProductByDateService } from '../../Services/ProductService';
import { Grower } from '../../Models/Grower';
//import { TotalProductByDate } from '../../Models/Product';
export function* setNextDaySaga({
  payload
}: ReturnType<typeof actionsCalendar.setNextDay>): any {
  yield put(actionsCalendar.setTime(payload));
  const modifiers: any = {
    today: new Date()
  };
  const disabledDays = yield select(
    (state: RootState) => state.CalendarReducer.disabledDays
  );
  const { blockDays } = yield select(
    (state: RootState) => state.AuthReducer.config
  );
  modifiers.limitedOffer = getOrangeDays(
    disabledDays,
    blockDays,
    payload
  ).map((i: number) => convertNumberDateToDate(i));
  yield put(actionsCalendar.setModifiers(modifiers));
}

export function* initCalendarSaga({
  payload
}: ReturnType<typeof actionsCalendar.initCalendar>) {
  let holidaysDates = [];
  const today = new Date();

  if (payload.holidays) {
    holidaysDates = payload.holidays.map((item: any) =>
      convertNumberDateToDate(item.date)
    );
  }
  const { user } = yield select((state: RootState) => state.AuthReducer);
  const { growerList } = yield select(
    (state: RootState) => state.DashboardReducer
  );

  let blockDays: Array<number> = [];

  blockDays = calculateDisabledDays(
    payload.disabled_calendar_days!,
    user?.metadata?.customer_state_key
  );

  const stillClosingAvailable = Object.values(growerList)
    .flat()
    .some((grower) => {
      return (
        (grower as Grower).country === USA &&
        (grower as Grower).closing_time &&
        getGrowerTime((grower as Grower).closing_time as string) >
          getEstNowTimeMinutes()
      );
    });

  if (!stillClosingAvailable) {
    const tomorrow = today.setDate(today.getDate() + 1);
    holidaysDates.push(tomorrow);
  }

  const disabledDays = [
    ...holidaysDates,
    today,
    { daysOfWeek: payload.blockDays.concat(...blockDays) },
    { before: today }
  ];

  // const tomorrow = new Date();
  // tomorrow.setDate(tomorrow.getDate() + 1);
  // if (payload.time > 800) {
  //   disabledDays.push(tomorrow);
  // }

  yield put(actionsCalendar.setDisableDays(disabledDays));
  let TOMORROW = [getNextBusinessDay(new Date())];

  const MODIFIERS = {
    limitedOffer: TOMORROW,
    today: today,
    orangeDaysOfWeek: {},
    disabledDays
  };
  MODIFIERS.limitedOffer = getOrangeDays(
    payload.holidays,
    payload.blockDays,
    payload.time
  ).map((i: number) => convertNumberDateToDate(i));

  MODIFIERS.orangeDaysOfWeek = { daysOfWeek: [5] };

  yield put(actionsCalendar.setModifiers(MODIFIERS));
}

function* getTotalProductsByDateSaga({
  payload
}: ReturnType<typeof actionsCalendar.getTotalProductByDate>): any {
  try {
    yield put(actionsCalendar.setLoadingDaysOfWeek(true));
    const res = yield call(getTotalProductByDateService, payload.params);
    if (res.success) {
      if (res.response.length > 0) {
        res.response = res.response.reduce((obj: any, it: any) => {
          obj[it.eta + ''] = it.totalItems;

          return obj;
        }, {});
      }
      let arrayDeliveryDate = [];

      for (let key in res.response) {
        if (res.response[key] !== 0) {
          arrayDeliveryDate.push({ [key]: res.response[key] });
        }
      }

      let daysOfWeek = showDaysOfWeek(arrayDeliveryDate.slice(0, 3));
      yield put(actionsCalendar.setLoadingDaysOfWeek(false));
      yield put(
        actionsCalendar.setTotalProductByDate({
          daysOfWeek,
          totalProductByDate: res.response
        })
      );
    } else {
      yield put(actionsCalendar.setRejected());
      yield put(actionsCalendar.setLoadingDaysOfWeek(false));
    }
  } catch (error) {
    yield put(actionsCalendar.setRejected());
  }
}

export default function* AllSagas() {
  yield all([
    takeEvery(SET_NEXT_DAY, setNextDaySaga),
    takeEvery(INIT_CALENDAR, initCalendarSaga),
    takeEvery(GET_TOTAL_PRODUCTS_BY_DATE, getTotalProductsByDateSaga)
  ]);
}
