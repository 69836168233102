import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsH } from '@fortawesome/pro-light-svg-icons';
type Props = {
  className?: string;
};
export default function ScrollSliceComponent({ className }: Props) {
  return (
    <span
      className={`position-absolute px-2 ibf-font-12 align-items-center d-flex d-lg-none text-white ${className ??
        ''}`}>
      <FontAwesomeIcon
        icon={faArrowsH}
        className="mr-1"
        size={'2x'}
        color={'white'}
      />
      SCROLL
    </span>
  );
}
